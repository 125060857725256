<template>
    <PegasusHeading>VIKÖ Pályázat szerkesztése</PegasusHeading>

    <PegasusPanel header="Adatok">
        <div class="row">
            <div class="col-lg-4">
                <dl>
                    <dt>Név</dt>
                    <dd>{{ application.user.name }}</dd>
                    <dt>E-mail cím</dt>
                    <dd>{{ application.user.email }}</dd>
                </dl>
            </div>

            <div class="col-lg-4">
                <TextField
                    v-model="nickName"
                    :disabled="!application.canEdit"
                    :schema="VikoApplicationUpdateSchema.nickName"
                ></TextField>

                <PegasusButton v-if="application.canEdit" variant="success" @click="save">
                    <i class="fas fa-save"></i> Mentés
                </PegasusButton>
            </div>

            <div class="col-lg-4">
                <PegasusButton v-if="application.canEdit" variant="danger" @click="deleteApplication">
                    <i class="fas fa-times"></i> Pályázat visszavonása és törlése
                </PegasusButton>
            </div>
        </div>
    </PegasusPanel>

    <PegasusPanel header="Beszámolók">
        <PegasusTable :data="reports" empty-text="Még egy beszámolót se adtál le">
            <Column field="group.name" header="Kör"></Column>

            <Column field="text.length" header="Karakterszám"></Column>

            <Column field="status" header="Állapot"></Column>

            <Column header="Műveletek">
                <template #body="{data}">
                    <PegasusButton
                        v-if="!data.finalized && application.canEdit"
                        variant="primary"
                        @click="editReport(data)"
                    >
                        <i class="fas fa-edit"></i> Szerkesztés
                    </PegasusButton>

                    <PegasusButton v-else variant="primary" @click="editReport(data)">
                        <i class="fas fa-eye"></i> Megtekintés
                    </PegasusButton>

                    <PegasusButton
                        v-if="!data.finalized && application.canEdit" variant="success"
                        @click="sendReport(data)">
                        <i class="fas fa-paper-plane"></i> Beküldés
                    </PegasusButton>

                    <PegasusButton v-if="application.canEdit" variant="danger" @click="deleteReport(data)">
                        <i class="fas fa-times"></i> Törlés
                    </PegasusButton>
                </template>
            </Column>
        </PegasusTable>

        <PegasusButton v-if="application.canEdit" @click="newReport">
            Új beszámoló
        </PegasusButton>
    </PegasusPanel>

    <PegasusButton to="viko.index" variant="primary">
        <i class="fas fa-backward"></i> Vissza a főoldalra
    </PegasusButton>
</template>

<title>VIKÖ pályázat</title>

<script lang="ts" setup>
import {Report} from "@Models/viko/Report";
import {Application} from "@Models/viko/Application";
import {VikoIndex} from "@Models/viko/VikoIndex";
import TextField from "@Components/base/form/types/TextField.vue";
import {VikoApplicationUpdateSchema} from "@/api/models";
import {promiseAll} from "@Utils/misc";
import {ref} from "vue";
import {useAuthStore} from "@/stores/auth";
import toast from "@Utils/toast";
import {redirect} from "@/router";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {Dialog} from '@Utils/dialog';
import NewReportDialog from '@Components/viko/application/NewReportDialog.vue';
import {map} from "lodash-es";
import ReportDialog from '@Components/viko/application/ReportDialog.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const {applicationId} = defineProps<{
    applicationId: number
}>();

const fetched = await promiseAll({
    application: Application.getById(applicationId),
    reports: Report.getByApplication(applicationId),
});

const application = ref(fetched.application);
const reports = ref(fetched.reports);
const summary = ref<VikoIndex | null>(null);

VikoIndex.getById().then(response => {
    summary.value = response;
});

const auth = useAuthStore();

const nickName = ref(auth.requireUser().value.nick ?? '');

function refresh() {
    const request = auth.refresh().then(() => {
        return VikoIndex.getById().then(response => {
            summary.value = response;
        });
    });

    toast.loading(request, 'Frissítés');
}

function newReport() {
    const report = Report.createNew();

    Dialog.open(NewReportDialog, {
        report,
        summary,
        reportIds: map(reports.value, report => report.group.id),
        onRefresh: () => refresh()
    }).then(result => {
        if (result) {
            reports.value[report.id] = report;
        }
    });
}

function editReport(report: Report) {
    Dialog.open(ReportDialog, {report});
}

async function sendReport(report: Report) {
    if (!await toast.confirm('Biztosan be akarod küldeni ezt a beszámolót?')) {
        return;
    }

    const request = report.send();

    toast.loading(request, 'Beküldés');
}

async function deleteReport(report: Report) {
    if (!await toast.confirm('VISSZAVONHATATLAN MŰVELET! Biztosan törölni szeretnéd a beszámolód?')) {
        return;
    }

    const request = report.destroy().then(() => {
        delete reports.value[report.id];
    });

    toast.loading(request, 'Törlés');
}

function save() {
    const request = application.value.update();

    toast.loading(request);
}

async function deleteApplication() {
    if (!await toast.confirm('VISSZAVONHATATLAN MŰVELET! Biztos vagy benne?')) {
        return;
    }

    if (!await toast.confirm('VISSZAVONHATATLAN MŰVELET! Tényleg biztos vagy benne?')) {
        return;
    }

    let request = application.value.destroy().then(() => {
        redirect({
            name: 'viko.index',
        });
    });

    toast.loading(request, 'Törlés');
}
</script>
