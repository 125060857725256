import {BaseEntity} from '@Models/BaseEntity';
import {MoneyApi} from '@/api/apis';
import {
    type MoneyBudgetItemResponse,
    type MoneyBudgetItemStore,
    type MoneyBudgetItemUpdate,
    MoneyBudgetLocation,
} from '@/api/models';
import {BudgetItemValue} from '@Models/money/BudgetItemValue';
import {Group} from '@Models/base/Group';
import {BudgetProgram} from '@Models/money/BudgetProgram';
import type {FormRef} from '@/vue';
import type {BudgetCategory} from '@Models/money/BudgetCategory';

export class BudgetItem extends BaseEntity {
    name: string;

    description: string;

    number: number;

    group: Group;

    values: BudgetItemValue[];

    static getById(id: number): Promise<BudgetItem> {
        return MoneyApi.budgetItemsShow(id).then(response => {
            return BudgetItem.newSingle(response.data, this.parseResponse);
        });
    }

    static parseResponse(item: BudgetItem, data: MoneyBudgetItemResponse): BudgetItem {
        item.id = data.id;
        item.name = data.name;
        item.description = data.description;
        item.number = data.number;
        item.group = Group.getBySingleId(data.groupId);
        item.values = BudgetItemValue.newArray(data.values, BudgetItemValue.parseResponse);

        return item;
    }

    get sum(): number {
        return this.values.reduce((prev, value) => prev + value.value, 0);
    }

    public valueByLocation(location: MoneyBudgetLocation): BudgetItemValue | null {
        return this.values.find(value => value.location === location) ?? null;
    }

    public getPrograms(): Promise<Record<number, BudgetProgram>> {
        return BudgetProgram.getAllByItem(this.id);
    }

    public store(name: string, description: string, number: number, parent: BudgetCategory): Promise<BudgetItem> {
        const data: MoneyBudgetItemStore = {
            name,
            description,
            number,
            category: parent.id
        };

        return MoneyApi.budgetItemsStore(data).then(response => {
            return BudgetItem.parseResponse(this, response.data);
        });
    }

    public update(form: FormRef): Promise<void> {
        const data: MoneyBudgetItemUpdate = {
            name: this.name,
            description: this.description,
            number: this.number,
            group: this.group.id
        };

        return MoneyApi.budgetItemsUpdate(this.id, data, {form}).then(response => {
            BudgetItem.parseResponse(this, response.data);
        });
    }

    public delete(): Promise<void> {
        return MoneyApi.budgetItemsDelete(this.id).then();
    }
}
