<template>
    <PegasusHeading>Költhető tételek - {{ itemGroup.calculatedName }}</PegasusHeading>

    <div class="row">
        <div v-for="group in itemGroup.alternativeChildren" :key="group.id" class="col-lg-6">
            <PegasusPanel :header="group.name" class="mb-2">
                <PegasusTable :data="group.getAllNonzeroItems()">
                    <Column field="id" header="Tétel">
                        <template #body="{data}">
                            <PegasusLink :params="{item: data.id}" to="money.spending.item">
                                {{ data.displayName }}
                            </PegasusLink>
                        </template>
                    </Column>

                    <Column :field="item => item.decidedPrice.huf()" header="Összeg"></Column>
                </PegasusTable>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Költhető tételek</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {ItemGroup} from '@Models/money/ItemGroup';
import {ref} from 'vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import Column from 'primevue/column';
import PegasusLink from '@Components/base/PegasusLink.vue';

const {itemGroupId} = defineProps<{
    itemGroupId: number
}>();

const itemGroup = ref(await ItemGroup.getById(itemGroupId));
</script>
