/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyItemsUpdateDefaultResponse } from './MoneyItemsUpdateDefaultResponse';
    import {
    MoneyItemsUpdateDefaultResponseFromJSON,
    MoneyItemsUpdateDefaultResponseFromJSONTyped,
    MoneyItemsUpdateDefaultResponseToJSON,
    MoneyItemsUpdateDefaultResponseCreateEmpty,
    } from './MoneyItemsUpdateDefaultResponse';
import {DateTime} from '@Utils/DateTime';

export interface MoneyItemGroupWithItemsResponse {
  id: number;
  name: string;
  description: string;
  calculatedName: string;
  items: Array<MoneyItemsUpdateDefaultResponse>;
  alternativeChildren: Array<MoneyItemGroupWithItemsResponse>;
  bonusChildren: Array<MoneyItemGroupWithItemsResponse>;
}

export const MoneyItemGroupWithItemsResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'description': {
    name: 'description',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'calculatedName': {
    name: 'calculatedName',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'items': {
    name: 'items',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'alternativeChildren': {
    name: 'alternativeChildren',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'bonusChildren': {
    name: 'bonusChildren',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfMoneyItemGroupWithItemsResponse(value: object): value is MoneyItemGroupWithItemsResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "calculatedName" in value;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "alternativeChildren" in value;
    isInstance = isInstance && "bonusChildren" in value;

return isInstance;
}

export function MoneyItemGroupWithItemsResponseFromJSON(json: any): MoneyItemGroupWithItemsResponse {
return MoneyItemGroupWithItemsResponseFromJSONTyped(json, false);
}

export function MoneyItemGroupWithItemsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyItemGroupWithItemsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'description': json['description'],
          'calculatedName': json['calculatedName'],
            'items': ((json['items'] as Array<any>).map(MoneyItemsUpdateDefaultResponseFromJSON)),
            'alternativeChildren': ((json['alternativeChildren'] as Array<any>).map(MoneyItemGroupWithItemsResponseFromJSON)),
            'bonusChildren': ((json['bonusChildren'] as Array<any>).map(MoneyItemGroupWithItemsResponseFromJSON)),
    };
  }

  export function MoneyItemGroupWithItemsResponseToJSON(value?: MoneyItemGroupWithItemsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'description': value.description,
                'calculatedName': value.calculatedName,
              'items': ((value.items as Array<any>).map(MoneyItemsUpdateDefaultResponseToJSON)),
              'alternativeChildren': ((value.alternativeChildren as Array<any>).map(MoneyItemGroupWithItemsResponseToJSON)),
              'bonusChildren': ((value.bonusChildren as Array<any>).map(MoneyItemGroupWithItemsResponseToJSON)),
    };
  }

  export function MoneyItemGroupWithItemsResponseCreateEmpty(): MoneyItemGroupWithItemsResponse {
  return {
      'id': 0,
      'name': '',
      'description': '',
      'calculatedName': '',
      'items': [],
      'alternativeChildren': [],
      'bonusChildren': [],
  };
  }

