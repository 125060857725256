/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsKitchenReservationData {
  reason: string;
  from: DateTime;
  to: DateTime;
  stoveCount: number;
  appliances: string;
}

export const EventsKitchenReservationDataSchema = {
  'reason': {
    name: 'reason',
    required: true,
    nullable: false,
    label: 'Használat célja',
      labelHelp: `Hány fő, mit főztök?`,
    type: 'string',
      minLength: 50,
      maxLength: 300,
  },
  'from': {
    name: 'from',
    required: true,
    nullable: false,
    label: 'Átvétel ideje',
    type: 'datetime',
  },
  'to': {
    name: 'to',
    required: true,
    nullable: false,
    label: 'Visszaadás ideje',
    type: 'datetime',
  },
  'stoveCount': {
    name: 'stoveCount',
    required: true,
    nullable: false,
    label: 'Használni kívánt zsámolyok száma',
    type: 'number',
      minimum: 0,
      maximum: 5,
  },
  'appliances': {
    name: 'appliances',
    required: true,
    nullable: false,
    label: 'Egyéb eszközök amiket használnátok',
      labelHelp: `Főleg az elektromos fogyasztók!`,
    type: 'string',
      maxLength: 300,
  },
}
export function instanceOfEventsKitchenReservationData(value: object): value is EventsKitchenReservationData {
let isInstance = true;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;
    isInstance = isInstance && "stoveCount" in value;
    isInstance = isInstance && "appliances" in value;

return isInstance;
}

export function EventsKitchenReservationDataFromJSON(json: any): EventsKitchenReservationData {
return EventsKitchenReservationDataFromJSONTyped(json, false);
}

export function EventsKitchenReservationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsKitchenReservationData {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'reason': json['reason'],
        'from': (DateTime.fromISO(json['from'])),
        'to': (DateTime.fromISO(json['to'])),
          'stoveCount': json['stoveCount'],
          'appliances': json['appliances'],
    };
  }

  export function EventsKitchenReservationDataToJSON(value?: EventsKitchenReservationData | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'reason': value.reason,
            'from': (value.from.toISO()),
            'to': (value.to.toISO()),
                'stoveCount': value.stoveCount,
                'appliances': value.appliances,
    };
  }

  export function EventsKitchenReservationDataCreateEmpty(): EventsKitchenReservationData {
  return {
      'reason': '',
      'from': DateTime.now(),
      'to': DateTime.now(),
      'stoveCount': 0,
      'appliances': '',
  };
  }

