/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseGroupSummaryInventoryResponse } from './BaseGroupSummaryInventoryResponse';
    import {
    BaseGroupSummaryInventoryResponseFromJSON,
    BaseGroupSummaryInventoryResponseFromJSONTyped,
    BaseGroupSummaryInventoryResponseToJSON,
    BaseGroupSummaryInventoryResponseCreateEmpty,
    } from './BaseGroupSummaryInventoryResponse';
    import type { InventoryGenericStatisticsResponse } from './InventoryGenericStatisticsResponse';
    import {
    InventoryGenericStatisticsResponseFromJSON,
    InventoryGenericStatisticsResponseFromJSONTyped,
    InventoryGenericStatisticsResponseToJSON,
    InventoryGenericStatisticsResponseCreateEmpty,
    } from './InventoryGenericStatisticsResponse';
    import type { InventoryOwnerSummaryResponse } from './InventoryOwnerSummaryResponse';
    import {
    InventoryOwnerSummaryResponseFromJSON,
    InventoryOwnerSummaryResponseFromJSONTyped,
    InventoryOwnerSummaryResponseToJSON,
    InventoryOwnerSummaryResponseCreateEmpty,
    } from './InventoryOwnerSummaryResponse';
import {DateTime} from '@Utils/DateTime';

export interface InventoryAdminSummaryResponse {
  itemCount: number;
  priceTotal: number;
  tagCounts: Array<InventoryGenericStatisticsResponse>;
  owners: Array<InventoryOwnerSummaryResponse>;
  groups: Array<BaseGroupSummaryInventoryResponse>;
}

export const InventoryAdminSummaryResponseSchema = {
  'itemCount': {
    name: 'itemCount',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'priceTotal': {
    name: 'priceTotal',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'tagCounts': {
    name: 'tagCounts',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'owners': {
    name: 'owners',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'groups': {
    name: 'groups',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfInventoryAdminSummaryResponse(value: object): value is InventoryAdminSummaryResponse {
let isInstance = true;
    isInstance = isInstance && "itemCount" in value;
    isInstance = isInstance && "priceTotal" in value;
    isInstance = isInstance && "tagCounts" in value;
    isInstance = isInstance && "owners" in value;
    isInstance = isInstance && "groups" in value;

return isInstance;
}

export function InventoryAdminSummaryResponseFromJSON(json: any): InventoryAdminSummaryResponse {
return InventoryAdminSummaryResponseFromJSONTyped(json, false);
}

export function InventoryAdminSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryAdminSummaryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'itemCount': json['itemCount'],
          'priceTotal': json['priceTotal'],
            'tagCounts': ((json['tagCounts'] as Array<any>).map(InventoryGenericStatisticsResponseFromJSON)),
            'owners': ((json['owners'] as Array<any>).map(InventoryOwnerSummaryResponseFromJSON)),
            'groups': ((json['groups'] as Array<any>).map(BaseGroupSummaryInventoryResponseFromJSON)),
    };
  }

  export function InventoryAdminSummaryResponseToJSON(value?: InventoryAdminSummaryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'itemCount': value.itemCount,
                'priceTotal': value.priceTotal,
              'tagCounts': ((value.tagCounts as Array<any>).map(InventoryGenericStatisticsResponseToJSON)),
              'owners': ((value.owners as Array<any>).map(InventoryOwnerSummaryResponseToJSON)),
              'groups': ((value.groups as Array<any>).map(BaseGroupSummaryInventoryResponseToJSON)),
    };
  }

  export function InventoryAdminSummaryResponseCreateEmpty(): InventoryAdminSummaryResponse {
  return {
      'itemCount': 0,
      'priceTotal': 0,
      'tagCounts': [],
      'owners': [],
      'groups': [],
  };
  }

