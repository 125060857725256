import toast from "@Utils/toast";
import {User} from "@Models/base/User";
import * as Sentry from "@sentry/vue";
import {BaseGlobalPermission, MainApi} from "@/api/api";
import {defineStore} from "pinia";
import type {RouteLocationNamedRaw} from "vue-router";
import {type Ref, ref} from "vue";
import {AuthenticationRequiredError} from "@Utils/errors/AuthenticationRequiredError";
import {useSessionStorage} from '@vueuse/core';
import {calculateHref} from '@/router/index';

type AuthStorage = {state: string | null, target: RouteLocationNamedRaw | null};

const storage: Ref<AuthStorage> = useSessionStorage<AuthStorage>('pegasus-auth', {state: null, target: null}, {deep: true});

export const useAuthStore = defineStore('auth', () => {
    const user: Ref<User | null> = ref(null);
    const impersonating: Ref<boolean> = ref(false);

    function setAuth(newUser: User | null, newImpersonating: boolean) {
        user.value = newUser;

        impersonating.value = newImpersonating;

        if (newUser) {
            Sentry.setUser({
                id: newUser.id.toString(),
                email: newUser.email,
            });
        } else {
            Sentry.setUser(null);
        }
    }

    function setTarget(target: RouteLocationNamedRaw | null) {
        storage.value.target = target;
    }

    async function init() {
        const {data} = await MainApi.authenticationUser();

        const user = User.newSingleNullable(data.user, User.parseExtendedResponse);

        setAuth(user, data.impersonating);
    }

    function redirect(): Promise<void> {
        const request = new Promise<void>((resolve, reject) => {
            const params = new URLSearchParams({
                redirectUrl: `${window.location.origin}/api/auth/callback/authsch`,
                targetUrl: storage.value.target ? calculateHref(storage.value.target) : '',
            });

            window.location.assign(`/api/auth/signin/authsch?${params.toString()}`);

            setTimeout(() => reject(), 20000);
        });

        toast.loading(request, 'Bejelentkezés');

        return request;
    }

    async function refresh() {
        const response = await MainApi.authenticationRefresh();
        user.value = User.newSingleNullable(response.data, User.parseExtendedResponse);
    }

    async function impersonate(user: User) {
        return MainApi.authenticationImpersonate(user.id).then(response => {
            const user = User.newSingleNullable(response.data.user, User.parseExtendedResponse);

            setAuth(user, response.data.impersonating);
        });
    }

    async function stopImpersonating() {
        return MainApi.authenticationStopImpersonating().then(response => {
            const user = User.newSingleNullable(response.data.user, User.parseExtendedResponse);

            setAuth(user, response.data.impersonating);
        });
    }

    function logout() {
        const request = MainApi.authenticationLogout().then(response => {
            const user = User.newSingleNullable(response.data.user, User.parseExtendedResponse);

            setAuth(user, response.data.impersonating);
        });

        toast.loading(request, 'Kijelentkezés');
    }

    function hasPermission(permissions: BaseGlobalPermission | BaseGlobalPermission[]) {
        return user.value?.hasPermission(permissions) === true;
    }

    function requireUser(): Ref<User> {
        if (!user.value) {
            redirect();
            throw new AuthenticationRequiredError('Bejelentkezés szükséges');
        }

        return user as Ref<User>;
    }

    return {
        user,
        setTarget,
        impersonate,
        impersonating,
        init,
        redirect,
        refresh,
        stopImpersonating,
        logout,
        hasPermission,
        requireUser
    };
});
