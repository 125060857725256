<template>
    <PegasusHeading>Főrendezői tanfolyam</PegasusHeading>

    <div class="row">
        <div class="col-lg-3">
            <PegasusPanel header="Beállítások">
                <TrainingComponent :training="training"></TrainingComponent>
            </PegasusPanel>
        </div>

        <div class="col-lg-9">
            <PegasusPanel header="Részvevők">
                <PegasusTable :data="participations" :per-page="10" :row-variant="row" search>
                    <Column field="user.name" header="Név"></Column>
                    <Column field="user.email" header="E-mail cím"></Column>
                    <Column header="Műveletek">
                        <template #body="{data}: {data: TrainingParticipation}">
                            <PegasusButton v-if="data.active" variant="danger" @click="unparticipate(data)">
                                Nem vett részt
                            </PegasusButton>

                            <PegasusButton v-else variant="success" @click="participate(data)">
                                Részt vett
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>

                <p>Piros: Jelentkezett, de nem vett részt</p>
                <p>Zöld: Jelentkezett és részt is vett</p>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Főrendezői tanfolyam</title>

<script lang="ts" setup>
import {Training} from "@Models/events/Training";
import TrainingComponent from "@Components/events/training/Training.vue";
import {TrainingParticipation} from "@Models/events/TrainingParticipation";
import toast from "@Utils/toast";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {ref} from "vue";
import {promiseAll} from '@Utils/misc';
import {useAuthStore} from "@/stores/auth";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const {trainingId} = defineProps<{
    trainingId: number
}>();

useAuthStore().requireUser();

const fetched = await promiseAll({
    training: Training.getById(trainingId),
    participations: TrainingParticipation.getByTraining(trainingId),
});

const training = ref(await fetched.training);

const participations = ref(await fetched.participations);

function row(item: TrainingParticipation) {
    if (item.active) {
        return 'success';
    } else {
        return 'danger';
    }
}

function participate(participation: TrainingParticipation) {
    participation.active = true;

    let request = participation.update();

    toast.loading(request);
}

function unparticipate(participation: TrainingParticipation) {
    participation.active = false;

    let request = participation.update();

    toast.loading(request);
}
</script>
