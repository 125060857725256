<template>
    <TextField
        v-model="spendingItem.name"
        :schema="MoneySpendingItemStoreSchema.name"
    ></TextField>

    <NumberField
        v-model="spendingItem.money"
        :schema="MoneySpendingItemStoreSchema.money"
    ></NumberField>

    <SelectField
        v-model="spendingItem.budgetLocation"
        :options="dictEnum(MoneyBudgetLocationValues)"
        :schema="MoneySpendingItemStoreSchema.location"
    ></SelectField>

    <SelectField
        v-model="spendingItem.method"
        :options="methodOptions"
        :schema="MoneySpendingItemStoreSchema.method"
    ></SelectField>
</template>

<script lang="ts" setup>
import {
    MoneyBudgetLocationValues,
    MoneySpendingItemStoreSchema,
    MoneySpendingMethod,
    MoneySpendingMethodValues,
} from '@/api/models';
import {dictEnum} from '@Models/Utils';
import SelectField from '@Components/base/form/types/SelectField.vue';
import TextField from '@Components/base/form/types/TextField.vue';
import NumberField from '@Components/base/form/types/NumberField.vue';
import type {SpendingItem} from '@Models/money/SpendingItem';
import {map} from 'lodash-es';
import {computed} from 'vue';

const {spendingItem} = defineProps<{
    spendingItem: SpendingItem
}>();

const methodOptions = computed(() => {
    return map(MoneySpendingMethodValues, (value: string, key: MoneySpendingMethod) => {
        if (key === MoneySpendingMethod.CARD) {
            return {
                value: key,
                label: value,
                warning: 'Kivételes esetben (Gazdasági felelős engedélyével)',
            };
        }

        return {
            value: key,
            label: value,
        };
    }) as unknown as {value: MoneySpendingMethod, label: string}[];
});
</script>
