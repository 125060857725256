import {BaseEntity} from "@Models/BaseEntity";
import {Category} from "@Models/rvt/kpr/Category";
import {SpendingPeriod} from "@Models/money/SpendingPeriod";
import {File} from "@Models/File";
import {
    KprApi,
    type KprTenderResponse,
    type KprTenderStore,
    type KprTenderUpdate,
    type KprUpdateRules
} from "@/api/api";
import {groupBy, join, map, some} from "lodash-es";
import type {FormRef} from '@/vue';

export class Tender extends BaseEntity {
    spendingPeriodId: number;

    categories: Record<number, Category>;

    claimsBudgetItem: number | null;

    amendable: boolean;

    static getAll(): Promise<Record<number, Tender>> {
        return KprApi.tenderIndex().then(response => {
            return Tender.newRecords(response.data.tenders, Tender.parseResponse);
        });
    }

    static getById(id: number): Promise<Tender> {
        return KprApi.tenderShow(id).then(response => {
            return Tender.newSingle(response.data, Tender.parseResponse);
        });
    }

    static parseResponse(tender: Tender, data: KprTenderResponse): Tender {
        tender.id = data.id;
        tender.spendingPeriodId = data.spendingPeriodId;
        tender.categories = Category.newRecords(data.categories, Category.parseResponse);
        tender.claimsBudgetItem = data.claimsBudgetItemId;
        tender.amendable = data.amendable;

        return tender;
    }

    public store(categories: Category[], form: FormRef): Promise<Tender> {
        const data: KprTenderStore = {
            spendingPeriod: this.spendingPeriodId,
            categories: map(categories, category => {
                return {
                    name: category.name,
                    shortName: category.shortName,
                    status: category.status,
                    endDrafting: category.endDrafting,
                    requireContributions: category.requireContributions,
                };
            }),
        };

        return KprApi.tenderStore(data, {form}).then(response => {
            return Tender.parseResponse(this, response.data);
        });
    }

    public update(): Promise<void> {
        const data: KprTenderUpdate = {
            claimsBudgetItem: this.claimsBudgetItem,
            categories: map(this.categories, category => {
                return {
                    category: category.id,
                    status: category.status,
                    endDrafting: category.endDrafting,
                    budget: category.budget,
                    budgetItem: category.budgetItemId
                };
            }),
        };

        return KprApi.tenderUpdate(this.id, data).then(response => {
            Tender.parseResponse(this, response.data);
        });
    }

    public sgrExport(): Promise<File> {
        return KprApi.tenderExportSgr(this.id).then(response => {
            return File.createFromResponse(response);
        });
    }

    public uploadRules(file: File): Promise<void> {
        const data: KprUpdateRules = {
            rules: file.getAsDomFile(),
        };

        return KprApi.tenderEditRules(this.id, data).then();
    }

    get spendingPeriod(): SpendingPeriod {
        return SpendingPeriod.getBySingleId(this.spendingPeriodId);
    }

    get displayName(): string {
        return 'KPR ' + this.spendingPeriod.from.year;
    }

    get status(): string {
        const categoriesByStatus = groupBy(this.categories, category => category.statusText);

        return join(
            map(categoriesByStatus, (categories, status) => status + ' (' + categories.map(category => category.shortName.toUpperCase()).join(', ') + ')'),
            ', '
        );
    }

    public hasDrafting(): boolean {
        return some(this.categories, (category: Category) => category.isInDrafting());
    }
}
