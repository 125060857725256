<template>
    <PegasusHeading>Mentor ráérések - {{ assignmentMonth.displayName }}</PegasusHeading>
    <PegasusTable :data="mentors" :per-page="20" show-gridlines>
        <Column body-class="py-2 text-nowrap" field="name" header="Mentor"></Column>

        <Column v-for="day in assignmentMonth.getDaysArray()" :key="day" body-class="p-0 text-center" header-class="p-1 text-center">
            <template #header>
                <div class="w-100">{{ day }}</div>
            </template>

            <template #body="{data}: {data: Mentor}">
                <span class="m-2">
                    <i v-if="isAssigned(data, day)" class="fa fa-square-check text-success"></i>
                    <i v-else-if="available(data, day)" class="fa fa-square-check text-primary"></i>
                </span>
            </template>
        </Column>
    </PegasusTable>
</template>

<title>Mentor ráérések</title>

<script lang="ts" setup>
import {Mentor} from '@Models/mentors/Mentor';
import Column from 'primevue/column';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import {promiseAll} from '@Utils/misc';
import {AssignmentMonth} from '@Models/mentors/AssignmentMonth';
import {Assignment} from '@Models/mentors/Assignment';
import {computed, ref} from 'vue';
import {filter, groupBy, keyBy, orderBy} from "lodash-es";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const {year, month} = defineProps<{
    year: number,
    month: number,
}>();

const fetched = await promiseAll({
    assignmentMonth: AssignmentMonth.get(year, month),
    assignments: Assignment.getMonth(year, month),
    mentors: Mentor.getAll(),
});

const assignmentMonth = ref(fetched.assignmentMonth);
const assignments = ref(keyBy(fetched.assignments, assignment => assignment.date.day));

const availabilitiesByMentor = computed(() => keyBy(assignmentMonth.value.availabilities, availability => availability.mentorId));

const assignmentsByMentor = computed((): Record<number, Assignment[]> => groupBy(assignments.value, assignment => assignment.mentor?.id));

const mentors = orderBy(fetched.mentors, mentor => parseInt(mentor.room));

function isAssigned(mentor: Mentor, day: number): boolean {
    const assignments = assignmentsByMentor.value[mentor.id];

    return filter(assignments, assignment => assignment.date.day === day).length > 0;
}

function available(mentor: Mentor, day: number): boolean {
    const availabilities = availabilitiesByMentor.value[mentor.id];
    if (availabilities === undefined) {
        return false;
    }

    return availabilities.days.includes(day);
}
</script>
