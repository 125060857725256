import type {RouteRecordRaw} from "vue-router";
import {getNumberParam} from '@Models/Utils';

const money: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'money.index',
        component: () => import('@Pages/money/Index.vue'),
    },
    {
        path: '/group/:group/summary/:year',
        name: 'money.group.summary',
        component: () => import('@Pages/money/group/Summary.vue'),
        props: route => ({
            groupId: getNumberParam(route, 'group'),
            year: getNumberParam(route, 'year'),
        }),
    },
    {
        path: '/spendingPeriods',
        name: 'money.spendingPeriods.index',
        component: () => import('@Pages/money/spendingPeriod/Index.vue'),
    },
    {
        path: '/spendingPeriods/:year',
        name: 'money.spendingPeriods.show',
        component: () => import('@Pages/money/spendingPeriod/Show.vue'),
        props: route => ({
            year: getNumberParam(route, 'year'),
        }),
    },
    {
        path: '/budget/year/:year',
        name: 'money.budget.year.show',
        component: () => import('@Pages/money/budget/Show.vue'),
        props: route => ({
            year: getNumberParam(route, 'year'),
        }),
    },
    {
        path: '/budget/program/:program',
        name: 'money.budget.program.show',
        component: () => import('@Pages/money/budget/Program.vue'),
        props: route => ({
            programId: getNumberParam(route, 'program'),
        }),
    },
    {
        path: '/budget/item/:item',
        name: 'money.budget.item.show',
        component: () => import('@Pages/money/budget/Item.vue'),
        props: route => ({
            itemId: getNumberParam(route, 'item'),
        }),
    },
    {
        path: '/openTenders/:tender',
        name: 'money.openTenders.show',
        component: () => import('@Pages/money/openTenders/Show.vue'),
        props: route => ({
            tenderId: getNumberParam(route, 'tender'),
        }),
    },
    {
        path: '/itemGroup/:itemGroup/main',
        name: 'money.itemGroups.main',
        component: () => import('@Pages/money/itemGroup/Main.vue'),
        props: route => ({
            itemGroupId: getNumberParam(route, 'itemGroup'),
        }),
    },
    {
        path: '/item/:item',
        name: 'money.spending.item',
        component: () => import('@Pages/money/item/Show.vue'),
        props: route => ({
            itemId: getNumberParam(route, 'item'),
        }),
    },
    {
        path: '/item/:item/spending/:spendingItem',
        name: 'money.spendingItem.show',
        component: () => import('@Pages/money/spendingItem/Show.vue'),
        props: route => ({
            itemId: getNumberParam(route, 'item'),
            spendingItemId: getNumberParam(route, 'spendingItem'),
        }),
    },
    {
        path: '/admin',
        name: 'money.admin.index',
        component: () => import('@Pages/money/admin/Index.vue'),
    },
];

export default money;
