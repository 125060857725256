<template>
    <SmartField :inline="inline" :schema="schema" :without-group="withoutGroup">
        <template #default="{id, isValid}">
            <div>
                <Dropdown
                    v-model="modelValue"

                    :class="{'p-invalid': isValid === false}"
                    :disabled="disabled"
                    :input-id="id"
                    :loading="!options"
                    :options="options ?? []"
                    :show-clear="clearable"
                    class="w-100"

                    filter
                    option-label="label"
                    option-value="value"
                    placeholder=""
                ></Dropdown>
            </div>
        </template>

        <template #label="slotProps">
            <slot name="label" v-bind="slotProps"></slot>
        </template>
    </SmartField>
</template>

<script lang="ts" setup>
import {Building} from '@Models/map/Building';
import SmartField from '@Components/base/form/SmartField.vue';
import {type FieldSchema} from '@Components/base/form/FieldProperties';
import {useConstantsStore} from '@/stores/constants';
import {ref} from 'vue';
import {filter, map, sortBy, values} from 'lodash-es';
import type {Room} from '@Models/map/Room';
import {MapRoomType} from '@/api/models';
import Dropdown from 'primevue/dropdown';
import {whenever} from '@vueuse/core';

const modelValue = defineModel<Room | null>({required: true});

const {
    allowedTypes = values(MapRoomType),
    withoutGroup,
    disabled,
    schema,
    inline,
} = defineProps<{
    allowedTypes?: MapRoomType[]
    disabled?: boolean;
    clearable?: boolean;
    withoutGroup?: boolean;
    schema: FieldSchema;
    inline?: boolean;
}>();

const options = ref<{value: Room, label: string}[] | null>(null);
Building.getBuildingById(useConstantsStore().constants.central.schBuildingId).then(building => {
    let rooms = filter(building.getAllRooms(), room => allowedTypes.includes(room.type));

    rooms = sortBy(rooms, room => room.displayNumber);

    options.value = map(rooms, room => {
        return {
            value: room,
            label: room.displayNumber,
        };
    });
});

function setByRoomNumber(roomNumber: string) {
    whenever(() => options.value && options.value.length > 0, () => {
        if (!options.value) {
            throw new Error('Cannot happen');
        }

        const index = options.value.findIndex(option => option.value.displayNumber === roomNumber);

        if (index) {
            modelValue.value = options.value[index].value;
        }
    });
}

defineExpose({
    setByRoomNumber,
});
</script>
