import {BaseEntity} from "@Models/BaseEntity";
import type {VikoGroupReviewUpdate, VikoReviewResponse, VikoReviewStore, VikoReviewUpdate} from "@/api/api";
import {VikoApi} from "@/api/api";
import type {FormRef} from '@/vue';

export class Review extends BaseEntity {
    userId: string;

    applicationId: number;

    points: number;

    groupPoints: number;

    finalPoints: number;

    comment: string;

    static create(app: number, userId: string, points: number): Promise<Review> {
        const data: VikoReviewStore = {
            point: points,
            application: app,
            comment: '',
        };

        return VikoApi.reviewsStore(data).then(response => {
            return Review.newSingle(response.data, Review.parseResponse);
        });
    }

    static parseResponse(review: Review, data: VikoReviewResponse): Review {
        review.id = data.id;
        review.userId = data.userId;
        review.applicationId = data.applicationId;
        review.points = data.points;
        review.groupPoints = data.groupPoints;
        review.finalPoints = data.finalPoints;
        review.comment = data.comment;

        return review;
    }

    get calculatedPoint(): number {
        return this.finalPoints ?? this.groupPoints ?? this.points;
    }

    update(point: number, form: FormRef): Promise<Review> {
        const data: VikoReviewStore = {
            point: point,
            application: this.applicationId,
            comment: '',
        };

        return VikoApi.reviewsStore(data, {form}).then(response => {
            return Review.parseResponse(this, response.data);
        });
    }

    summaryUpdate(reviewerGroupId: number | null, points: number): Promise<Review> {
        if (reviewerGroupId !== null) {
            return this.summaryUpdateGroup(reviewerGroupId, points);
        }

        const data: VikoReviewUpdate = {
            finalPoints: points,
        };

        return VikoApi.summaryUpdateReview(this.id, data).then(response => {
            return Review.parseResponse(this, response.data);
        });
    }

    private summaryUpdateGroup(reviewerGroupId: number, groupPoints: number): Promise<Review> {
        const data: VikoGroupReviewUpdate = {
            groupPoints: groupPoints,
        };

        return VikoApi.summaryUpdateGroupReview(this.id, reviewerGroupId, data).then(response => {
            return Review.parseResponse(this, response.data);
        });
    }


    public isModified(groupId: number | null): boolean {
        if (groupId === null) {
            return this.finalPoints !== this.points;
        }

        return this.groupPoints !== this.points;
    }
}
