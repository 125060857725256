import {Base} from '@Models/Base';
import {OwnerSummary} from '@Models/inventory/OwnerSummary';
import {InventoryGroupSummary} from '@Models/inventory/InventoryGroupSummary';
import type {InventoryAdminSummaryResponse} from '@/api/api';
import {InventoryApi} from '@/api/api';
import {useConstantsStore} from '@/stores/constants';
import {keyBy, map} from 'lodash-es';
import type {TagCount} from '@Models/inventory/InventoryGroupStatistics';

export class AdminSummary extends Base {
    itemCount: number;

    priceTotal: number;

    tagCounts: Record<number, TagCount>;

    owners: Record<number, OwnerSummary>;

    groups: Record<number, InventoryGroupSummary>;

    static get(): Promise<AdminSummary> {
        return InventoryApi.adminSummary().then(response => {
            return AdminSummary.newSingle(response.data, AdminSummary.parseResponse);
        });
    }

    static parseResponse(summary: AdminSummary, data: InventoryAdminSummaryResponse): AdminSummary {
        summary.itemCount = data.itemCount;
        summary.priceTotal = data.priceTotal;

        const constants = useConstantsStore().constants;
        const tagCounts = map(data.tagCounts, tagCount => {
            return {
                tag: constants.inventory.tags[tagCount.countedById],
                itemCount: tagCount.itemCount,
                priceSum: tagCount.sumPrice,
            };
        });

        summary.tagCounts = keyBy(tagCounts, tagCount => tagCount.tag.id);

        summary.owners = OwnerSummary.newRecordsBy(data.owners, OwnerSummary.parseResponse, (summary: OwnerSummary) => summary.owner.id.toString());
        summary.groups = InventoryGroupSummary.newRecordsBy(data.groups, InventoryGroupSummary.parseResponse, (summary: InventoryGroupSummary) => summary.group.id.toString());

        return summary;
    }
}
