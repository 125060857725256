/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseGroupPermissionResponse } from './BaseGroupPermissionResponse';
    import {
    BaseGroupPermissionResponseFromJSON,
    BaseGroupPermissionResponseFromJSONTyped,
    BaseGroupPermissionResponseToJSON,
    BaseGroupPermissionResponseCreateEmpty,
    } from './BaseGroupPermissionResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseAuthConstantsResponse {
  groupPermissions: Array<BaseGroupPermissionResponse>;
}

export const BaseAuthConstantsResponseSchema = {
  'groupPermissions': {
    name: 'groupPermissions',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfBaseAuthConstantsResponse(value: object): value is BaseAuthConstantsResponse {
let isInstance = true;
    isInstance = isInstance && "groupPermissions" in value;

return isInstance;
}

export function BaseAuthConstantsResponseFromJSON(json: any): BaseAuthConstantsResponse {
return BaseAuthConstantsResponseFromJSONTyped(json, false);
}

export function BaseAuthConstantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseAuthConstantsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'groupPermissions': ((json['groupPermissions'] as Array<any>).map(BaseGroupPermissionResponseFromJSON)),
    };
  }

  export function BaseAuthConstantsResponseToJSON(value?: BaseAuthConstantsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'groupPermissions': ((value.groupPermissions as Array<any>).map(BaseGroupPermissionResponseToJSON)),
    };
  }

  export function BaseAuthConstantsResponseCreateEmpty(): BaseAuthConstantsResponse {
  return {
      'groupPermissions': [],
  };
  }

