<template>
    <a :href="href" :target="newTab ? '_blank' : undefined" role="button" @click="clickHandler">
        <slot></slot>
    </a>
</template>

<script lang="ts" setup>
import type {RouteParams} from "@/vue";
import {routeParamsNormalise} from "@Models/Utils";
import {calculateHref, redirect} from "@/router";
import {computed} from 'vue';
import type {RouteParamsGeneric} from 'vue-router';

const emit = defineEmits<{
    click: []
}>();

const {to, params = {}, newTab = false} = defineProps<{
    to: string,
    params?: RouteParams,
    newTab?: boolean
}>();

const href = computed(() => {
    if (to) {
        return calculateHref({
            name: to,
            params: params as RouteParamsGeneric,
        });
    }

    return 'javascript:void(0)';
});


function clickHandler(event: MouseEvent) {
    if (event.ctrlKey || newTab) {
        return;
    }

    event.preventDefault();

    emit('click');

    redirect({
        name: to,
        params: routeParamsNormalise(params ?? {}),
    });
}
</script>
