<template>
    <PegasusForm ref="form">
        <TextField
            v-model="event.name"
            :schema="EventsEventUpdateSchema.name"
            @update:model-value="emit('dirty')"
        ></TextField>

        <CheckboxMultipleField
            v-model="event.locations"
            :options="dictArray(constants.getEnabledLocations(), loc => loc.longName)"
            :schema="EventsEventUpdateSchema.locations"
            @update:model-value="emit('dirty')"
        ></CheckboxMultipleField>

        <TextareaField
            v-model="event.description"
            :schema="EventsEventUpdateSchema.description"
            @update:model-value="emit('dirty')"
        >
            <template v-if="!event.isExternal()" #label>
                <b class="me-2">{{ EventsEventUpdateSchema.description.label }}</b>
                <PegasusHelp title="Leírás" variant="warning">
                    Ez a rendezvény rövid leírása, amelyet kötelező megadni és az Egyetemi szervek felé továbbítva lesz,
                    ezért így fogalmazzátok. 50 és 400 karakter között kell lennie!<br><br>
                    A naptárban megjelenő leírást a Naptár extránál lehet módosítani.
                </PegasusHelp>
            </template>
        </TextareaField>

        <h4>Időpont</h4>
        <div class="row text-center">
            <div class="col-sm-5 text-center">
                <DatetimeField
                    v-model="event.start"
                    :disabled="!event.canEdit"
                    :schema="EventsEventUpdateSchema.start"
                    @update:model-value="emit('dirty')"
                ></DatetimeField>
            </div>

            <div class="col-sm-5 text-center">
                <DatetimeField
                    v-model="event.finish"
                    :disabled="!event.canEdit"
                    :schema="EventsEventUpdateSchema.finish"
                    @update:model-value="emit('dirty')"
                ></DatetimeField>
            </div>
        </div>

        <div v-if="!event.isExternal()" class="row text-center mt-2">
            <span class="mb-2"></span>

            <div class="col-sm-5 text-center">
                <DatetimeField
                    v-model="event.roomStart"
                    :disabled="!event.canEdit"
                    :schema="EventsEventUpdateSchema.roomStart"
                    @update:model-value="emit('dirty')"
                ></DatetimeField>
            </div>

            <div class="col-sm-5 text-center">
                <DatetimeField
                    v-model="event.roomFinish"
                    :disabled="!event.canEdit"
                    :schema="EventsEventUpdateSchema.roomFinish"
                    @update:model-value="emit('dirty')"
                ></DatetimeField>
            </div>
        </div>
        <br/>

        <h4>Résztvevők várható száma</h4>
        <NumberField
            v-model="event.participants"
            :disabled="!event.canEdit"
            :schema="EventsEventUpdateSchema.participants"
            inline
            @update:model-value="emit('dirty')"
        ></NumberField>

        <NumberField
            v-if="!event.isExternal()"
            v-model="event.outsiders"
            :disabled="!event.canEdit"
            :schema="EventsEventUpdateSchema.outsiders"
            inline
            @update:model-value="emit('dirty')"
        ></NumberField>

        <NumberField
            v-if="!event.isExternal()"
            v-model="event.externalWorkers"
            :disabled="!event.canEdit"
            :schema="EventsEventUpdateSchema.externalWorkers"
            inline
            @update:model-value="emit('dirty')"
        ></NumberField>
        <br/>

        <hr>

        <PegasusButton v-if="event.canEdit" variant="success" @click="save">
            <i class="fa fa-save"></i> Mentés
        </PegasusButton>

        <PegasusOverlay
            :content="checks.fatal.length + ' súlyos hiba kijavításáig nem lehetséges a bejelentés'"
            :enabled="submitDisabledFatal"
            header="Bejelentés nem lehetséges"
        >
            <PegasusButton
                v-if="event.canEdit && (!event.finalized || event.decision === false)"
                :disabled="!canSubmit"
                variant="danger"
                @click="submit"
            >
                Bejelentés
            </PegasusButton>
        </PegasusOverlay>
    </PegasusForm>

    <PegasusButton
        v-if="event.canEdit && !event.cancelled" variant="danger"
        @click="Dialog.open(CancelDialog, {event: event})"
    >
        <i class="fa fa-trash"></i> Rendezvény visszamondása
    </PegasusButton>
</template>

<script lang="ts" setup>
import Event from '@Models/events/Event';
import {Checks} from '@Models/events/Checks';
import {computed, ref} from 'vue';
import toast from '@Utils/toast';
import {useAuthStore} from '@/stores/auth';
import {useConstantsStore} from '@/stores/constants';
import {BaseGlobalPermission, EventsEventUpdateSchema} from '@/api/models';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import DatetimeField from '@Components/base/form/types/DatetimeField.vue';
import TextField from '@Components/base/form/types/TextField.vue';
import NumberField from '@Components/base/form/types/NumberField.vue';
import {dictArray} from '@Models/Utils';
import CheckboxMultipleField from '@Components/base/form/types/CheckboxMultipleField.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusOverlay from '@Components/base/PegasusOverlay.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import type {FormRef} from '@/vue';
import CancelDialog from '@Components/events/dialogs/CancelDialog.vue';
import SubmitDialog from '@Components/events/dialogs/SubmitDialog.vue';
import {Dialog} from '@Utils/dialog';
import PegasusHelp from '@Components/base/PegasusHelp.vue';

const {event, checks} = defineProps<{
    event: Event,
    checks: Checks,
}>();

const emit = defineEmits<{
    save: [],
    dirty: [],
}>();

const user = useAuthStore().requireUser();

const form = ref<FormRef>(null);

const constants = useConstantsStore();

function save() {
    const request = event.update(form.value).then(() => emit('save'));

    toast.loading(request);
}

const submitDisabledFatal = computed(() => {
    if (user.value.hasPermission(BaseGlobalPermission.EVENTS_APPROVE)) {
        return false;
    }
    if (!checks.fatal) {
        return false;
    }

    return checks.fatal.length > 0;
});

const canSubmit = computed(() => {
    if (!event.canEdit) {
        return false;
    }

    if (user.value.hasPermission(BaseGlobalPermission.EVENTS_APPROVE)) {
        return true;
    }

    if (event.cancelled) {
        return false;
    }

    return checks.fatal.length === 0;
});

async function submit() {
    if (checks.fatal && checks.fatal.length > 0) {
        if (!user.value.hasPermission(BaseGlobalPermission.EVENTS_APPROVE)) {
            toast.error('Bejelentés súlyos probléma miatt nem lehetséges');
            return;
        }

        let text = 'A bejelentés ' + checks.fatal.length + ' súlyos problémával'
            + ' rendelkezik, és csak azért tudod bejelenteni mert admin vagy! Biztosan ezt szeretnéd?';

        if (!await toast.confirm(text)) {
            return;
        }
    }

    if (checks.warning.length > 0) {
        if (!await toast.confirm('A bejelentéssel vannak problémák, csak akkor folytasd ha biztos vagy benne!')) {
            return;
        }
    }

    return Dialog.open(SubmitDialog, {event});
}
</script>
