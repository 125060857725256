import {globalRoutes, matcher, rawRoutes} from './routes';
import type {RouteLocationNamedRaw} from "vue-router";
import {createRouter, createWebHistory} from "vue-router";
import type {Site} from "@/router/domains";
import {currentSite, domainForSite} from "@/router/domains";
import {isEmpty, isNil, map} from 'lodash-es';
import {routeParamsNormalise} from '@Models/Utils';

const site: Site = currentSite();

const routes = [
    ...rawRoutes[site].children ?? [],
    ...globalRoutes(),
];

const layout = rawRoutes[site];

if (layout === undefined || layout === null || layout.component === null || layout.component === undefined) {
    throw new Error('Invalid layout');
}

export const component = layout.component;

export function calculateHref(route: RouteLocationNamedRaw): string {
    if (!route.name) {
        throw new Error('Route must have name');
    }
    if (typeof route.name === 'symbol') {
        throw new Error('Route name cannot be symbol');
    }

    const site = route.name.split('.')[0] as Site;

    if (!site) {
        throw new Error('Invalid route: ' + route.name);
    }

    const target = matcher(site, route.name);

    if (!target) {
        throw new Error('Invalid route: ' + route.name);
    }

    const href = target.path
        .replace(/:([a-zA-Z]*)/g, (_match: string, param: string) => {
            const value = routeParamsNormalise(route.params)[param] ?? null;
            if (!value) {
                throw new Error(`Route param (${param}) does not exist for route ${String(route.name)}`);
            }

            return value;
        })
        .replace(/\([^)]*\)/g, '');

    const targetDomain = domainForSite(site);

    return 'https://' + targetDomain + href + (route.hash ? route.hash : '');
}

export function redirect(to: RouteLocationNamedRaw, replace: boolean = false): Promise<any> {
    const routeName = to.name;
    if (!routeName || typeof routeName !== 'string') {
        throw new Error('Empty route given');
    }

    const site = routeName.split('.')[0] as Site;

    if (!site) {
        throw new Error('Invalid route: ' + routeName);
    }

    const targetDomain = domainForSite(site);

    if (isNil(targetDomain)) {
        throw new Error('Invalid route: ' + routeName);
    }

    if (targetDomain === window.location.hostname) { // within domain
        if (replace) {
            return router.replace(to);
        } else {
            return router.push(to);
        }
    }

    const target = matcher(site, routeName);

    if (!target) {
        throw new Error('Invalid route: ' + routeName);
    }

    const params = to.params ?? {};

    let href = target.path.replace(/:([a-zA-Z]*)/g, (_match: string, param: string) => {
        const value = params[param];

        if (value === null || value === undefined) {
            throw new Error('Missing route parameter: ' + param + ' in route: ' + routeName);
        }

        if (typeof value === 'number') {
            return value.toString();
        }

        if (Array.isArray(value)) {
            return value.toString();
        }

        return value;
    }).replace(/\([^)]*\)/g, '');

    if (!isEmpty(to.query)) {
        href += '?' + new URLSearchParams(to.query as Record<string, string>).toString();
    }

    window.location.assign('https://' + targetDomain + href);

    return new Promise(() => {});
}

export const router = createRouter({
    history: createWebHistory(),
    routes,
});
