/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyBudgetLocation } from './MoneyBudgetLocation';
    import {
    MoneyBudgetLocationFromJSON,
    MoneyBudgetLocationFromJSONTyped,
    MoneyBudgetLocationToJSON,
    MoneyBudgetLocationCreateEmpty,
    } from './MoneyBudgetLocation';
    import type { MoneySpendingItemEventResponse } from './MoneySpendingItemEventResponse';
    import {
    MoneySpendingItemEventResponseFromJSON,
    MoneySpendingItemEventResponseFromJSONTyped,
    MoneySpendingItemEventResponseToJSON,
    MoneySpendingItemEventResponseCreateEmpty,
    } from './MoneySpendingItemEventResponse';
    import type { MoneySpendingItemStatus } from './MoneySpendingItemStatus';
    import {
    MoneySpendingItemStatusFromJSON,
    MoneySpendingItemStatusFromJSONTyped,
    MoneySpendingItemStatusToJSON,
    MoneySpendingItemStatusCreateEmpty,
    } from './MoneySpendingItemStatus';
    import type { MoneySpendingMethod } from './MoneySpendingMethod';
    import {
    MoneySpendingMethodFromJSON,
    MoneySpendingMethodFromJSONTyped,
    MoneySpendingMethodToJSON,
    MoneySpendingMethodCreateEmpty,
    } from './MoneySpendingMethod';
import {DateTime} from '@Utils/DateTime';
import { MoneyBudgetLocationValues } from './MoneyBudgetLocation';
import { MoneySpendingMethodValues } from './MoneySpendingMethod';
import { MoneySpendingItemStatusValues } from './MoneySpendingItemStatus';

export interface MoneySpendingItemResponse {
  id: number;
  location: MoneyBudgetLocation;
  itemId: number;
  name: string;
  money: number;
  method: MoneySpendingMethod;
  status: MoneySpendingItemStatus;
  createdAt: DateTime;
  events: Array<MoneySpendingItemEventResponse>;
}

export const MoneySpendingItemResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: 'ID',
    type: 'number',
      minimum: 1,
  },
  'location': {
    name: 'location',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Gazdasági láb",
        enumValues: MoneyBudgetLocationValues,
  },
  'itemId': {
    name: 'itemId',
    required: true,
    nullable: false,
    label: 'Költségvetési tétel azonosítója',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Megnevezés',
    type: 'string',
      minLength: 2,
      maxLength: 150,
  },
  'money': {
    name: 'money',
    required: true,
    nullable: false,
    label: 'Összeg',
    type: 'number',
  },
  'method': {
    name: 'method',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Típus",
        enumValues: MoneySpendingMethodValues,
  },
  'status': {
    name: 'status',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Állapot",
        enumValues: MoneySpendingItemStatusValues,
  },
  'createdAt': {
    name: 'createdAt',
    required: true,
    nullable: false,
    label: 'Létrehozás dátuma',
    type: 'datetime',
  },
  'events': {
    name: 'events',
    required: true,
    nullable: false,
    label: 'Események',
      type: 'array',
  },
}
export function instanceOfMoneySpendingItemResponse(value: object): value is MoneySpendingItemResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "itemId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "money" in value;
    isInstance = isInstance && "method" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "events" in value;

return isInstance;
}

export function MoneySpendingItemResponseFromJSON(json: any): MoneySpendingItemResponse {
return MoneySpendingItemResponseFromJSONTyped(json, false);
}

export function MoneySpendingItemResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneySpendingItemResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
              'location': MoneyBudgetLocationFromJSON(json['location']),
          'itemId': json['itemId'],
          'name': json['name'],
          'money': json['money'],
              'method': MoneySpendingMethodFromJSON(json['method']),
              'status': MoneySpendingItemStatusFromJSON(json['status']),
        'createdAt': (DateTime.fromISO(json['createdAt'])),
            'events': ((json['events'] as Array<any>).map(MoneySpendingItemEventResponseFromJSON)),
    };
  }

  export function MoneySpendingItemResponseToJSON(value?: MoneySpendingItemResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'location': MoneyBudgetLocationToJSON(value.location),
                'itemId': value.itemId,
                'name': value.name,
                'money': value.money,
                'method': MoneySpendingMethodToJSON(value.method),
                'status': MoneySpendingItemStatusToJSON(value.status),
            'createdAt': (value.createdAt.toISO()),
              'events': ((value.events as Array<any>).map(MoneySpendingItemEventResponseToJSON)),
    };
  }

  export function MoneySpendingItemResponseCreateEmpty(): MoneySpendingItemResponse {
  return {
      'id': 0,
          'location': MoneyBudgetLocationCreateEmpty(),
      'itemId': 0,
      'name': '',
      'money': 0,
          'method': MoneySpendingMethodCreateEmpty(),
          'status': MoneySpendingItemStatusCreateEmpty(),
      'createdAt': DateTime.now(),
      'events': [],
  };
  }

