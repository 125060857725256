import {useAuthStore} from "@/stores/auth";
import type {Router} from "vue-router";
import {useConstantsStore} from "@/stores/constants";
import type {App} from "vue";
import {forEach} from "lodash-es";

export default class {
    static async init(): Promise<void> {
        await useConstantsStore().init();

        await useAuthStore().init();
    }

    static install(vue: App, router: Router) {
        this.utilities();
        this.transitions(router);
    }

    private static transitions(router: Router) {
        router.afterEach(to => {
            document.body.classList.remove('loading-body');

            if (!('cannotBeAuthRedirectTarget' in to.meta)) {
                useAuthStore().setTarget({
                    hash: to.hash,
                    name: to.name ?? undefined,
                    params: to.params,
                    query: to.query,
                });
            }

            forEach(to.matched, component => {
                forEach(component.components, instance => {
                    if (instance && 'title' in instance) {
                        document.title = instance.title as string;
                    }
                });
            });
        });
    }

    private static utilities() {
        Number.prototype.huf = function(): string {
            return this.thousands() + ' HUF';
        };

        Number.prototype.thousands = function(): string {
            return this.toLocaleString('hu', {useGrouping: true});
        };

        String.prototype.limit = function(limit: number): string {
            if (this.length > limit) {
                return this.substring(0, limit) + '...';
            }

            return this.valueOf();
        };

        String.prototype.ucfirst = function(): string {
            return this.charAt(0).toUpperCase().concat(this.substring(1));
        };

        Object.defineProperty(Array.prototype, 'keyBy', {
            value: function<T>(mapper: (item: T) => number) {
                const result: Record<number, T> = {};
                this.forEach((item: T) => {
                    result[mapper(item)] = item;
                });
                return result;
            }
        });
    }
}
