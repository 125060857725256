/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsReservationPeriodUpdate {
  responsible: string;
  start: DateTime;
  finish: DateTime;
  editable: boolean;
  active: boolean;
}

export const EventsReservationPeriodUpdateSchema = {
  'responsible': {
    name: 'responsible',
    required: true,
    nullable: false,
    label: 'Felelős',
      labelHelp: `Ez a felhasználó tudja az időszak beállításait módosítani, konfliktusokat feloldani`,
    type: 'string',
      minLength: 32,
      maxLength: 32,
  },
  'start': {
    name: 'start',
    required: true,
    nullable: false,
    label: 'Időszak kezdete',
    type: 'datetime',
  },
  'finish': {
    name: 'finish',
    required: true,
    nullable: false,
    label: 'Időszak vége',
    type: 'datetime',
  },
  'editable': {
    name: 'editable',
    required: true,
    nullable: false,
    label: 'Szerkeszthető',
      labelHelp: `Ez állítja, hogy fel lehet-e venni új rendezvény, vagy meglévőt módosítani`,
    type: 'boolean',
  },
  'active': {
    name: 'active',
    required: true,
    nullable: false,
    label: 'Aktív időszak',
      labelHelp: `Ez állítja, hogy az időszak megjelenik-e a felhasználóknak`,
    type: 'boolean',
  },
}
export function instanceOfEventsReservationPeriodUpdate(value: object): value is EventsReservationPeriodUpdate {
let isInstance = true;
    isInstance = isInstance && "responsible" in value;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "finish" in value;
    isInstance = isInstance && "editable" in value;
    isInstance = isInstance && "active" in value;

return isInstance;
}

export function EventsReservationPeriodUpdateFromJSON(json: any): EventsReservationPeriodUpdate {
return EventsReservationPeriodUpdateFromJSONTyped(json, false);
}

export function EventsReservationPeriodUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsReservationPeriodUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'responsible': json['responsible'],
        'start': (DateTime.fromISO(json['start'])),
        'finish': (DateTime.fromISO(json['finish'])),
          'editable': json['editable'],
          'active': json['active'],
    };
  }

  export function EventsReservationPeriodUpdateToJSON(value?: EventsReservationPeriodUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'responsible': value.responsible,
            'start': (value.start.toISO()),
            'finish': (value.finish.toISO()),
                'editable': value.editable,
                'active': value.active,
    };
  }

  export function EventsReservationPeriodUpdateCreateEmpty(): EventsReservationPeriodUpdate {
  return {
      'responsible': '',
      'start': DateTime.now(),
      'finish': DateTime.now(),
      'editable': false,
      'active': false,
  };
  }

