<template>
    <PegasusHeading>Lezáratás kalkulátor</PegasusHeading>

    <PegasusPanel class="mb-2" header="Lezáratás költség">
        <div class="row">
            <div class="col-lg-6">
                <SelectField
                    v-model="closure.type"
                    :options="dictObject(closureTypes, type => type.displayName)"
                    :schema="EventsClosureUpdateSchema.type"
                ></SelectField>

                <div class="row">
                    <div class="col-lg-6">
                        <DatetimeField
                            v-model="closure.begin"
                            :schema="EventsClosureStoreSchema.begin"
                        ></DatetimeField>
                    </div>
                    <div class="col-lg-6">
                        <DatetimeField
                            v-model="closure.end"
                            :schema="EventsClosureStoreSchema.end"
                        ></DatetimeField>
                    </div>
                </div>

                <h5>Lezáratás hossza: {{ closure.hours }} óra</h5>
                <h5>Lezáratás költsége: {{ closure.price.huf() }}</h5>
                <div>Költség számítás módja: (Hossz órában + 2) * óradíj</div>

                <hr>

                <p>Ez a pénz fedezéséhez a kör esetén szükséges alkalmas elfogadott KPR tétel, elfogadott módosító vagy
                    elfogadott RVT keretigény.
                    <b>Ez alól pultigénylés megléte sem ad kivételt!</b>
                </p>

                <p>Magánrendezvény esetén egyeztetés szükséges a <a href="mailto:bejelentes@sch.bme.hu">bejelentes@sch.bme.hu</a>
                    levelezőlistán!</p>

                <div v-if="closure.disrupts">
                    A fenti lezáratás előtt plakátolásra lesz szükség!
                </div>
            </div>
            <div class="col-lg-6">
                <div class="col-lg-6">
                    <div>
                        <span v-for="(svg, id) in maps[closure.type.id]" :key="id" v-html="svg"></span>
                    </div>
                </div>
            </div>
        </div>
    </PegasusPanel>
</template>

<title>Lezáratás kalkulátor</title>

<script lang="ts" setup>
import {useAuthStore} from '@/stores/auth';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {BaseGlobalPermission, EventsClosureStoreSchema, EventsClosureUpdateSchema} from '@/api/models/index';
import {AccessDeniedError} from '@Utils/errors/AccessDeniedError';
import {dictObject} from '@Models/Utils';
import SelectField from '@Components/base/form/types/SelectField.vue';
import {useConstantsStore} from '@/stores/constants';
import {ref} from 'vue';
import {Closure} from '@Models/events/Closure';
import {each} from 'lodash-es';
import DatetimeField from '@Components/base/form/types/DatetimeField.vue';

const user = useAuthStore().requireUser();

if (!user.value.hasPermission(BaseGlobalPermission.EVENTS_PLANNING)) {
    throw new AccessDeniedError();
}

const closureTypes = useConstantsStore().constants.events.closureTypes;

const closure = ref(Closure.createEmpty());

const maps = ref<Record<number, string[]>>({});

each(closureTypes, type => {
    type.getMap().then(map => {
        maps.value[type.id] = map;
    });
});
</script>
