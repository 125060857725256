<template>
    <PegasusHeading>TODO lista</PegasusHeading>

    <WaitingApprove
        :waiting-approve="waitingApprove"
    ></WaitingApprove>

    <WaitingReport
        :waiting-ack="waitingAck"
        :waiting-report="waitingReport"
    ></WaitingReport>

    <WaitingAck
        :waiting-ack="waitingAck"
    ></WaitingAck>

    <ServicesReport
        :services="services"
        :services-ack="servicesAck"
    ></ServicesReport>

    <ServicesAck
        :services-ack="servicesAck"
    ></ServicesAck>
</template>

<title>Rendezvények admin</title>

<script lang="ts" setup>
import Event from "@Models/events/Event";
import {Service} from "@Models/events/Service";
import {ref} from "vue";
import {promiseAll} from '@Utils/misc';
import WaitingApprove from "@Components/events/todo/WaitingApprove.vue";
import WaitingReport from "@Components/events/todo/WaitingReport.vue";
import WaitingAck from "@Components/events/todo/WaitingAck.vue";
import ServicesReport from "@Components/events/todo/ServicesReport.vue";
import ServicesAck from "@Components/events/todo/ServicesAck.vue";
import {useAuthStore} from "@/stores/auth";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

useAuthStore().requireUser();

const data = await promiseAll({
    waitingApprove: Event.getWaitingApprove(),
    waitingReport: Event.getWaitingReport(),
    waitingAck: Event.getWaitingReportAck(),
    services: Service.waiting(),
    servicesAck: Service.waitingAck(),
});

const waitingApprove = ref(data.waitingApprove);
const waitingReport = ref(data.waitingReport);
const waitingAck = ref(data.waitingAck);
const services = ref(data.services);
const servicesAck = ref(data.servicesAck);
</script>
