import ac from "./groups/Ac.vue";
import bar from "./groups/Bar.vue";
import bss from "./groups/Bss.vue";
import spot from "./groups/Spot.vue";
import carpermit from "./facilities/Carpermit.vue";
import electricpermit from "./facilities/Electricpermit.vue";
import entrancepermit from "./facilities/Entrancepermit.vue";
import extratable from "./assets/Extratable.vue";
import guestlist from "./facilities/Guestlist.vue";
import heatingpermit from "./facilities/HeatingPermit.vue";
import tableset from "./assets/Tableset.vue";
import kitchenreservation from "./assets/KitchenReservation.vue";
import workerlist from "./facilities/Workerlist.vue";
import elevatorpermit from "./facilities/Elevatorpermit.vue";
import calendarevent from "./assets/CalendarEvent.vue";

export default {
    ac,
    bar,
    bss,
    spot,
    carpermit,
    electricpermit,
    entrancepermit,
    extratable,
    guestlist,
    heatingpermit,
    tableset,
    kitchenreservation,
    workerlist,
    elevatorpermit,
    calendarevent,
};
