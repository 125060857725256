/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseExtendedUserResponse } from './BaseExtendedUserResponse';
    import {
    BaseExtendedUserResponseFromJSON,
    BaseExtendedUserResponseFromJSONTyped,
    BaseExtendedUserResponseToJSON,
    BaseExtendedUserResponseCreateEmpty,
    } from './BaseExtendedUserResponse';
    import type { BaseGlobalPermission } from './BaseGlobalPermission';
    import {
    BaseGlobalPermissionFromJSON,
    BaseGlobalPermissionFromJSONTyped,
    BaseGlobalPermissionToJSON,
    BaseGlobalPermissionCreateEmpty,
    } from './BaseGlobalPermission';
import {DateTime} from '@Utils/DateTime';

export interface BaseGlobalPermissionAssignmentResponse {
  user: BaseExtendedUserResponse;
  assignedGlobalPermissions: Array<BaseGlobalPermission>;
}

export const BaseGlobalPermissionAssignmentResponseSchema = {
  'user': {
    name: 'user',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'assignedGlobalPermissions': {
    name: 'assignedGlobalPermissions',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfBaseGlobalPermissionAssignmentResponse(value: object): value is BaseGlobalPermissionAssignmentResponse {
let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "assignedGlobalPermissions" in value;

return isInstance;
}

export function BaseGlobalPermissionAssignmentResponseFromJSON(json: any): BaseGlobalPermissionAssignmentResponse {
return BaseGlobalPermissionAssignmentResponseFromJSONTyped(json, false);
}

export function BaseGlobalPermissionAssignmentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGlobalPermissionAssignmentResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'user': BaseExtendedUserResponseFromJSON(json['user']),
            'assignedGlobalPermissions': ((json['assignedGlobalPermissions'] as Array<any>).map(BaseGlobalPermissionFromJSON)),
    };
  }

  export function BaseGlobalPermissionAssignmentResponseToJSON(value?: BaseGlobalPermissionAssignmentResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'user': BaseExtendedUserResponseToJSON(value.user),
              'assignedGlobalPermissions': ((value.assignedGlobalPermissions as Array<any>).map(BaseGlobalPermissionToJSON)),
    };
  }

  export function BaseGlobalPermissionAssignmentResponseCreateEmpty(): BaseGlobalPermissionAssignmentResponse {
  return {
          'user': BaseExtendedUserResponseCreateEmpty(),
      'assignedGlobalPermissions': [],
  };
  }

