import type {RouteRecordRaw} from "vue-router";
import {getNumberParam, getNumberParamNullable, getStringParam} from "@Models/Utils";

const rvt: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'rvt.index',
        component: () => import('@Pages/rvt/Index.vue'),
    },
    {
        path: '/rvt',
        name: 'rvt.main',
        component: () => import('@Pages/rvt/Main.vue'),
    },
    {
        path: '/profile',
        name: 'rvt.profile',
        component: () => import('@Pages/rvt/user/Profile.vue'),
    },
    {
        path: '/spendingPeriods',
        name: 'rvt.spendingPeriods.index',
        component: () => import('@Pages/rvt/spendingPeriods/Index.vue'),
    },
    {
        path: '/spendingPeriods/:period',
        name: 'rvt.spendingPeriod.show',
        component: () => import('@Pages/rvt/spendingPeriods/Show.vue'),
        props: route => ({
            periodId: getNumberParam(route, 'period'),
        }),
    },
    {
        path: '/resort/:group',
        name: 'rvt.resorts.show',
        component: () => import('@Pages/rvt/groups/Resort.vue'),
        props: route => ({
            groupId: getNumberParam(route, 'group'),
        }),
    },
    {
        path: '/groups/:group',
        name: 'rvt.groups.show',
        component: () => import('@Pages/rvt/groups/Group.vue'),
        props: route => ({
            groupId: getNumberParam(route, 'group'),
        }),
    },
    {
        path: '/groups/:group/archive',
        name: 'rvt.groups.archive',
        component: () => import('@Pages/rvt/groups/Archive.vue'),
        props: route => ({
            groupId: getNumberParam(route, 'group'),
        }),
    },
    {
        path: '/groups/:group/money/:period',
        name: 'rvt.groups.money',
        component: () => import('@Pages/rvt/groups/Money.vue'),
        props: route => ({
            groupId: getNumberParam(route, 'group'),
            periodId: getNumberParam(route, 'period'),
        }),
    },
    {
        path: '/groups/:group/statements/:year?',
        name: 'rvt.groups.statements',
        component: () => import('@Pages/rvt/groups/Statements.vue'),
        props: route => ({
            groupId: getNumberParam(route, 'group'),
            selectedYear: getNumberParamNullable(route, 'year'),
        }),
    },
    {
        path: '/groups/:group/settings',
        name: 'rvt.groups.settings',
        component: () => import('@Pages/rvt/groups/Settings.vue'),
        props: route => ({
            groupId: getNumberParam(route, 'group'),
        }),
    },
    {
        path: '/claims/:claim',
        name: 'rvt.claims.show',
        component: () => import('@Pages/rvt/requests/claims/Claim.vue'),
        props: route => ({
            claimId: getNumberParam(route, 'claim'),
        }),
    },
    {
        path: '/amends/:amend',
        name: 'rvt.amends.show',
        component: () => import('@Pages/rvt/requests/amends/Amend.vue'),
        props: route => ({
            amendId: getNumberParam(route, 'amend'),
        }),
    },
    {
        path: '/kpr/tenders/:tender',
        name: 'rvt.kpr.tender.show',
        component: () => import('@Pages/kpr/Tender.vue'),
        props: route => ({
            tenderId: getNumberParam(route, 'tender'),
        }),
    },
    {
        path: '/admin/kpr/tenders/:tender',
        name: 'rvt.kpr.tender.admin',
        component: () => import('@Pages/rvt/admin/kpr/Show.vue'),
        props: route => ({
            tenderId: getNumberParam(route, 'tender'),
        }),
    },
    {
        path: '/kpr/tenders/:tender/amends',
        name: 'rvt.kpr.tender.amends',
        component: () => import('@Pages/rvt/requests/amends/Index.vue'),
        props: route => ({
            tenderId: getNumberParam(route, 'tender'),
        }),
    },
    {
        path: '/kpr/tender/:tender/claims',
        name: 'rvt.kpr.tender.claims',
        component: () => import('@Pages/rvt/requests/claims/Index.vue'),
        props: route => ({
            tenderId: getNumberParam(route, 'tender'),
        }),
    },
    {
        path: '/kpr/tender/:tender/statistics',
        name: 'rvt.kpr.tender.statistics',
        component: () => import('@Pages/kpr/Statistics.vue'),
        props: route => ({
            tenderId: getNumberParam(route, 'tender'),
        }),
    },
    {
        path: '/kpr/tender/:tender/itemsByFlag/:flag',
        name: 'rvt.kpr.tender.itemsByFlag',
        component: () => import('@Pages/kpr/ItemsByFlag.vue'),
        props: route => ({
            tenderId: getNumberParam(route, 'tender'),
            flagId: getNumberParam(route, 'flag'),
        }),
    },
    {
        path: '/kpr/:application',
        name: 'rvt.kpr.application',
        component: () => import('@Pages/kpr/Application.vue'),
        props: route => ({
            applicationId: getNumberParam(route, 'application'),
        }),
    },
    {
        path: '/kpr/:application/edit/:category',
        name: 'rvt.kpr.edit',
        component: () => import('@Pages/kpr/Edit.vue'),
        props: route => ({
            applicationId: getNumberParam(route, 'application'),
            categoryName: getStringParam(route, 'category'),
        }),
    },
    {
        path: '/admin',
        name: 'rvt.admin.index',
        component: () => import('@Pages/rvt/admin/Index.vue'),
    },
    {
        path: '/admin/system',
        name: 'rvt.admin.system',
        component: () => import('@Pages/rvt/admin/System.vue'),
    },
    {
        path: '/admin/system/emails',
        name: 'rvt.admin.system.emails',
        component: () => import('@Pages/rvt/admin/system/Emails.vue'),
    },
    {
        path: '/admin/users',
        name: 'rvt.admin.users.index',
        component: () => import('@Pages/rvt/admin/users/Index.vue'),
    },
    {
        path: '/admin/users/:user',
        name: 'rvt.admin.users.show',
        component: () => import('@Pages/rvt/admin/users/User.vue'),
        props: route => ({
            userId: getStringParam(route, 'user'),
        }),
    },
    {
        path: '/admin/groups',
        name: 'rvt.admin.groups',
        component: () => import('@Pages/rvt/admin/Groups.vue'),
    },
];

export default rvt;
