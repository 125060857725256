/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsReservationReassign {
  responsible: string;
}

export const EventsReservationReassignSchema = {
  'responsible': {
    name: 'responsible',
    required: true,
    nullable: false,
    label: 'Foglaló',
    type: 'string',
      minLength: 32,
      maxLength: 32,
  },
}
export function instanceOfEventsReservationReassign(value: object): value is EventsReservationReassign {
let isInstance = true;
    isInstance = isInstance && "responsible" in value;

return isInstance;
}

export function EventsReservationReassignFromJSON(json: any): EventsReservationReassign {
return EventsReservationReassignFromJSONTyped(json, false);
}

export function EventsReservationReassignFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsReservationReassign {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'responsible': json['responsible'],
    };
  }

  export function EventsReservationReassignToJSON(value?: EventsReservationReassign | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'responsible': value.responsible,
    };
  }

  export function EventsReservationReassignCreateEmpty(): EventsReservationReassign {
  return {
      'responsible': '',
  };
  }

