/* tslint:disable */
/* eslint-disable */

export enum BaseAuthschEntitlementStatus {
    OLD = 'öregtag',
    MEMBER = 'tag',
    LEADER = 'körvezető'
}

export const BaseAuthschEntitlementStatusValues: Record<BaseAuthschEntitlementStatus, string> = {
    'öregtag': 'Öregtag',
    'tag': 'Tag',
    'körvezető': 'Körvezető'
}


export function BaseAuthschEntitlementStatusFromJSON(json: any): BaseAuthschEntitlementStatus {
    return BaseAuthschEntitlementStatusFromJSONTyped(json, false);
}

export function BaseAuthschEntitlementStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseAuthschEntitlementStatus {
    return json as BaseAuthschEntitlementStatus;
}

export function BaseAuthschEntitlementStatusToJSON(value?: BaseAuthschEntitlementStatus | null): any {
    return value as any;
}

export function BaseAuthschEntitlementStatusCreateEmpty(): BaseAuthschEntitlementStatus {
    return BaseAuthschEntitlementStatus.OLD;
}

