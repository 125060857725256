/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseGlobalPermission } from './BaseGlobalPermission';
    import {
    BaseGlobalPermissionFromJSON,
    BaseGlobalPermissionFromJSONTyped,
    BaseGlobalPermissionToJSON,
    BaseGlobalPermissionCreateEmpty,
    } from './BaseGlobalPermission';
    import type { BaseGroupAccessResponse } from './BaseGroupAccessResponse';
    import {
    BaseGroupAccessResponseFromJSON,
    BaseGroupAccessResponseFromJSONTyped,
    BaseGroupAccessResponseToJSON,
    BaseGroupAccessResponseCreateEmpty,
    } from './BaseGroupAccessResponse';
    import type { MapRoomResponse } from './MapRoomResponse';
    import {
    MapRoomResponseFromJSON,
    MapRoomResponseFromJSONTyped,
    MapRoomResponseToJSON,
    MapRoomResponseCreateEmpty,
    } from './MapRoomResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseExtendedUserResponse {
  neptun: string | null;
  groupAccesses: Array<BaseGroupAccessResponse>;
  pekSync: DateTime | null;
  updatedAt: DateTime;
  createdAt: DateTime;
  globalPermissions: Array<BaseGlobalPermission>;
  pek: string | null;
  room: MapRoomResponse | null;
  phone: string | null;
  phoneLink: string | null;
  phoneShort: string | null;
  id: string;
  name: string;
  nick: string | null;
  email: string;
}

export const BaseExtendedUserResponseSchema = {
  'neptun': {
    name: 'neptun',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'groupAccesses': {
    name: 'groupAccesses',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'pekSync': {
    name: 'pekSync',
    required: true,
    nullable: true,
    label: '',
    type: 'datetime',
  },
  'updatedAt': {
    name: 'updatedAt',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'createdAt': {
    name: 'createdAt',
    required: true,
    nullable: false,
    label: '',
    type: 'datetime',
  },
  'globalPermissions': {
    name: 'globalPermissions',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'pek': {
    name: 'pek',
    required: true,
    nullable: true,
    label: 'Pék felhasználónév',
    type: 'string',
  },
  'room': {
    name: 'room',
    required: true,
    nullable: true,
      type: 'embedded',
  },
  'phone': {
    name: 'phone',
    required: true,
    nullable: true,
    label: 'Telefonszám',
    type: 'string',
  },
  'phoneLink': {
    name: 'phoneLink',
    required: true,
    nullable: true,
    label: 'Telefonszám linkként',
    type: 'string',
  },
  'phoneShort': {
    name: 'phoneShort',
    required: true,
    nullable: true,
    label: 'Telefonszám előhívő nélkül',
    type: 'string',
  },
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'nick': {
    name: 'nick',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'email': {
    name: 'email',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
}
export function instanceOfBaseExtendedUserResponse(value: object): value is BaseExtendedUserResponse {
let isInstance = true;
    isInstance = isInstance && "neptun" in value;
    isInstance = isInstance && "groupAccesses" in value;
    isInstance = isInstance && "pekSync" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "globalPermissions" in value;
    isInstance = isInstance && "pek" in value;
    isInstance = isInstance && "room" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "phoneLink" in value;
    isInstance = isInstance && "phoneShort" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "nick" in value;
    isInstance = isInstance && "email" in value;

return isInstance;
}

export function BaseExtendedUserResponseFromJSON(json: any): BaseExtendedUserResponse {
return BaseExtendedUserResponseFromJSONTyped(json, false);
}

export function BaseExtendedUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseExtendedUserResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'neptun': json['neptun'],
            'groupAccesses': ((json['groupAccesses'] as Array<any>).map(BaseGroupAccessResponseFromJSON)),
        'pekSync': (json['pekSync'] === null ? null : DateTime.fromISO(json['pekSync'])),
        'updatedAt': (DateTime.fromISO(json['updatedAt'])),
        'createdAt': (DateTime.fromISO(json['createdAt'])),
            'globalPermissions': ((json['globalPermissions'] as Array<any>).map(BaseGlobalPermissionFromJSON)),
          'pek': json['pek'],
              'room': MapRoomResponseFromJSON(json['room']),
          'phone': json['phone'],
          'phoneLink': json['phoneLink'],
          'phoneShort': json['phoneShort'],
          'id': json['id'],
          'name': json['name'],
          'nick': json['nick'],
          'email': json['email'],
    };
  }

  export function BaseExtendedUserResponseToJSON(value?: BaseExtendedUserResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'neptun': value.neptun,
              'groupAccesses': ((value.groupAccesses as Array<any>).map(BaseGroupAccessResponseToJSON)),
            'pekSync': (
              value.pekSync === null ? null : value.pekSync.toISO()),
            'updatedAt': (value.updatedAt.toISO()),
            'createdAt': (value.createdAt.toISO()),
              'globalPermissions': ((value.globalPermissions as Array<any>).map(BaseGlobalPermissionToJSON)),
                'pek': value.pek,
                'room': MapRoomResponseToJSON(value.room),
                'phone': value.phone,
                'phoneLink': value.phoneLink,
                'phoneShort': value.phoneShort,
                'id': value.id,
                'name': value.name,
                'nick': value.nick,
                'email': value.email,
    };
  }

  export function BaseExtendedUserResponseCreateEmpty(): BaseExtendedUserResponse {
  return {
      'neptun': '',
      'groupAccesses': [],
      'pekSync': DateTime.now(),
      'updatedAt': DateTime.now(),
      'createdAt': DateTime.now(),
      'globalPermissions': [],
      'pek': '',
          'room': MapRoomResponseCreateEmpty(),
      'phone': '',
      'phoneLink': '',
      'phoneShort': '',
      'id': '',
      'name': '',
      'nick': '',
      'email': '',
  };
  }

