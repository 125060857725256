import type {RouteRecordRaw} from "vue-router";
import {getNumberParam, getStringParam} from "@Models/Utils";

const inventory: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'inventory.index',
        component: () => import('@Pages/inventory/Index.vue'),
    },
    {
        path: '/public/check',
        name: 'inventory.public.check',
        component: () => import('@Pages/inventory/public/Check.vue'),
    },
    {
        path: '/items/filter',
        name: 'inventory.items.filter',
        component: () => import('@Pages/inventory/ItemFilter.vue'),
    },
    {
        path: '/items/:code',
        name: 'inventory.items.default',
        component: () => import('@Pages/inventory/item/Show.vue'),
        props: route => ({
            code: getStringParam(route, 'code'),
        }),
    },
    {
        path: '/administrator',
        name: 'inventory.administrator',
        component: () => import('@Pages/inventory/Administrator.vue'),
    },
    {
        path: '/groups/:group',
        name: 'inventory.groups.index',
        component: () => import('@Pages/inventory/group/Index.vue'),
        props: route => ({
            groupId: getNumberParam(route, 'group'),
        }),
    },
    {
        path: '/groups/:group/statistics',
        name: 'inventory.groups.statistics',
        component: () => import('@Pages/inventory/group/Statistics.vue'),
        props: route => ({
            groupId: getNumberParam(route, 'group'),
        }),
    },
    {
        path: '/groups/:group/create/existing',
        name: 'inventory.groups.create.existing',
        component: () => import('@Pages/inventory/item/CreateExisting.vue'),
        props: route => ({
            groupId: getNumberParam(route, 'group'),
        }),
    },
    {
        path: '/admin',
        name: 'inventory.admin.index',
        component: () => import('@Pages/inventory/admin/Index.vue'),
    },
    {
        path: '/admin/owners/:owner',
        name: 'inventory.admin.owner',
        component: () => import('@Pages/inventory/admin/Owner.vue'),
        props: route => ({
            ownerId: getNumberParam(route, 'owner'),
        }),
    },
];

export default inventory;
