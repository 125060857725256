<template>
    <PegasusHeading>Schönherz Leltár Admin</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel class="mb-2" header="Információk">
                <dl>
                    <dt>Tételek száma</dt>
                    <dd>{{ summary.itemCount.thousands() }}</dd>
                    <dt>Össz érték</dt>
                    <dd>{{ summary.priceTotal.huf() }}</dd>
                </dl>
            </PegasusPanel>

            <PanelByTags
                :filter="{}"
                :tag-counts="summary.tagCounts"
            ></PanelByTags>
        </div>

        <div class="col-lg-6">
            <PegasusPanel class="mb-2" header="E-mail sablonok">
                <h5>Kör leltár felelősei kapják</h5>
                <PegasusButton @click="emailTemplate('InventoryItemCreated')">
                    Leltár eszköz létrehozva
                </PegasusButton>
                <PegasusButton @click="emailTemplate('InventoryMissedWarningFirst')">
                    Elmaradás első figyelmeztetés
                </PegasusButton>
                <PegasusButton @click="emailTemplate('InventoryMissedWarningSecond')">
                    Elmaradás második figyelmeztetés
                </PegasusButton>
                <PegasusButton @click="emailTemplate('InventoryMissedWarningThird')">
                    Elmaradás harmadik figyelmeztetés
                </PegasusButton>
            </PegasusPanel>

            <PegasusPanel header="Tulajdonosok">
                <PegasusTable :data="summary.owners">
                    <Column field="owner.name" header="Tulajdonos">
                        <template #body="{data}: {data: OwnerSummary}">
                            <PegasusLink :params="{owner: data.owner}" to="inventory.admin.owner">
                                {{ data.owner.name }}
                            </PegasusLink>
                        </template>
                    </Column>

                    <Column field="itemCount" header="Tételek száma"></Column>

                    <Column :field="item => item.priceTotal.huf()" header="Becsült teljes érték"></Column>
                </PegasusTable>
            </PegasusPanel>
        </div>
    </div>

    <hr>

    <PegasusPanel header="Körök">
        <TabView>
            <TabPanel v-for="resort in resorts" :key="resort.id" :header="resort.shortName ?? resort.name">
                <PegasusTable :data="resort.getActiveChildrenGroupsWithResort({inventoryEnabled: true})">
                    <Column field="name" header="Kör neve">
                        <template #body="{data}: {data: Group}">
                            <PegasusLink :params="{group: data}" to="inventory.groups.index">
                                {{ data.name }}
                            </PegasusLink>
                        </template>
                    </Column>

                    <Column
                        :field="item => summary.groups[item.id].rootGroup.itemCountWithDescendants.toString()"
                        header="Tételek száma"
                    ></Column>

                    <Column
                        v-for="tag in severeTags"
                        :key="tag.id"
                        :field="item => summary.groups[item.id].tagCounts[tag.id]?.itemCount.toString() ?? '0'"
                        :header="tag.name"
                    >
                        <template #body="{data}: {data: Group}">
                            <div v-if="summary.groups[data.id].tagCounts[tag.id]?.itemCount > 0">
                                <i :class="'fa fa-exclamation-triangle text-' + tag.severity"></i>
                                {{ summary.groups[data.id].tagCounts[tag.id].itemCount }}
                            </div>
                            <div v-else>
                                -
                            </div>
                        </template>
                    </Column>
                </PegasusTable>
            </TabPanel>
        </TabView>
    </PegasusPanel>
</template>

<title>Schönherz Leltár adminisztrátor</title>

<script lang="ts" setup>
import {AdminSummary} from "@Models/inventory/AdminSummary";
import {useConstantsStore} from "@/stores/constants";
import Column from "primevue/column";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusLink from "@Components/base/PegasusLink.vue";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import type {Group} from "@Models/base/Group";
import {useAuthStore} from "@/stores/auth";
import type {OwnerSummary} from "@Models/inventory/OwnerSummary";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {Dialog} from '@Utils/dialog';
import EmailPreviewDialog from '@Components/system/emails/EmailPreviewDialog.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {filter} from 'lodash-es';
import PanelByTags from '@Components/inventory/statistics/PanelByTags.vue';

useAuthStore().requireUser();

const summary = await AdminSummary.get();

const constants = useConstantsStore().constants;

const resorts = constants.groups.calculated;

const severeTags = filter(constants.inventory.tags, tag => tag.severity === 'danger' || tag.severity === 'warning');

async function emailTemplate(template: string) {
    Dialog.open(EmailPreviewDialog, {template});
}
</script>
