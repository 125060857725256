/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface InventoryGenericStatisticsResponse {
  countedById: number;
  itemCount: number;
  sumPrice: number;
}

export const InventoryGenericStatisticsResponseSchema = {
  'countedById': {
    name: 'countedById',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'itemCount': {
    name: 'itemCount',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'sumPrice': {
    name: 'sumPrice',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
}
export function instanceOfInventoryGenericStatisticsResponse(value: object): value is InventoryGenericStatisticsResponse {
let isInstance = true;
    isInstance = isInstance && "countedById" in value;
    isInstance = isInstance && "itemCount" in value;
    isInstance = isInstance && "sumPrice" in value;

return isInstance;
}

export function InventoryGenericStatisticsResponseFromJSON(json: any): InventoryGenericStatisticsResponse {
return InventoryGenericStatisticsResponseFromJSONTyped(json, false);
}

export function InventoryGenericStatisticsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryGenericStatisticsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'countedById': json['countedById'],
          'itemCount': json['itemCount'],
          'sumPrice': json['sumPrice'],
    };
  }

  export function InventoryGenericStatisticsResponseToJSON(value?: InventoryGenericStatisticsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'countedById': value.countedById,
                'itemCount': value.itemCount,
                'sumPrice': value.sumPrice,
    };
  }

  export function InventoryGenericStatisticsResponseCreateEmpty(): InventoryGenericStatisticsResponse {
  return {
      'countedById': 0,
      'itemCount': 0,
      'sumPrice': 0,
  };
  }

