import {Group} from './Group';
import {Membership} from '@Models/base/Membership';
import {DateTime} from '@Utils/DateTime';
import type {
    AuthAuthschProfileResponse,
    BaseExtendedUserResponse,
    BaseMinimalUserResponse,
    BaseUserResponse,
    BaseUserSearch,
    BaseUserUpdate,
} from '@/api/api';
import {
    BaseGlobalPermission,
    BaseGroupSpecificPermission,
    instanceOfBaseExtendedUserResponse,
    MainApi,
} from '@/api/api';
import {filter, find, forEach, isArray, isObject, map} from 'lodash-es';
import {GroupAccess} from '@Models/base/GroupAccess';
import {Room} from '@Models/map/Room';
import type {FormRef} from '@/vue';
import {BaseEntityString} from '@Models/BaseEntityString';

export class User extends BaseEntityString {
    name: string;

    email: string;

    nick: string | null = null;

    phone: string | null = null;

    room: Room | null = null;

    pek: string | null = null;

    groupAccesses: Record<number, GroupAccess>;

    permissions: BaseGlobalPermission[] | null = null;

    pekSync: DateTime | null = null;

    updatedAt: DateTime | null = null;

    createdAt: DateTime | null = null;

    static searchUsers(currentPage: number, perPage: number, term: string): Promise<{data: User[], total: number}> {
        const data: BaseUserSearch = {
            perPage: perPage,
            currentPage: currentPage,
            searchTerm: term,
        };

        return MainApi.usersSearch(data).then(response => {
            return {
                data: User.newArray(response.data.data, User.parseMinimalResponse),
                total: response.data.total,
            };
        });
    }

    static parseMinimalResponse(user: User, data: BaseMinimalUserResponse): User {
        user.id = data.id;
        user.name = data.name;
        user.nick = data.nick;
        user.email = data.email;

        return user;
    }

    static parseBaseResponse(user: User, data: BaseUserResponse): User {
        User.parseMinimalResponse(user, data);

        user.phone = data.phone;
        user.pek = data.pek;
        user.room = Room.newSingleNullable(data.room, Room.parseResponse);

        return user;
    }

    static parseExtendedResponse(user: User, data: BaseExtendedUserResponse): User {
        User.parseBaseResponse(user, data);

        user.groupAccesses = GroupAccess.newRecordsBy(data.groupAccesses, GroupAccess.parseResponse, access => access.group.id.toString());
        user.permissions = data.globalPermissions ?? null;
        user.pekSync = data.pekSync;
        user.updatedAt = data.updatedAt;
        user.createdAt = data.createdAt;

        return user;
    }

    public update(form: FormRef, room: Room | null, phone: string | null, nick: string | null = null): Promise<User> {
        const data: BaseUserUpdate = {
            room: room?.id ?? null,
            nickName: nick ?? this.nick,
            phone: phone,
        };

        return MainApi.usersUpdate(this.id, data, {form}).then(response => {
            if (instanceOfBaseExtendedUserResponse(response.data)) {
                return User.parseExtendedResponse(this, response.data);
            } else {
                return User.parseBaseResponse(this, response.data);
            }
        });
    }

    public refresh(): Promise<void> {
        return MainApi.usersRefresh(this.id).then();
    }

    public getDebugData(): Promise<AuthAuthschProfileResponse> {
        return MainApi.usersDebug(this.id).then(response => response.data);
    }

    getEventableGroups(): Group[] {
        const groups = map(
            filter(this.groupAccesses, access => access.group.eventEnabled),
            access => access.group,
        );

        groups.push(Group.createDummy('-Magánrendezvény-'));

        return groups;
    }

    public hasGroupPermission(group: Group | number, permissions: BaseGroupSpecificPermission | BaseGroupSpecificPermission[]): boolean {
        if (this.hasPermission(BaseGlobalPermission.ADMIN_ACL)) {
            return true;
        }

        let arr;
        if (isArray(permissions)) {
            arr = permissions;
        } else {
            arr = [permissions];
        }

        if (isObject(group)) {
            group = group.id;
        }

        return this.groupAccesses[group]?.hasPermission(arr) ?? false;
    }

    public hasPermission(permissions: BaseGlobalPermission | BaseGlobalPermission[]): boolean {
        if (!isArray(permissions)) {
            permissions = [permissions];
        }

        return permissions.some(permission => this.permissions?.includes(permission));
    }

    public groupsWithPermission(permissions: BaseGroupSpecificPermission[]): Record<number, Group> {
        const groups: Record<number, Group> = {};

        forEach(this.groupAccesses, access => {
            if (access.hasPermission(permissions)) {
                groups[access.group.id] = access.group;
            }
        });

        return groups;
    }

    public getAccess(group: Group): GroupAccess | null {
        return find(this.groupAccesses, access => access.group.id === group.id) ?? null;
    }

    public inventoryGroups(): Record<number, Group> {
        return filter(
            this.groupsWithPermission([BaseGroupSpecificPermission.READ_INVENTORY, BaseGroupSpecificPermission.MANAGE_INVENTORY]),
            group => group.inventoryEnabled,
        );
    }

    public moneyGroups(): Record<number, Group> {
        return this.groupsWithPermission([BaseGroupSpecificPermission.READ_MONEY, BaseGroupSpecificPermission.MANAGE_MONEY]);
    }

    public eventsGroups(): Record<number, Group> {
        return this.groupsWithPermission([BaseGroupSpecificPermission.MANAGE_EVENTS]);
    }

    get leaderships(): Record<number, Group> {
        return map(
            filter(this.memberships, membership => membership.isLeader()),
            membership => membership.group,
        );
    }

    get resortLeaderships(): Record<number, Group> {
        return filter(this.leaderships, group => group.isResort());
    }

    get memberships(): Record<number, Membership> {
        const memberships: Record<number, Membership> = {};

        forEach(this.groupAccesses, access => {
            if (access.membership) {
                memberships[access.group.id] = access.membership;
            }
        });

        return memberships;
    }

    get phoneLink(): string | null {
        if (this.phone === null) {
            return null;
        }

        return 'tel:' + this.phone.replace(' ', '-');
    }

    get phoneShort(): string | null {
        if (this.phone === null) {
            return null;
        }

        return this.phone.slice(3).replace(' ', '');
    }
}
