/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { InventoryGenericStatisticsResponse } from './InventoryGenericStatisticsResponse';
    import {
    InventoryGenericStatisticsResponseFromJSON,
    InventoryGenericStatisticsResponseFromJSONTyped,
    InventoryGenericStatisticsResponseToJSON,
    InventoryGenericStatisticsResponseCreateEmpty,
    } from './InventoryGenericStatisticsResponse';
    import type { InventoryItemGroupWithHierarchyResponse } from './InventoryItemGroupWithHierarchyResponse';
    import {
    InventoryItemGroupWithHierarchyResponseFromJSON,
    InventoryItemGroupWithHierarchyResponseFromJSONTyped,
    InventoryItemGroupWithHierarchyResponseToJSON,
    InventoryItemGroupWithHierarchyResponseCreateEmpty,
    } from './InventoryItemGroupWithHierarchyResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseGroupSummaryInventoryResponse {
  groupId: number;
  itemsWithoutGroup: number;
  tagCounts: Array<InventoryGenericStatisticsResponse>;
  itemGroups: Array<InventoryItemGroupWithHierarchyResponse>;
}

export const BaseGroupSummaryInventoryResponseSchema = {
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'itemsWithoutGroup': {
    name: 'itemsWithoutGroup',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'tagCounts': {
    name: 'tagCounts',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'itemGroups': {
    name: 'itemGroups',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfBaseGroupSummaryInventoryResponse(value: object): value is BaseGroupSummaryInventoryResponse {
let isInstance = true;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "itemsWithoutGroup" in value;
    isInstance = isInstance && "tagCounts" in value;
    isInstance = isInstance && "itemGroups" in value;

return isInstance;
}

export function BaseGroupSummaryInventoryResponseFromJSON(json: any): BaseGroupSummaryInventoryResponse {
return BaseGroupSummaryInventoryResponseFromJSONTyped(json, false);
}

export function BaseGroupSummaryInventoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGroupSummaryInventoryResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'groupId': json['groupId'],
          'itemsWithoutGroup': json['itemsWithoutGroup'],
            'tagCounts': ((json['tagCounts'] as Array<any>).map(InventoryGenericStatisticsResponseFromJSON)),
            'itemGroups': ((json['itemGroups'] as Array<any>).map(InventoryItemGroupWithHierarchyResponseFromJSON)),
    };
  }

  export function BaseGroupSummaryInventoryResponseToJSON(value?: BaseGroupSummaryInventoryResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'groupId': value.groupId,
                'itemsWithoutGroup': value.itemsWithoutGroup,
              'tagCounts': ((value.tagCounts as Array<any>).map(InventoryGenericStatisticsResponseToJSON)),
              'itemGroups': ((value.itemGroups as Array<any>).map(InventoryItemGroupWithHierarchyResponseToJSON)),
    };
  }

  export function BaseGroupSummaryInventoryResponseCreateEmpty(): BaseGroupSummaryInventoryResponse {
  return {
      'groupId': 0,
      'itemsWithoutGroup': 0,
      'tagCounts': [],
      'itemGroups': [],
  };
  }

