import {BaseEntity} from '@Models/BaseEntity';
import {type MoneyBudgetItemValueResponse, MoneyBudgetLocation} from '@/api/models';

export class BudgetItemValue extends BaseEntity {
    location: MoneyBudgetLocation;

    value: number;

    static parseResponse(value: BudgetItemValue, data: MoneyBudgetItemValueResponse): BudgetItemValue {
        value.location = data.location;
        value.value = data.value;

        return value;
    }
}
