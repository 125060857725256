<template>
    <PegasusHeading>SCH Közélet adminisztrációs portál</PegasusHeading>

    <template v-if="auth.user">
        <PegasusPanel header="Körtagságok">
            <PegasusOverlay v-for="access in auth.user.groupAccesses" :key="access.group.id" :header="access.group.name" :hover="false" wide>
                <PegasusButton>{{ access.group.name }}</PegasusButton>

                <template #overlay>
                    <GroupLinks :group="access.group"></GroupLinks>

                    <GroupAccess :access="access" :user="auth.user"></GroupAccess>
                </template>
            </PegasusOverlay>
        </PegasusPanel>
    </template>

    <div v-else>
        <p>Az oldal használatához bejelentkezés szükséges</p>
    </div>
</template>

<title>Schönherz Közéleti Rendszer</title>

<script lang="ts" setup>
import {useAuthStore} from "@/stores/auth";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import PegasusOverlay from '@Components/base/PegasusOverlay.vue';
import GroupLinks from '@Components/rvt/GroupLinks.vue';
import GroupAccess from '@Components/rvt/groups/GroupAccess.vue';

const auth = useAuthStore();
</script>
