/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MapRoomResponse } from './MapRoomResponse';
    import {
    MapRoomResponseFromJSON,
    MapRoomResponseFromJSONTyped,
    MapRoomResponseToJSON,
    MapRoomResponseCreateEmpty,
    } from './MapRoomResponse';
import {DateTime} from '@Utils/DateTime';

export interface InventoryRoomStatisticsResponse {
  room: MapRoomResponse;
  itemCount: number;
  sumPrice: number;
}

export const InventoryRoomStatisticsResponseSchema = {
  'room': {
    name: 'room',
    required: true,
    nullable: false,
      type: 'embedded',
  },
  'itemCount': {
    name: 'itemCount',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'sumPrice': {
    name: 'sumPrice',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
}
export function instanceOfInventoryRoomStatisticsResponse(value: object): value is InventoryRoomStatisticsResponse {
let isInstance = true;
    isInstance = isInstance && "room" in value;
    isInstance = isInstance && "itemCount" in value;
    isInstance = isInstance && "sumPrice" in value;

return isInstance;
}

export function InventoryRoomStatisticsResponseFromJSON(json: any): InventoryRoomStatisticsResponse {
return InventoryRoomStatisticsResponseFromJSONTyped(json, false);
}

export function InventoryRoomStatisticsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryRoomStatisticsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'room': MapRoomResponseFromJSON(json['room']),
          'itemCount': json['itemCount'],
          'sumPrice': json['sumPrice'],
    };
  }

  export function InventoryRoomStatisticsResponseToJSON(value?: InventoryRoomStatisticsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'room': MapRoomResponseToJSON(value.room),
                'itemCount': value.itemCount,
                'sumPrice': value.sumPrice,
    };
  }

  export function InventoryRoomStatisticsResponseCreateEmpty(): InventoryRoomStatisticsResponse {
  return {
          'room': MapRoomResponseCreateEmpty(),
      'itemCount': 0,
      'sumPrice': 0,
  };
  }

