/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseAuthschEntitlementStatus } from './BaseAuthschEntitlementStatus';
    import {
    BaseAuthschEntitlementStatusFromJSON,
    BaseAuthschEntitlementStatusFromJSONTyped,
    BaseAuthschEntitlementStatusToJSON,
    BaseAuthschEntitlementStatusCreateEmpty,
    } from './BaseAuthschEntitlementStatus';
import {DateTime} from '@Utils/DateTime';
import { BaseAuthschEntitlementStatusValues } from './BaseAuthschEntitlementStatus';

export interface AuthAuthschEntitlement {
  id: number;
  name: string;
  status: BaseAuthschEntitlementStatus;
  title: Array<string>;
  start: DateTime | null;
  end: DateTime | null;
}

export const AuthAuthschEntitlementSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: 'Kör azonosítója',
    type: 'number',
      minimum: 1,
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Kör neve',
    type: 'string',
      minLength: 1,
      maxLength: 250,
  },
  'status': {
    name: 'status',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Körtagság státusza",
        enumValues: BaseAuthschEntitlementStatusValues,
  },
  'title': {
    name: 'title',
    required: true,
    nullable: false,
    label: 'Kör pozíciók',
    type: 'array',
  },
  'start': {
    name: 'start',
    required: true,
    nullable: true,
    label: 'Tagság kezdete',
    type: 'date',
  },
  'end': {
    name: 'end',
    required: true,
    nullable: true,
    label: 'Tagság vége',
    type: 'date',
  },
}
export function instanceOfAuthAuthschEntitlement(value: object): value is AuthAuthschEntitlement {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "end" in value;

return isInstance;
}

export function AuthAuthschEntitlementFromJSON(json: any): AuthAuthschEntitlement {
return AuthAuthschEntitlementFromJSONTyped(json, false);
}

export function AuthAuthschEntitlementFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthAuthschEntitlement {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
              'status': BaseAuthschEntitlementStatusFromJSON(json['status']),
          'title': json['title'],
        'start': (json['start'] === null ? null : DateTime.fromISO(json['start'])),
        'end': (json['end'] === null ? null : DateTime.fromISO(json['end'])),
    };
  }

  export function AuthAuthschEntitlementToJSON(value?: AuthAuthschEntitlement | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'status': BaseAuthschEntitlementStatusToJSON(value.status),
            'title': value.title,
            'start': (
              value.start === null ? null : value.start.toISODate()),
            'end': (
              value.end === null ? null : value.end.toISODate()),
    };
  }

  export function AuthAuthschEntitlementCreateEmpty(): AuthAuthschEntitlement {
  return {
      'id': 0,
      'name': '',
          'status': BaseAuthschEntitlementStatusCreateEmpty(),
      'title': [],
      'start': DateTime.now(),
      'end': DateTime.now(),
  };
  }

