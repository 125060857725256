import {Base} from "@Models/Base";
import {User} from "@Models/base/User";
import Event from "@Models/events/Event";
import {Tender} from "@Models/viko/Tender";
import {Manager} from "@Models/events/Manager";
import {Claim} from "@Models/rvt/requests/Claim";
import {Amend} from "@Models/rvt/requests/Amend";
import {AuthschAccount} from "@Models/base/AuthschAccount";
import {Application} from "@Models/viko/Application";
import {TrainingParticipation} from "@Models/events/TrainingParticipation";
import type {BaseUserSummaryResponse} from "@/api/api";
import {MainApi} from "@/api/api";

export class UserSummary extends Base {
    user: User;

    authsch: AuthschAccount;

    events: Record<number, Event>;

    eventManagers: Record<number, Manager>;

    trainingParticipations: Record<number, TrainingParticipation>;

    vikoApplications: Record<number, Application>;

    vikoReviewerGroups: Record<number, Tender>;

    vikoModerators: Record<number, Tender>;

    claims: Record<number, Claim>;

    amends: Record<number, Amend>;

    static getById(id: string): Promise<UserSummary> {
        return MainApi.usersSummary(id).then(response => {
            return UserSummary.newSingle(response.data, UserSummary.parseResponse);
        });
    }

    static parseResponse(summary: UserSummary, data: BaseUserSummaryResponse): UserSummary {
        summary.user = User.newSingle(data.user, User.parseExtendedResponse);
        summary.authsch = AuthschAccount.newSingle(data.authsch, AuthschAccount.parseResponse);
        summary.events = Event.newRecords(data.events, Event.parseExtendedResponse);
        summary.eventManagers = Manager.newRecords(data.eventManagers, Manager.parseResponse);
        summary.trainingParticipations = TrainingParticipation.newRecords(data.trainingParticipations, TrainingParticipation.parseResponse);
        summary.vikoApplications = Application.newRecords(data.vikoApplications, Application.parseResponse);
        summary.vikoReviewerGroups = Tender.newRecords(data.vikoReviewerGroups, Tender.parseResponse);
        summary.vikoModerators = Tender.newRecords(data.vikoModerators, Tender.parseResponse);
        summary.claims = Claim.newRecords(data.claims, Claim.parseResponse);
        summary.amends = Amend.newRecords(data.amends, Amend.parseResponse);

        return summary;
    }
}
