<template>
    <PegasusHeading>Felhasználó adatlap</PegasusHeading>

    <div class="row mb-3">
        <div class="col-lg-12">
            <h2>
                <PegasusButton variant="danger" @click="impersonate">
                    Megszemélyesítés
                </PegasusButton>

                <PegasusButton variant="success" @click="getDebug">
                    Authsch debug
                </PegasusButton>

                <PegasusButton variant="success" @click="refresh">
                    Authsch refresh
                </PegasusButton>
            </h2>
        </div>
    </div>

    <TabView content-class="p-1">
        <TabPanel header="Adatok">
            <div class="row">
                <div class="col-md-6">
                    <PegasusPanel class="mb-3" header="Általános adatok">
                        <dl>
                            <dt>Név:</dt>
                            <dd>{{ summary.user.name }}</dd>
                            <dt>ID</dt>
                            <dd>{{ summary.user.id }}</dd>
                            <dt>E-mail cím:</dt>
                            <dd>{{ summary.user.email }}</dd>
                            <dt>Jogosultságok:</dt>
                            <dd>{{ summary.user.permissions?.join(', ') }}</dd>
                            <dt>Módosítva:</dt>
                            <dd>{{ summary.user.updatedAt }}</dd>
                            <dt>Létrehozva:</dt>
                            <dd>{{ summary.user.createdAt }}</dd>
                        </dl>
                    </PegasusPanel>
                </div>
                <div class="col-md-6">
                    <PegasusPanel header="Schönherz Account">
                        <dl>
                            <template v-for="field in summary.authsch.getFields()" :key="field.key">
                                <dt>{{ field.key }}</dt>
                                <dd>{{ field.value }}</dd>
                            </template>
                        </dl>
                    </PegasusPanel>
                </div>
            </div>
        </TabPanel>

        <TabPanel header="Manuális jogosultságok">
            <div class="row">
                <div class="col-lg-3">
                    <div v-for="(text, permission) in BaseGlobalPermissionValues" :key="permission">
                        <div
                            v-if="!assignedGlobalPermissions.includes(permission)"
                            class="cursor-pointer mb-1"
                            @click="addGlobalPermission(permission)"
                        >
                            {{ permission }}
                        </div>
                    </div>
                </div>

                <div class="col-lg-3"></div>

                <div class="col-lg-6">
                    <ul>
                        <li v-for="permission in assignedGlobalPermissions" :key="permission" class="mb-1">
                            {{ permission }}
                            <span class="cursor-pointer" @click="removeGlobalPermission(permission)">
                                <i class="fas fa-times"></i>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </TabPanel>

        <TabPanel header="Körtagságok">
            <PegasusTable :data="summary.user.memberships">
                <Column field="group.name" header="Kör"></Column>
                <Column field="postsList" header="Posztok"></Column>
            </PegasusTable>
        </TabPanel>

        <TabPanel header="Rendezvények">
            <h3 class="m-2 text-center">Rendezvények</h3>

            <PegasusTable :data="summary.events" :per-page="10" search>
                <Column :field="item => '#' + item.id" header="ID"></Column>
                <Column field="name" header="Név"></Column>
                <Column field="datetime" header="Időpont"></Column>
                <Column field="displayLocations" header="Helyszín"></Column>
                <Column field="status" header="Állapot"></Column>
                <Column header="Műveletek">
                    <template #body="{data}">
                        <PegasusButton :params="{event: data}" to="events.edit" variant="primary">
                            Megnyitás
                        </PegasusButton>
                    </template>
                </Column>
            </PegasusTable>

            <h3 class="m-2 text-center">Főrendezői tanfolyam részvételek</h3>

            <PegasusTable :data="summary.trainingParticipations">
                <Column :field="(item: TrainingParticipation) => item.training.date.datetime()" header="Dátum"></Column>
                <Column field="location.longName" header="Helyszín"></Column>
                <Column field="status" header="Állapot"></Column>
            </PegasusTable>
        </TabPanel>

        <TabPanel v-if="!isEmpty(summary.eventManagers)" header="Hetifőnökség">

        </TabPanel>

        <TabPanel header="VIKÖ">
            <h3 class="m-2 text-center">Leadott pályázatok</h3>

            <PegasusTable :data="summary.vikoApplications" :per-page="10">
                <Column field="tender.displayName" header="Pályázat"></Column>
                <Column field="reportCount" header="Beszámolók száma"></Column>
                <Column :field="(item: Application) => item.resultText" header="Eredmény"></Column>
                <Column header="Műveletek">
                    <template #body="{data}">
                        <PegasusButton :params="{application: data.id}" to="viko.application" variant="primary">
                            Megnyitás
                        </PegasusButton>
                    </template>
                </Column>
            </PegasusTable>

            <div class="row">
                <div class="col-md-6">
                    <h3 class="m-2 text-center">Bírálási részvétel</h3>

                    <PegasusTable :data="summary.vikoReviewerGroups">
                        <Column field="displayName" header="Pályázat"></Column>
                        <Column
                            :field="item => item.findReviewerGroupForUser(summary.user)?.name"
                            header="Pályázat"
                        ></Column>
                    </PegasusTable>
                </div>

                <div class="col-md-6">
                    <h3 class="m-2 text-center">Bíráláson moderálás</h3>

                    <PegasusTable :data="summary.vikoModerators">
                        <Column field="displayName" header="Pályázat"></Column>
                    </PegasusTable>
                </div>
            </div>
        </TabPanel>

        <TabPanel header="Kérvények">
            <h3 class="m-2 text-center">RVT támogatási kérvények</h3>

            <PegasusTable :data="summary.claims" :per-page="10">
                <Column :field="item => item.createdAt.datetime()" header="Létrehozás"></Column>
                <Column field="group.name" header="Kör"></Column>
                <Column :field="item => item.sum.huf()" header="Összeg"></Column>
                <Column field="status" header="Állapot"></Column>
            </PegasusTable>

            <h3 class="m-2 text-center">KPR módosítási kérvények</h3>

            <PegasusTable :data="summary.amends" :per-page="10">
                <Column :field="item => item.createdAt.datetime()" header="Létrehozás"></Column>
                <Column field="group.name" header="Kör"></Column>
                <Column :field="item => item.balance.huf()" header="Összeg"></Column>
                <Column field="status" header="Állapot"></Column>
            </PegasusTable>
        </TabPanel>
    </TabView>
</template>

<title>Felhasználó adatlap</title>

<script lang="ts" setup>
import {UserSummary} from '@Models/base/UserSummary';
import {clone, isEmpty} from 'lodash-es';
import {redirect} from '@/router';
import toast from '@Utils/toast';
import Toast from '@Utils/toast';
import Column from 'primevue/column';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import {useAuthStore} from '@/stores/auth';
import type {Application} from '@Models/viko/Application';
import type {TrainingParticipation} from '@Models/events/TrainingParticipation';
import {Dialog} from '@Utils/dialog';
import UserAuthschDebugDialog from '@Components/rvt/admin/UserAuthschDebugDialog.vue';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {MainApi} from '@/api/apis/index';
import {BaseGlobalPermission, BaseGlobalPermissionValues} from '@/api/models/index';
import {ref} from 'vue';

const {userId} = defineProps<{
    userId: string
}>();

const authStore = useAuthStore();
authStore.requireUser();

const summary = await UserSummary.getById(userId);
const {data: permissionsData} = await MainApi.globalPermissionsByUser(userId);

const assignedGlobalPermissions = ref(permissionsData.assignedGlobalPermissions);

function removeGlobalPermission(permission: BaseGlobalPermission) {
    const newPermissions = clone(assignedGlobalPermissions.value);

    newPermissions.splice(
        newPermissions.indexOf(permission),
        1,
    );

    const request = MainApi.globalPermissionsUpdate(userId, {
        globalPermissions: newPermissions,
    }).then(({data}) => {
        assignedGlobalPermissions.value = data.assignedGlobalPermissions;
    });

    Toast.loading(request);
}

function addGlobalPermission(permission: BaseGlobalPermission) {
    const newPermissions = clone(assignedGlobalPermissions.value);
    newPermissions.push(permission);

    const request = MainApi.globalPermissionsUpdate(userId, {
        globalPermissions: newPermissions,
    }).then(({data}) => {
        assignedGlobalPermissions.value = data.assignedGlobalPermissions;
    });

    Toast.loading(request);
}

function impersonate() {
    let request = authStore.impersonate(summary.user).then(() => {
        redirect({
            name: 'rvt.index',
        });
    });

    toast.loading(request, 'Megszemélyesítés');
}

function refresh() {
    const request = summary.user.refresh();

    toast.loading(request, 'Frissítés');
}

function getDebug() {
    const request = summary.user.getDebugData().then(debugData => {
        Dialog.open(UserAuthschDebugDialog, {debug: debugData});
    });
    toast.loading(request, 'Lekérés');
}
</script>
