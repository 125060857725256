<template>
    <PegasusModal :header="report.canEdit ? 'Beszámoló szerkesztése' : 'Beszámoló megtekintése'" width="800px">
        <div class="row">
            <div class="col-lg-6">
                <dl>
                    <dt>Kör</dt>
                    <dd>{{ report.group.name }}</dd>
                    <dt>Karakterszám</dt>
                    <dd>{{ charCount }} / {{ charLimit }}</dd>
                </dl>
            </div>

            <div class="col-lg-6">
                <dl>
                    <dt>Állapot</dt>
                    <dd v-if="report.accepted === null">Előbírálásra vár</dd>
                    <dd v-if="report.accepted === true">Jóváhagyva</dd>
                    <dd v-if="report.accepted === false">Elutasítva</dd>

                    <template v-if="report.leaderComment">
                        <dt>Körvezetői visszajelzés</dt>
                        <dd>{{ report.leaderComment }}</dd>
                    </template>
                </dl>
            </div>
        </div>

        <PegasusPanel v-if="report.canEdit">
            <p>Megengedett speciális karakterek: ' " ? : ! . & ( ) , -</p>
            <TextareaField
                v-model="report.text"
                :disabled="!report.canEdit"
                :schema="VikoReportUpdateSchema.text"
                without-group
            ></TextareaField>
        </PegasusPanel>

        <p v-else style="white-space: pre-wrap">{{ report.text }}</p>

        <PegasusButton v-if="report.canEdit" variant="success" @click="save">
            <i class="fas fa-save"></i> Mentés
        </PegasusButton>

        <PegasusForm v-if="canDecide" ref="decideForm" class="mt-2">
            <h4>Döntés</h4>
            <TextareaField
                v-model="comment"
                :schema="VikoReportDecideSchema.comment"
            ></TextareaField>

            <PegasusButton variant="success" @click="decide(true)">
                <i class="fa fa-gavel"></i> Jóváhagyás
            </PegasusButton>

            <PegasusButton variant="danger" @click="decide(false)">
                <i class="fa fa-gavel"></i> Elutasítás
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import {Report} from "@Models/viko/Report";
import {computed, ref} from "vue";
import {useConstantsStore} from "@/stores/constants";
import TextareaField from "@Components/base/form/types/TextareaField.vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import PegasusModal from "@Components/base/PegasusModal.vue";
import {BaseGroupSpecificPermission, VikoReportDecideSchema, VikoReportUpdateSchema} from '@/api/models';
import toast from '@Utils/toast';
import {useAuthStore} from '@/stores/auth';
import {useReturn} from '@Utils/dialog';
import PegasusForm from "@Components/base/form/PegasusForm.vue";
import type {FormRef} from "@/vue";

const {report, admin = false} = defineProps<{
    report: Report,
    admin?: boolean
}>();

const charLimit = useConstantsStore().constants.viko.charLimit;

const charCount = computed(() => {
    return report?.text.length ?? 0;
});

const user = useAuthStore().requireUser();

const canDecide = computed(() => user.value.hasGroupPermission(report.group, BaseGroupSpecificPermission.MANAGE_VIKO) || admin);

const comment = ref('');

const decideForm = ref<FormRef>(null);

const returnValue = useReturn();

function save() {
    const request = report.update();

    toast.loading(request);
}

function decide(decision: boolean) {
    const request = report.decide(decision, comment.value, decideForm.value).then(() => {
        returnValue(decision);
    });

    toast.loading(request);
}
</script>
