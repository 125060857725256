/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyBudgetLocation } from './MoneyBudgetLocation';
    import {
    MoneyBudgetLocationFromJSON,
    MoneyBudgetLocationFromJSONTyped,
    MoneyBudgetLocationToJSON,
    MoneyBudgetLocationCreateEmpty,
    } from './MoneyBudgetLocation';
import {DateTime} from '@Utils/DateTime';
import { MoneyBudgetLocationValues } from './MoneyBudgetLocation';

export interface MoneySpendingItemUpdate {
  name: string;
  location: MoneyBudgetLocation;
  money: number;
}

export const MoneySpendingItemUpdateSchema = {
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: 'Név',
    type: 'string',
      minLength: 3,
      maxLength: 100,
  },
  'location': {
    name: 'location',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Gazdasági láb",
        enumValues: MoneyBudgetLocationValues,
  },
  'money': {
    name: 'money',
    required: true,
    nullable: false,
    label: 'Összeg',
    type: 'number',
      minimum: -10000000,
      maximum: 10000000,
  },
}
export function instanceOfMoneySpendingItemUpdate(value: object): value is MoneySpendingItemUpdate {
let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "money" in value;

return isInstance;
}

export function MoneySpendingItemUpdateFromJSON(json: any): MoneySpendingItemUpdate {
return MoneySpendingItemUpdateFromJSONTyped(json, false);
}

export function MoneySpendingItemUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneySpendingItemUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'name': json['name'],
              'location': MoneyBudgetLocationFromJSON(json['location']),
          'money': json['money'],
    };
  }

  export function MoneySpendingItemUpdateToJSON(value?: MoneySpendingItemUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'name': value.name,
                'location': MoneyBudgetLocationToJSON(value.location),
                'money': value.money,
    };
  }

  export function MoneySpendingItemUpdateCreateEmpty(): MoneySpendingItemUpdate {
  return {
      'name': '',
          'location': MoneyBudgetLocationCreateEmpty(),
      'money': 0,
  };
  }

