<template>
    <div v-if="block">
        <PegasusOverlay v-if="popover" :content="popover" simple>
            <a :class="classObject" :href="href" role="button" @click="clickHandler">
                <slot></slot>
            </a>
        </PegasusOverlay>

        <a v-else :class="classObject" :href="href" role="button" @click="clickHandler">
            <slot></slot>
        </a>
    </div>

    <PegasusOverlay v-else-if="popover" :content="popover" simple>
        <a :class="classObject" :href="href" role="button" @click="clickHandler">
            <slot></slot>
        </a>
    </PegasusOverlay>

    <a v-else :class="classObject" :href="href" role="button" @click="clickHandler">
        <slot></slot>
    </a>
</template>

<script lang="ts" setup>
import type {RouteParams, Variants} from '@/vue';
import {routeHashNormalise, routeParamsNormalise} from '@Models/Utils';
import {calculateHref, redirect} from '@/router';
import PegasusOverlay from '@Components/base/PegasusOverlay.vue';
import {computed} from 'vue';
import type {RouteParamsGeneric} from 'vue-router';

const {variant = 'primary', size, to, params, hash, query, block = false, popover, disabled = false} = defineProps<{
    variant?: Variants,
    size?: 'xs' | 'sm' | 'lg',
    to?: string,
    params?: RouteParams,
    hash?: RouteParams,
    query?: Record<string, string>
    block?: boolean,
    popover?: string,
    disabled?: boolean
}>();

const emit = defineEmits<{
    click: []
}>();

const href = computed(() => {
    if (disabled) {
        return undefined;
    }

    if (to) {
        return calculateHref({
            name: to,
            params: params as RouteParamsGeneric, // TODO fix this
            query: query,
            hash: hash ? routeHashNormalise(hash) : '',
        });
    }

    return 'javascript:void(0)';
});

const classObject = computed(() => {
    let result = 'm-1 text-nowrap p-button p-component p-button-' + variant;

    if (size) {
        result += ' p-button-' + size;
    }

    if (block) {
        result += ' d-inline-block';
    }

    if (disabled) {
        result += ' p-disabled';
    }

    return result;
});

function clickHandler(event: MouseEvent) {
    if (event.ctrlKey) {
        return;
    }

    if (disabled) {
        return;
    }

    event.preventDefault();

    emit('click');

    if (to) {
        redirect({
            name: to,
            params: routeParamsNormalise(params ?? {}),
            query: query,
            hash: hash ? routeHashNormalise(hash) : undefined,
        });
    }
}
</script>
