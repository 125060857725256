import {Base} from '@Models/Base';
import {ItemGroup} from '@Models/inventory/ItemGroup';
import {Group} from '@Models/base/Group';
import {type BaseGroupSummaryInventoryResponse, InventoryApi} from '@/api/api';
import {every, filter, flatMapDeep, keyBy, map, toArray} from 'lodash-es';
import {useConstantsStore} from '@/stores/constants';
import type {Variants} from '@/vue';
import type {TagCount} from '@Models/inventory/InventoryGroupStatistics';

export class InventoryGroupSummary extends Base {
    group: Group;

    itemsWithoutGroup: number;

    tagCounts: Record<number, TagCount>;

    rootGroup: ItemGroup;

    static parseResponse(summary: InventoryGroupSummary, response: BaseGroupSummaryInventoryResponse): InventoryGroupSummary {
        summary.group = Group.getBySingleId(response.groupId);

        const constants = useConstantsStore().constants;

        const tagCounts = map(response.tagCounts, tagCount => {
            return {
                tag: constants.inventory.tags[tagCount.countedById],
                itemCount: tagCount.itemCount,
                priceSum: tagCount.sumPrice,
            };
        });

        summary.tagCounts = keyBy(tagCounts, tagCount => tagCount.tag.id);
        summary.itemsWithoutGroup = response.itemsWithoutGroup;


        summary.rootGroup = ItemGroup.createDummyRoot(
            summary.group,
            ItemGroup.newRecords(response.itemGroups, ItemGroup.parseHierarchyResponse),
            summary.itemsWithoutGroup,
        );

        return summary;
    }

    static getByGroup(groupId: number): Promise<InventoryGroupSummary> {
        return InventoryApi.groupsSummary(groupId).then(response => {
            return InventoryGroupSummary.newSingle(response.data, this.parseResponse);
        });
    }

    public allItemGroups(): Record<number, ItemGroup> {
        return keyBy(
            flatMapDeep(
                this.rootGroup.children,
                itemGroup => toArray(itemGroup.children).concat([itemGroup]),
            ),
            itemGroup => itemGroup.id,
        );
    }

    public filterTagCounts(severity: Variants): TagCount[] {
        return filter(this.tagCounts, tagCount => tagCount.tag.severity === severity);
    }

    get noWarnings(): boolean {
        return every(this.tagCounts, tagCount => tagCount.tag.severity !== 'danger' && tagCount.tag.severity !== 'warning');
    }
}
