/* tslint:disable */
/* eslint-disable */

import {
    type EventsAcData,
    EventsAcDataFromJSONTyped,
    EventsAcDataToJSON,
    EventsAcDataCreateEmpty,
    instanceOfEventsAcData,
} from './EventsAcData';
import {
    type EventsBarData,
    EventsBarDataFromJSONTyped,
    EventsBarDataToJSON,
    EventsBarDataCreateEmpty,
    instanceOfEventsBarData,
} from './EventsBarData';
import {
    type EventsBssData,
    EventsBssDataFromJSONTyped,
    EventsBssDataToJSON,
    EventsBssDataCreateEmpty,
    instanceOfEventsBssData,
} from './EventsBssData';
import {
    type EventsCalendarEventData,
    EventsCalendarEventDataFromJSONTyped,
    EventsCalendarEventDataToJSON,
    EventsCalendarEventDataCreateEmpty,
    instanceOfEventsCalendarEventData,
} from './EventsCalendarEventData';
import {
    type EventsCarPermitData,
    EventsCarPermitDataFromJSONTyped,
    EventsCarPermitDataToJSON,
    EventsCarPermitDataCreateEmpty,
    instanceOfEventsCarPermitData,
} from './EventsCarPermitData';
import {
    type EventsElectricPermitData,
    EventsElectricPermitDataFromJSONTyped,
    EventsElectricPermitDataToJSON,
    EventsElectricPermitDataCreateEmpty,
    instanceOfEventsElectricPermitData,
} from './EventsElectricPermitData';
import {
    type EventsElevatorPermitData,
    EventsElevatorPermitDataFromJSONTyped,
    EventsElevatorPermitDataToJSON,
    EventsElevatorPermitDataCreateEmpty,
    instanceOfEventsElevatorPermitData,
} from './EventsElevatorPermitData';
import {
    type EventsEntrancePermitData,
    EventsEntrancePermitDataFromJSONTyped,
    EventsEntrancePermitDataToJSON,
    EventsEntrancePermitDataCreateEmpty,
    instanceOfEventsEntrancePermitData,
} from './EventsEntrancePermitData';
import {
    type EventsExtraTableData,
    EventsExtraTableDataFromJSONTyped,
    EventsExtraTableDataToJSON,
    EventsExtraTableDataCreateEmpty,
    instanceOfEventsExtraTableData,
} from './EventsExtraTableData';
import {
    type EventsGuestListData,
    EventsGuestListDataFromJSONTyped,
    EventsGuestListDataToJSON,
    EventsGuestListDataCreateEmpty,
    instanceOfEventsGuestListData,
} from './EventsGuestListData';
import {
    type EventsHeatingPermitData,
    EventsHeatingPermitDataFromJSONTyped,
    EventsHeatingPermitDataToJSON,
    EventsHeatingPermitDataCreateEmpty,
    instanceOfEventsHeatingPermitData,
} from './EventsHeatingPermitData';
import {
    type EventsKitchenReservationData,
    EventsKitchenReservationDataFromJSONTyped,
    EventsKitchenReservationDataToJSON,
    EventsKitchenReservationDataCreateEmpty,
    instanceOfEventsKitchenReservationData,
} from './EventsKitchenReservationData';
import {
    type EventsSpotData,
    EventsSpotDataFromJSONTyped,
    EventsSpotDataToJSON,
    EventsSpotDataCreateEmpty,
    instanceOfEventsSpotData,
} from './EventsSpotData';
import {
    type EventsTableSetData,
    EventsTableSetDataFromJSONTyped,
    EventsTableSetDataToJSON,
    EventsTableSetDataCreateEmpty,
    instanceOfEventsTableSetData,
} from './EventsTableSetData';
import {
    type EventsWorkerListData,
    EventsWorkerListDataFromJSONTyped,
    EventsWorkerListDataToJSON,
    EventsWorkerListDataCreateEmpty,
    instanceOfEventsWorkerListData,
} from './EventsWorkerListData';

export type EventsServiceResponseData = { serviceType: 'ac' } & EventsAcData | { serviceType: 'bar' } & EventsBarData | { serviceType: 'bss' } & EventsBssData | { serviceType: 'calendarevent' } & EventsCalendarEventData | { serviceType: 'carpermit' } & EventsCarPermitData | { serviceType: 'electricpermit' } & EventsElectricPermitData | { serviceType: 'elevatorpermit' } & EventsElevatorPermitData | { serviceType: 'entrancepermit' } & EventsEntrancePermitData | { serviceType: 'extratable' } & EventsExtraTableData | { serviceType: 'guestlist' } & EventsGuestListData | { serviceType: 'heatingpermit' } & EventsHeatingPermitData | { serviceType: 'kitchenreservation' } & EventsKitchenReservationData | { serviceType: 'spot' } & EventsSpotData | { serviceType: 'tableset' } & EventsTableSetData | { serviceType: 'workerlist' } & EventsWorkerListData;
export type EventsServiceResponseDataSimple = EventsAcData | EventsBarData | EventsBssData | EventsCalendarEventData | EventsCarPermitData | EventsElectricPermitData | EventsElevatorPermitData | EventsEntrancePermitData | EventsExtraTableData | EventsGuestListData | EventsHeatingPermitData | EventsKitchenReservationData | EventsSpotData | EventsTableSetData | EventsWorkerListData;



export function EventsServiceResponseDataFromJSON(json: any): EventsServiceResponseData {
    return EventsServiceResponseDataFromJSONTyped(json, false);
}

export function EventsServiceResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsServiceResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['serviceType']) {
        case 'ac':
            return {...EventsAcDataFromJSONTyped(json, true), serviceType: 'ac'};
        case 'bar':
            return {...EventsBarDataFromJSONTyped(json, true), serviceType: 'bar'};
        case 'bss':
            return {...EventsBssDataFromJSONTyped(json, true), serviceType: 'bss'};
        case 'calendarevent':
            return {...EventsCalendarEventDataFromJSONTyped(json, true), serviceType: 'calendarevent'};
        case 'carpermit':
            return {...EventsCarPermitDataFromJSONTyped(json, true), serviceType: 'carpermit'};
        case 'electricpermit':
            return {...EventsElectricPermitDataFromJSONTyped(json, true), serviceType: 'electricpermit'};
        case 'elevatorpermit':
            return {...EventsElevatorPermitDataFromJSONTyped(json, true), serviceType: 'elevatorpermit'};
        case 'entrancepermit':
            return {...EventsEntrancePermitDataFromJSONTyped(json, true), serviceType: 'entrancepermit'};
        case 'extratable':
            return {...EventsExtraTableDataFromJSONTyped(json, true), serviceType: 'extratable'};
        case 'guestlist':
            return {...EventsGuestListDataFromJSONTyped(json, true), serviceType: 'guestlist'};
        case 'heatingpermit':
            return {...EventsHeatingPermitDataFromJSONTyped(json, true), serviceType: 'heatingpermit'};
        case 'kitchenreservation':
            return {...EventsKitchenReservationDataFromJSONTyped(json, true), serviceType: 'kitchenreservation'};
        case 'spot':
            return {...EventsSpotDataFromJSONTyped(json, true), serviceType: 'spot'};
        case 'tableset':
            return {...EventsTableSetDataFromJSONTyped(json, true), serviceType: 'tableset'};
        case 'workerlist':
            return {...EventsWorkerListDataFromJSONTyped(json, true), serviceType: 'workerlist'};
        default:
            throw new Error(`No variant of EventsServiceResponseData exists with 'serviceType=${json['serviceType']}'`);
    }
}

export function EventsServiceResponseDataToJSON(value?: EventsServiceResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['serviceType']) {
        case 'ac':
            return EventsAcDataToJSON(value);
        case 'bar':
            return EventsBarDataToJSON(value);
        case 'bss':
            return EventsBssDataToJSON(value);
        case 'calendarevent':
            return EventsCalendarEventDataToJSON(value);
        case 'carpermit':
            return EventsCarPermitDataToJSON(value);
        case 'electricpermit':
            return EventsElectricPermitDataToJSON(value);
        case 'elevatorpermit':
            return EventsElevatorPermitDataToJSON(value);
        case 'entrancepermit':
            return EventsEntrancePermitDataToJSON(value);
        case 'extratable':
            return EventsExtraTableDataToJSON(value);
        case 'guestlist':
            return EventsGuestListDataToJSON(value);
        case 'heatingpermit':
            return EventsHeatingPermitDataToJSON(value);
        case 'kitchenreservation':
            return EventsKitchenReservationDataToJSON(value);
        case 'spot':
            return EventsSpotDataToJSON(value);
        case 'tableset':
            return EventsTableSetDataToJSON(value);
        case 'workerlist':
            return EventsWorkerListDataToJSON(value);
        default:
            throw new Error(`No variant of EventsServiceResponseData exists with 'serviceType=${value['serviceType']}'`);
    }

}
export function EventsServiceResponseDataCreateEmpty(): EventsServiceResponseData {
    const ret: EventsServiceResponseData = EventsAcDataCreateEmpty() as EventsServiceResponseData;
    ret.serviceType = 'ac'
      return ret;
}
export function EventsServiceResponseDataCreateEmptyByType(type: string): EventsServiceResponseDataSimple {
  switch (type) {
    case 'ac':
      return EventsAcDataCreateEmpty();
    case 'bar':
      return EventsBarDataCreateEmpty();
    case 'bss':
      return EventsBssDataCreateEmpty();
    case 'calendarevent':
      return EventsCalendarEventDataCreateEmpty();
    case 'carpermit':
      return EventsCarPermitDataCreateEmpty();
    case 'electricpermit':
      return EventsElectricPermitDataCreateEmpty();
    case 'elevatorpermit':
      return EventsElevatorPermitDataCreateEmpty();
    case 'entrancepermit':
      return EventsEntrancePermitDataCreateEmpty();
    case 'extratable':
      return EventsExtraTableDataCreateEmpty();
    case 'guestlist':
      return EventsGuestListDataCreateEmpty();
    case 'heatingpermit':
      return EventsHeatingPermitDataCreateEmpty();
    case 'kitchenreservation':
      return EventsKitchenReservationDataCreateEmpty();
    case 'spot':
      return EventsSpotDataCreateEmpty();
    case 'tableset':
      return EventsTableSetDataCreateEmpty();
    case 'workerlist':
      return EventsWorkerListDataCreateEmpty();
    default:
      throw new Error(`No variant of EventsServiceResponseData exists with 'serviceType=${type}'`);
  }
}

