/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface EventsManagerStore {
  user: string;
  leader: boolean;
  active: boolean;
}

export const EventsManagerStoreSchema = {
  'user': {
    name: 'user',
    required: true,
    nullable: false,
    label: 'Felhasználó',
    type: 'string',
      minLength: 32,
      maxLength: 32,
  },
  'leader': {
    name: 'leader',
    required: true,
    nullable: false,
    label: 'Gárdavezető',
    type: 'boolean',
  },
  'active': {
    name: 'active',
    required: true,
    nullable: false,
    label: 'Aktív',
    type: 'boolean',
  },
}
export function instanceOfEventsManagerStore(value: object): value is EventsManagerStore {
let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "leader" in value;
    isInstance = isInstance && "active" in value;

return isInstance;
}

export function EventsManagerStoreFromJSON(json: any): EventsManagerStore {
return EventsManagerStoreFromJSONTyped(json, false);
}

export function EventsManagerStoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsManagerStore {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'user': json['user'],
          'leader': json['leader'],
          'active': json['active'],
    };
  }

  export function EventsManagerStoreToJSON(value?: EventsManagerStore | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'user': value.user,
                'leader': value.leader,
                'active': value.active,
    };
  }

  export function EventsManagerStoreCreateEmpty(): EventsManagerStore {
  return {
      'user': '',
      'leader': false,
      'active': false,
  };
  }

