/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface VikoReviewResponse {
  id: number;
  applicationId: number;
  userId: string;
  points: number;
  groupPoints: number;
  finalPoints: number;
  comment: string;
}

export const VikoReviewResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'applicationId': {
    name: 'applicationId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'userId': {
    name: 'userId',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'points': {
    name: 'points',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
      maximum: 5,
  },
  'groupPoints': {
    name: 'groupPoints',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
      maximum: 5,
  },
  'finalPoints': {
    name: 'finalPoints',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
      maximum: 5,
  },
  'comment': {
    name: 'comment',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
      maxLength: 1000,
  },
}
export function instanceOfVikoReviewResponse(value: object): value is VikoReviewResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "applicationId" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "points" in value;
    isInstance = isInstance && "groupPoints" in value;
    isInstance = isInstance && "finalPoints" in value;
    isInstance = isInstance && "comment" in value;

return isInstance;
}

export function VikoReviewResponseFromJSON(json: any): VikoReviewResponse {
return VikoReviewResponseFromJSONTyped(json, false);
}

export function VikoReviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VikoReviewResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'applicationId': json['applicationId'],
          'userId': json['userId'],
          'points': json['points'],
          'groupPoints': json['groupPoints'],
          'finalPoints': json['finalPoints'],
          'comment': json['comment'],
    };
  }

  export function VikoReviewResponseToJSON(value?: VikoReviewResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'applicationId': value.applicationId,
                'userId': value.userId,
                'points': value.points,
                'groupPoints': value.groupPoints,
                'finalPoints': value.finalPoints,
                'comment': value.comment,
    };
  }

  export function VikoReviewResponseCreateEmpty(): VikoReviewResponse {
  return {
      'id': 0,
      'applicationId': 0,
      'userId': '',
      'points': 0,
      'groupPoints': 0,
      'finalPoints': 0,
      'comment': '',
  };
  }

