/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { InventoryItemResponse } from './InventoryItemResponse';
    import {
    InventoryItemResponseFromJSON,
    InventoryItemResponseFromJSONTyped,
    InventoryItemResponseToJSON,
    InventoryItemResponseCreateEmpty,
    } from './InventoryItemResponse';
import {DateTime} from '@Utils/DateTime';

export interface InventoryItemPaginationResponse {
  data: Array<InventoryItemResponse>;
  total: number;
}

export const InventoryItemPaginationResponseSchema = {
  'data': {
    name: 'data',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'total': {
    name: 'total',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
  },
}
export function instanceOfInventoryItemPaginationResponse(value: object): value is InventoryItemPaginationResponse {
let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "total" in value;

return isInstance;
}

export function InventoryItemPaginationResponseFromJSON(json: any): InventoryItemPaginationResponse {
return InventoryItemPaginationResponseFromJSONTyped(json, false);
}

export function InventoryItemPaginationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryItemPaginationResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'data': ((json['data'] as Array<any>).map(InventoryItemResponseFromJSON)),
          'total': json['total'],
    };
  }

  export function InventoryItemPaginationResponseToJSON(value?: InventoryItemPaginationResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'data': ((value.data as Array<any>).map(InventoryItemResponseToJSON)),
                'total': value.total,
    };
  }

  export function InventoryItemPaginationResponseCreateEmpty(): InventoryItemPaginationResponse {
  return {
      'data': [],
      'total': 0,
  };
  }

