<template>
    <PegasusHeading>Házszintű költségvetés</PegasusHeading>

    <TabView>
        <TabPanel v-for="category in budget.categories" :key="category.id" :header="category.name" content-class="p-0">
            <table class="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Név</th>

                        <th v-for="location in MoneyBudgetLocation" :key="location">
                            {{ MoneyBudgetLocationValues[location] }}
                        </th>

                        <th>SUM</th>
                    </tr>
                </thead>

                <tbody class="table-group-divider">
                    <template v-for="subCategory in category.childrenInOrder()" :key="subCategory.id">
                        <tr class="table-primary" @click="categoryClick(subCategory, category)">
                            <td class="fw-bold">
                                <PegasusOverlay :content="subCategory.description" :header="subCategory.name">
                                    {{ category.prefix }}{{ subCategory.number }}
                                    {{ subCategory.name }}
                                </PegasusOverlay>
                            </td>

                            <td v-for="location in MoneyBudgetLocation" :key="location" class="text-nowrap">
                                {{ subCategory.valueSumByLocation(location).huf() }}
                            </td>

                            <td class="text-nowrap">{{ subCategory.sum().huf() }}</td>
                        </tr>

                        <tr v-for="item in subCategory.itemsInOrder()" :key="item.id">
                            <td>
                                <PegasusOverlay :content="item.description" :header="item.name">
                                    <PegasusLink :params="{item}" to="money.budget.item.show">
                                        {{ category.prefix }}{{ subCategory.number }}.{{ item.number }}
                                        {{ item.name }}
                                    </PegasusLink>
                                </PegasusOverlay>
                            </td>

                            <td v-for="location in MoneyBudgetLocation" :key="location" class="text-nowrap">
                                {{ locationValueText(item.valueByLocation(location)) }}
                            </td>

                            <td>{{ item.sum.huf() }}</td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <PegasusButton v-if="canEdit" variant="success" @click="newCategory(category)">
                <i class="fa fa-plus"></i> Új csoport
            </PegasusButton>
        </TabPanel>
    </TabView>
</template>

<title>Házszintű költségvetés</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {useAuthStore} from '@/stores/auth';
import {SpendingPeriod} from '@Models/money/SpendingPeriod';
import {NotFoundError} from '@Utils/errors/NotFoundError';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import PegasusOverlay from '@Components/base/PegasusOverlay.vue';
import {Dialog} from '@Utils/dialog';
import {ref} from 'vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {BudgetCategory} from '@Models/money/BudgetCategory';
import BudgetCategoryDialog from '@Components/money/budget/BudgetCategoryDialog.vue';
import {last} from 'lodash-es';
import toast from '@Utils/toast';
import type {BudgetItemValue} from '@Models/money/BudgetItemValue';
import PegasusLink from '@Components/base/PegasusLink.vue';
import {MoneyBudgetLocation, MoneyBudgetLocationValues} from '@/api/models/index';

const {year} = defineProps<{
    year: number,
}>();

useAuthStore().requireUser();

const period = SpendingPeriod.getByYear(year);

if (period === null) {
    throw new NotFoundError('Nincs ilyen év');
}

const budget = ref(await period.getBudget());

const canEdit = ref(true);

function locationValueText(value: BudgetItemValue | null): string {
    if (!value) {
        return '';
    }

    if (value.value === 0) {
        return '';
    }

    return value.value.huf();
}

function categoryClick(category: BudgetCategory, parent: BudgetCategory) {
    Dialog.open(BudgetCategoryDialog, {
        category,
        canEdit,
        onDelete: () => delete parent.children[category.id],
    });
}

function newCategory(parent: BudgetCategory) {
    if (!period) {
        throw new Error('Not possible');
    }

    const category = new BudgetCategory();
    category.name = 'Új kategória';
    category.description = '';
    category.number = (last(parent.childrenInOrder())?.number ?? 0) + 1;

    const request = category.store(period, parent.id).then(category => {
        parent.children[category.id] = category;
    });

    toast.loading(request, 'Létrehozás');
}
</script>
