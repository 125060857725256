/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { MoneyBudgetLocation } from './MoneyBudgetLocation';
    import {
    MoneyBudgetLocationFromJSON,
    MoneyBudgetLocationFromJSONTyped,
    MoneyBudgetLocationToJSON,
    MoneyBudgetLocationCreateEmpty,
    } from './MoneyBudgetLocation';
import {DateTime} from '@Utils/DateTime';
import { MoneyBudgetLocationValues } from './MoneyBudgetLocation';

export interface MoneyBudgetItemValueResponse {
  location: MoneyBudgetLocation;
  value: number;
}

export const MoneyBudgetItemValueResponseSchema = {
  'location': {
    name: 'location',
    required: true,
    nullable: false,
      type: 'embedded',
        label: "Gazdasági láb",
        enumValues: MoneyBudgetLocationValues,
  },
  'value': {
    name: 'value',
    required: true,
    nullable: false,
    label: 'Érték',
    type: 'number',
  },
}
export function instanceOfMoneyBudgetItemValueResponse(value: object): value is MoneyBudgetItemValueResponse {
let isInstance = true;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "value" in value;

return isInstance;
}

export function MoneyBudgetItemValueResponseFromJSON(json: any): MoneyBudgetItemValueResponse {
return MoneyBudgetItemValueResponseFromJSONTyped(json, false);
}

export function MoneyBudgetItemValueResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetItemValueResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
              'location': MoneyBudgetLocationFromJSON(json['location']),
          'value': json['value'],
    };
  }

  export function MoneyBudgetItemValueResponseToJSON(value?: MoneyBudgetItemValueResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'location': MoneyBudgetLocationToJSON(value.location),
                'value': value.value,
    };
  }

  export function MoneyBudgetItemValueResponseCreateEmpty(): MoneyBudgetItemValueResponse {
  return {
          'location': MoneyBudgetLocationCreateEmpty(),
      'value': 0,
  };
  }

