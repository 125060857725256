<template>
    <PegasusHeading>{{ group.name }} - leltár statisztikák</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PanelByLocation
                :filter="{group: groupId}"
                :room-counts="statistics.roomStatistics"
            ></PanelByLocation>
        </div>

        <div class="col-lg-6">
            <PanelByTags
                :filter="{group: groupId}"
                :tag-counts="statistics.tagCounts"
            ></PanelByTags>

            <PanelByOwner
                :filter="{group: groupId}"
                :owner-counts="statistics.ownerCounts"
                class="mt-2"
            ></PanelByOwner>
        </div>
    </div>
</template>

<title>Leltár kör adminisztráció</title>

<script lang="ts" setup>
import {Group} from '@Models/base/Group';
import {useAuthStore} from '@/stores/auth';
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {InventoryGroupStatistics} from '@Models/inventory/InventoryGroupStatistics';
import PanelByTags from '@Components/inventory/statistics/PanelByTags.vue';
import PanelByLocation from '@Components/inventory/statistics/PanelByLocation.vue';
import PanelByOwner from '@Components/inventory/statistics/PanelByOwner.vue';

const {groupId} = defineProps<{
    groupId: number
}>();

useAuthStore().requireUser();
const group = Group.getBySingleId(groupId);

const statistics = await InventoryGroupStatistics.getByGroup(groupId);
</script>
