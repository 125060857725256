/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseGlobalPermission } from './BaseGlobalPermission';
    import {
    BaseGlobalPermissionFromJSON,
    BaseGlobalPermissionFromJSONTyped,
    BaseGlobalPermissionToJSON,
    BaseGlobalPermissionCreateEmpty,
    } from './BaseGlobalPermission';
import {DateTime} from '@Utils/DateTime';

export interface BaseGlobalPermissionUpdate {
  globalPermissions: Array<BaseGlobalPermission>;
}

export const BaseGlobalPermissionUpdateSchema = {
  'globalPermissions': {
    name: 'globalPermissions',
    required: true,
    nullable: false,
    label: 'Globális jogosultságok',
      type: 'array',
  },
}
export function instanceOfBaseGlobalPermissionUpdate(value: object): value is BaseGlobalPermissionUpdate {
let isInstance = true;
    isInstance = isInstance && "globalPermissions" in value;

return isInstance;
}

export function BaseGlobalPermissionUpdateFromJSON(json: any): BaseGlobalPermissionUpdate {
return BaseGlobalPermissionUpdateFromJSONTyped(json, false);
}

export function BaseGlobalPermissionUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGlobalPermissionUpdate {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'globalPermissions': ((json['globalPermissions'] as Array<any>).map(BaseGlobalPermissionFromJSON)),
    };
  }

  export function BaseGlobalPermissionUpdateToJSON(value?: BaseGlobalPermissionUpdate | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'globalPermissions': ((value.globalPermissions as Array<any>).map(BaseGlobalPermissionToJSON)),
    };
  }

  export function BaseGlobalPermissionUpdateCreateEmpty(): BaseGlobalPermissionUpdate {
  return {
      'globalPermissions': [],
  };
  }

