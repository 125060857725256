<template>
    <PegasusHeading>Nagyteremosztó - saját foglalásaim</PegasusHeading>

    <div v-if="period">
        <h4>
            Aktuális időszak: {{ period.start.date() }} - {{ period.finish.date() }}

            <PegasusButton v-if="period.editable" to="events.planning.new" variant="primary">
                Új rendezvény
            </PegasusButton>
        </h4>

        <PegasusTable :data="reservations">
            <Column field="dateText" header="Dátum"></Column>

            <Column header="Név">
                <template #body="{data}: {data: Reservation}">
                    {{ data.name }}
                    <PegasusOverlay v-if="data.priority" content="Kiemelt rendezvény">
                        <i class="fas fa-star"></i>
                    </PegasusOverlay>
                </template>
            </Column>

            <Column field="group.name" header="Kör"></Column>

            <Column field="locationsText" header="Helyszín"></Column>

            <Column :field="(item: Reservation) => item.responsible.name + '(' + item.responsible.email + ')'" header="Felelős"></Column>

            <Column header="Műveletek">
                <template #body="{data}">
                    <PegasusButton :params="{reservation: data}" to="events.planning.edit" variant="primary">
                        <i class="fas fa-edit"></i> Szerkesztés
                    </PegasusButton>

                    <PegasusButton variant="danger" @click="deleteReservation(data)">
                        <i class="fas fa-trash"></i> Törlés
                    </PegasusButton>
                </template>
            </Column>
        </PegasusTable>

        <PegasusButton to="events.planning.index">
            <i class="fa fa-backward"></i>  Vissza a nagyteremosztóra
        </PegasusButton>
    </div>

    <h4 v-else>Nincsen nagyteremosztó időszak</h4>
</template>

<title>Nagyteremosztó</title>

<script lang="ts" setup>
import {ReservationPeriod} from "@Models/events/ReservationPeriod";
import {Reservation} from "@Models/events/Reservation";
import {promiseAll} from '@Utils/misc';
import {useAuthStore} from "@/stores/auth";
import toast from "@Utils/toast";
import {find} from "lodash-es";
import PegasusButton from "@Components/base/PegasusButton.vue";
import Column from "primevue/column";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import PegasusOverlay from "@Components/base/PegasusOverlay.vue";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {ref} from 'vue';

const user = useAuthStore().requireUser();

const loadedData = await promiseAll({
    periods: ReservationPeriod.getAll(),
    reservations: Reservation.getByUser(user.value.id),
});

const reservations = ref(loadedData.reservations);

const period = find(loadedData.periods, period => period.active);

async function deleteReservation(reservation: Reservation) {
    if (!await toast.confirm('Biztos hogy törölni akarod ezt a foglalást?')) {
        return;
    }

    let request = reservation.delete().then(() => {
        delete reservations.value[reservation.id];
    });

    toast.loading(request, 'Törlés');
}
</script>
