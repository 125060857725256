/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface MoneySpendingEventOtherEvent {
  event: string;
  data: string;
}

export const MoneySpendingEventOtherEventSchema = {
  'event': {
    name: 'event',
    required: true,
    nullable: false,
    label: 'Esemény',
    type: 'string',
  },
  'data': {
    name: 'data',
    required: true,
    nullable: false,
    label: 'Részletek',
    type: 'string',
  },
}
export function instanceOfMoneySpendingEventOtherEvent(value: object): value is MoneySpendingEventOtherEvent {
let isInstance = true;
    isInstance = isInstance && "event" in value;
    isInstance = isInstance && "data" in value;

return isInstance;
}

export function MoneySpendingEventOtherEventFromJSON(json: any): MoneySpendingEventOtherEvent {
return MoneySpendingEventOtherEventFromJSONTyped(json, false);
}

export function MoneySpendingEventOtherEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneySpendingEventOtherEvent {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'event': json['event'],
          'data': json['data'],
    };
  }

  export function MoneySpendingEventOtherEventToJSON(value?: MoneySpendingEventOtherEvent | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'event': value.event,
                'data': value.data,
    };
  }

  export function MoneySpendingEventOtherEventCreateEmpty(): MoneySpendingEventOtherEvent {
  return {
      'event': '',
      'data': '',
  };
  }

