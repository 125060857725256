/* eslint-disable */
// noinspection ES6RedundantNestingInTemplateLiteral

import * as runtime from '../runtime';
import type {FormRef} from "@/vue";
  import type {
    BaseSuccessResponse,
    BaseValidationBagResponse,
    KprApplicationStore,
    KprApplicationUpdate,
    KprApplicationWithItemsResponse,
    KprApplicationsWithItemsResponse,
    KprCategoryApplicationResponse,
    KprCategoryApplicationStore,
    KprTenderFlagStatisticsResponse,
    KprTenderListResponse,
    KprTenderResponse,
    KprTenderStore,
    KprTenderSummaryResponse,
    KprTenderUpdate,
    KprUpdateRules,
    MoneyItemPaginationResponse,
    RvtAmendsResponse,
    RvtClaimsResponse,
  } from '../models/index';
    import {
      BaseSuccessResponseFromJSON,
      BaseSuccessResponseToJSON,
      BaseValidationBagResponseFromJSON,
      BaseValidationBagResponseToJSON,
      KprApplicationStoreFromJSON,
      KprApplicationStoreToJSON,
      KprApplicationUpdateFromJSON,
      KprApplicationUpdateToJSON,
      KprApplicationWithItemsResponseFromJSON,
      KprApplicationWithItemsResponseToJSON,
      KprApplicationsWithItemsResponseFromJSON,
      KprApplicationsWithItemsResponseToJSON,
      KprCategoryApplicationResponseFromJSON,
      KprCategoryApplicationResponseToJSON,
      KprCategoryApplicationStoreFromJSON,
      KprCategoryApplicationStoreToJSON,
      KprTenderFlagStatisticsResponseFromJSON,
      KprTenderFlagStatisticsResponseToJSON,
      KprTenderListResponseFromJSON,
      KprTenderListResponseToJSON,
      KprTenderResponseFromJSON,
      KprTenderResponseToJSON,
      KprTenderStoreFromJSON,
      KprTenderStoreToJSON,
      KprTenderSummaryResponseFromJSON,
      KprTenderSummaryResponseToJSON,
      KprTenderUpdateFromJSON,
      KprTenderUpdateToJSON,
      KprUpdateRulesFromJSON,
      KprUpdateRulesToJSON,
      MoneyItemPaginationResponseFromJSON,
      MoneyItemPaginationResponseToJSON,
      RvtAmendsResponseFromJSON,
      RvtAmendsResponseToJSON,
      RvtClaimsResponseFromJSON,
      RvtClaimsResponseToJSON,
    } from '../models/index';

      export interface KprApplicationsDestroyRequest {
        application: number;
      }

      export interface KprApplicationsShowRequest {
        application: number;
      }

      export interface KprApplicationsStoreRequest {
        kprApplicationStore: KprApplicationStore;
      }

      export interface KprApplicationsUpdateRequest {
        application: number;
        kprApplicationUpdate: KprApplicationUpdate;
      }

      export interface KprCategoryApplicationsDeleteRequest {
        application: number;
      }

      export interface KprCategoryApplicationsShowRequest {
        application: number;
      }

      export interface KprCategoryApplicationsStoreRequest {
        kprCategoryApplicationStore: KprCategoryApplicationStore;
      }

      export interface KprStatisticsFlagsRequest {
        tender: number;
      }

      export interface KprStatisticsUnApprovedItemsRequest {
        tender: number;
      }

      export interface KprTenderAmendsRequest {
        tender: number;
      }

      export interface KprTenderApplicationsRequest {
        tender: number;
      }

      export interface KprTenderClaimsRequest {
        tender: number;
      }

      export interface KprTenderDestroyRequest {
        tender: number;
      }

      export interface KprTenderEditRulesRequest {
        tender: number;
        kprUpdateRules: KprUpdateRules;
      }

      export interface KprTenderExportSgrRequest {
        tender: number;
      }

      export interface KprTenderGetRulesRequest {
        tender: number;
      }

      export interface KprTenderItemsByFlagRequest {
        tender: number;
        flag: number;
      }

      export interface KprTenderShowRequest {
        tender: number;
      }

      export interface KprTenderStoreRequest {
        kprTenderStore: KprTenderStore;
      }

      export interface KprTenderSummaryRequest {
        tender: number;
      }

      export interface KprTenderUpdateRequest {
        tender: number;
        kprTenderUpdate: KprTenderUpdate;
      }

      export interface KprTenderWaitingAmendsRequest {
        tender: number;
      }

      export interface KprTenderWaitingClaimsRequest {
        tender: number;
      }

      export class KprApi extends runtime.BaseAPI {

      private async applicationsDestroyRaw(requestParameters: KprApplicationsDestroyRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.application === null || requestParameters.application === undefined) {
          throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling applicationsDestroy.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/kpr/applications/{application}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async applicationsDestroy(application: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.applicationsDestroyRaw({ application: application }, options);
          return await response.value();
        }

      private async applicationsShowRaw(requestParameters: KprApplicationsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<KprApplicationWithItemsResponse>> {
          if (requestParameters.application === null || requestParameters.application === undefined) {
          throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling applicationsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/kpr/applications/{application}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => KprApplicationWithItemsResponseFromJSON(jsonValue));
      }

        static async applicationsShow(application: number, options?: {form?: FormRef}): Promise<{data: KprApplicationWithItemsResponse, raw: Response}> {
          const api = new this();
          const response = await api.applicationsShowRaw({ application: application }, options);
          return await response.value();
        }

      private async applicationsStoreRaw(requestParameters: KprApplicationsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<KprApplicationWithItemsResponse>> {
          if (requestParameters.kprApplicationStore === null || requestParameters.kprApplicationStore === undefined) {
          throw new runtime.RequiredError('kprApplicationStore','Required parameter requestParameters.kprApplicationStore was null or undefined when calling applicationsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/kpr/applications`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: KprApplicationStoreToJSON(requestParameters.kprApplicationStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => KprApplicationWithItemsResponseFromJSON(jsonValue));
      }

        static async applicationsStore(kprApplicationStore: KprApplicationStore, options?: {form?: FormRef}): Promise<{data: KprApplicationWithItemsResponse, raw: Response}> {
          const api = new this();
          const response = await api.applicationsStoreRaw({ kprApplicationStore: kprApplicationStore }, options);
          return await response.value();
        }

      private async applicationsUpdateRaw(requestParameters: KprApplicationsUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<KprApplicationWithItemsResponse>> {
          if (requestParameters.application === null || requestParameters.application === undefined) {
          throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling applicationsUpdate.');
          }

          if (requestParameters.kprApplicationUpdate === null || requestParameters.kprApplicationUpdate === undefined) {
          throw new runtime.RequiredError('kprApplicationUpdate','Required parameter requestParameters.kprApplicationUpdate was null or undefined when calling applicationsUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/kpr/applications/{application}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: KprApplicationUpdateToJSON(requestParameters.kprApplicationUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => KprApplicationWithItemsResponseFromJSON(jsonValue));
      }

        static async applicationsUpdate(application: number, kprApplicationUpdate: KprApplicationUpdate, options?: {form?: FormRef}): Promise<{data: KprApplicationWithItemsResponse, raw: Response}> {
          const api = new this();
          const response = await api.applicationsUpdateRaw({ application: application, kprApplicationUpdate: kprApplicationUpdate }, options);
          return await response.value();
        }

      private async categoryApplicationsDeleteRaw(requestParameters: KprCategoryApplicationsDeleteRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.application === null || requestParameters.application === undefined) {
          throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling categoryApplicationsDelete.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/kpr/capp/{application}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async categoryApplicationsDelete(application: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.categoryApplicationsDeleteRaw({ application: application }, options);
          return await response.value();
        }

      private async categoryApplicationsShowRaw(requestParameters: KprCategoryApplicationsShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<KprCategoryApplicationResponse>> {
          if (requestParameters.application === null || requestParameters.application === undefined) {
          throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling categoryApplicationsShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/kpr/money/capp/{application}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => KprCategoryApplicationResponseFromJSON(jsonValue));
      }

        static async categoryApplicationsShow(application: number, options?: {form?: FormRef}): Promise<{data: KprCategoryApplicationResponse, raw: Response}> {
          const api = new this();
          const response = await api.categoryApplicationsShowRaw({ application: application }, options);
          return await response.value();
        }

      private async categoryApplicationsStoreRaw(requestParameters: KprCategoryApplicationsStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<KprCategoryApplicationResponse>> {
          if (requestParameters.kprCategoryApplicationStore === null || requestParameters.kprCategoryApplicationStore === undefined) {
          throw new runtime.RequiredError('kprCategoryApplicationStore','Required parameter requestParameters.kprCategoryApplicationStore was null or undefined when calling categoryApplicationsStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/kpr/capp`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: KprCategoryApplicationStoreToJSON(requestParameters.kprCategoryApplicationStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => KprCategoryApplicationResponseFromJSON(jsonValue));
      }

        static async categoryApplicationsStore(kprCategoryApplicationStore: KprCategoryApplicationStore, options?: {form?: FormRef}): Promise<{data: KprCategoryApplicationResponse, raw: Response}> {
          const api = new this();
          const response = await api.categoryApplicationsStoreRaw({ kprCategoryApplicationStore: kprCategoryApplicationStore }, options);
          return await response.value();
        }

      private async statisticsFlagsRaw(requestParameters: KprStatisticsFlagsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<KprTenderFlagStatisticsResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling statisticsFlags.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/kpr/tenders/{tender}/statistics/flags`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => KprTenderFlagStatisticsResponseFromJSON(jsonValue));
      }

        static async statisticsFlags(tender: number, options?: {form?: FormRef}): Promise<{data: KprTenderFlagStatisticsResponse, raw: Response}> {
          const api = new this();
          const response = await api.statisticsFlagsRaw({ tender: tender }, options);
          return await response.value();
        }

      private async statisticsUnApprovedItemsRaw(requestParameters: KprStatisticsUnApprovedItemsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<KprApplicationsWithItemsResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling statisticsUnApprovedItems.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/kpr/tenders/{tender}/statistics/unapproved`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => KprApplicationsWithItemsResponseFromJSON(jsonValue));
      }

        static async statisticsUnApprovedItems(tender: number, options?: {form?: FormRef}): Promise<{data: KprApplicationsWithItemsResponse, raw: Response}> {
          const api = new this();
          const response = await api.statisticsUnApprovedItemsRaw({ tender: tender }, options);
          return await response.value();
        }

      private async tenderAmendsRaw(requestParameters: KprTenderAmendsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtAmendsResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tenderAmends.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/kpr/tenders/{tender}/amends`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtAmendsResponseFromJSON(jsonValue));
      }

        static async tenderAmends(tender: number, options?: {form?: FormRef}): Promise<{data: RvtAmendsResponse, raw: Response}> {
          const api = new this();
          const response = await api.tenderAmendsRaw({ tender: tender }, options);
          return await response.value();
        }

      private async tenderApplicationsRaw(requestParameters: KprTenderApplicationsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<KprApplicationsWithItemsResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tenderApplications.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/kpr/tenders/{tender}/applications`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => KprApplicationsWithItemsResponseFromJSON(jsonValue));
      }

        static async tenderApplications(tender: number, options?: {form?: FormRef}): Promise<{data: KprApplicationsWithItemsResponse, raw: Response}> {
          const api = new this();
          const response = await api.tenderApplicationsRaw({ tender: tender }, options);
          return await response.value();
        }

      private async tenderClaimsRaw(requestParameters: KprTenderClaimsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtClaimsResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tenderClaims.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/kpr/tenders/{tender}/claims`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtClaimsResponseFromJSON(jsonValue));
      }

        static async tenderClaims(tender: number, options?: {form?: FormRef}): Promise<{data: RvtClaimsResponse, raw: Response}> {
          const api = new this();
          const response = await api.tenderClaimsRaw({ tender: tender }, options);
          return await response.value();
        }

      private async tenderDestroyRaw(requestParameters: KprTenderDestroyRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tenderDestroy.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/kpr/tenders/{tender}`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async tenderDestroy(tender: number, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.tenderDestroyRaw({ tender: tender }, options);
          return await response.value();
        }

      private async tenderEditRulesRaw(requestParameters: KprTenderEditRulesRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<BaseSuccessResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tenderEditRules.');
          }

          if (requestParameters.kprUpdateRules === null || requestParameters.kprUpdateRules === undefined) {
          throw new runtime.RequiredError('kprUpdateRules','Required parameter requestParameters.kprUpdateRules was null or undefined when calling tenderEditRules.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/kpr/tenders/{tender}/rules`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: KprUpdateRulesToJSON(requestParameters.kprUpdateRules),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => BaseSuccessResponseFromJSON(jsonValue));
      }

        static async tenderEditRules(tender: number, kprUpdateRules: KprUpdateRules, options?: {form?: FormRef}): Promise<{data: BaseSuccessResponse, raw: Response}> {
          const api = new this();
          const response = await api.tenderEditRulesRaw({ tender: tender, kprUpdateRules: kprUpdateRules }, options);
          return await response.value();
        }

      private async tenderExportSgrRaw(requestParameters: KprTenderExportSgrRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<Blob>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tenderExportSgr.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/kpr/tenders/{tender}/export/sgr`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

              if (this.isJsonMime(response.headers.get('content-type'))) {
              return new runtime.JSONApiResponse<Blob>(response);
              } else {
              return new runtime.BlobApiResponse(response) as any;
              }
      }

        static async tenderExportSgr(tender: number, options?: {form?: FormRef}): Promise<{data: Blob, raw: Response}> {
          const api = new this();
          const response = await api.tenderExportSgrRaw({ tender: tender }, options);
          return await response.value();
        }

      private async tenderGetRulesRaw(requestParameters: KprTenderGetRulesRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<Blob>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tenderGetRules.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/kpr/tenders/{tender}/rules`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

              if (this.isJsonMime(response.headers.get('content-type'))) {
              return new runtime.JSONApiResponse<Blob>(response);
              } else {
              return new runtime.BlobApiResponse(response) as any;
              }
      }

        static async tenderGetRules(tender: number, options?: {form?: FormRef}): Promise<{data: Blob, raw: Response}> {
          const api = new this();
          const response = await api.tenderGetRulesRaw({ tender: tender }, options);
          return await response.value();
        }

      private async tenderIndexRaw(options?: {form?: FormRef}): Promise<runtime.ApiResponse<KprTenderListResponse>> {
      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/kpr/tenders`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => KprTenderListResponseFromJSON(jsonValue));
      }

        static async tenderIndex(options?: {form?: FormRef}): Promise<{data: KprTenderListResponse, raw: Response}> {
          const api = new this();
          const response = await api.tenderIndexRaw(options);
          return await response.value();
        }

      private async tenderItemsByFlagRaw(requestParameters: KprTenderItemsByFlagRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<MoneyItemPaginationResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tenderItemsByFlag.');
          }

          if (requestParameters.flag === null || requestParameters.flag === undefined) {
          throw new runtime.RequiredError('flag','Required parameter requestParameters.flag was null or undefined when calling tenderItemsByFlag.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/kpr/tenders/{tender}/itemsByFlag/{flag}`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))).replace(`{${"flag"}}`, encodeURIComponent(String(requestParameters.flag))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => MoneyItemPaginationResponseFromJSON(jsonValue));
      }

        static async tenderItemsByFlag(tender: number, flag: number, options?: {form?: FormRef}): Promise<{data: MoneyItemPaginationResponse, raw: Response}> {
          const api = new this();
          const response = await api.tenderItemsByFlagRaw({ tender: tender, flag: flag }, options);
          return await response.value();
        }

      private async tenderShowRaw(requestParameters: KprTenderShowRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<KprTenderResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tenderShow.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/kpr/tenders/{tender}`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => KprTenderResponseFromJSON(jsonValue));
      }

        static async tenderShow(tender: number, options?: {form?: FormRef}): Promise<{data: KprTenderResponse, raw: Response}> {
          const api = new this();
          const response = await api.tenderShowRaw({ tender: tender }, options);
          return await response.value();
        }

      private async tenderStoreRaw(requestParameters: KprTenderStoreRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<KprTenderResponse>> {
          if (requestParameters.kprTenderStore === null || requestParameters.kprTenderStore === undefined) {
          throw new runtime.RequiredError('kprTenderStore','Required parameter requestParameters.kprTenderStore was null or undefined when calling tenderStore.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/kpr/tenders`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
                body: KprTenderStoreToJSON(requestParameters.kprTenderStore),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => KprTenderResponseFromJSON(jsonValue));
      }

        static async tenderStore(kprTenderStore: KprTenderStore, options?: {form?: FormRef}): Promise<{data: KprTenderResponse, raw: Response}> {
          const api = new this();
          const response = await api.tenderStoreRaw({ kprTenderStore: kprTenderStore }, options);
          return await response.value();
        }

      private async tenderSummaryRaw(requestParameters: KprTenderSummaryRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<KprTenderSummaryResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tenderSummary.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/kpr/tenders/{tender}/summary`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => KprTenderSummaryResponseFromJSON(jsonValue));
      }

        static async tenderSummary(tender: number, options?: {form?: FormRef}): Promise<{data: KprTenderSummaryResponse, raw: Response}> {
          const api = new this();
          const response = await api.tenderSummaryRaw({ tender: tender }, options);
          return await response.value();
        }

      private async tenderUpdateRaw(requestParameters: KprTenderUpdateRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<KprTenderResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tenderUpdate.');
          }

          if (requestParameters.kprTenderUpdate === null || requestParameters.kprTenderUpdate === undefined) {
          throw new runtime.RequiredError('kprTenderUpdate','Required parameter requestParameters.kprTenderUpdate was null or undefined when calling tenderUpdate.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

          headerParameters['Content-Type'] = 'application/json';

      const response = await this.request({
      path: `/api/kpr/tenders/{tender}`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
                body: KprTenderUpdateToJSON(requestParameters.kprTenderUpdate),
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => KprTenderResponseFromJSON(jsonValue));
      }

        static async tenderUpdate(tender: number, kprTenderUpdate: KprTenderUpdate, options?: {form?: FormRef}): Promise<{data: KprTenderResponse, raw: Response}> {
          const api = new this();
          const response = await api.tenderUpdateRaw({ tender: tender, kprTenderUpdate: kprTenderUpdate }, options);
          return await response.value();
        }

      private async tenderWaitingAmendsRaw(requestParameters: KprTenderWaitingAmendsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtAmendsResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tenderWaitingAmends.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/kpr/tenders/{tender}/waitingAmends`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtAmendsResponseFromJSON(jsonValue));
      }

        static async tenderWaitingAmends(tender: number, options?: {form?: FormRef}): Promise<{data: RvtAmendsResponse, raw: Response}> {
          const api = new this();
          const response = await api.tenderWaitingAmendsRaw({ tender: tender }, options);
          return await response.value();
        }

      private async tenderWaitingClaimsRaw(requestParameters: KprTenderWaitingClaimsRequest, options?: {form?: FormRef}): Promise<runtime.ApiResponse<RvtClaimsResponse>> {
          if (requestParameters.tender === null || requestParameters.tender === undefined) {
          throw new runtime.RequiredError('tender','Required parameter requestParameters.tender was null or undefined when calling tenderWaitingClaims.');
          }

      const queryParameters: any = {};

      const headerParameters: runtime.HTTPHeaders = {};

      const response = await this.request({
      path: `/api/kpr/tenders/{tender}/waitingClaims`.replace(`{${"tender"}}`, encodeURIComponent(String(requestParameters.tender))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      }, options);

                return new runtime.JSONApiResponse(response, (jsonValue) => RvtClaimsResponseFromJSON(jsonValue));
      }

        static async tenderWaitingClaims(tender: number, options?: {form?: FormRef}): Promise<{data: RvtClaimsResponse, raw: Response}> {
          const api = new this();
          const response = await api.tenderWaitingClaimsRaw({ tender: tender }, options);
          return await response.value();
        }

    }
