type PlainObj = Record<string, unknown>;
export type PromisesMap<T extends PlainObj> = {
    [P in keyof T]: Promise<T[P]> | T[P];
};

export async function promiseAll<T extends PlainObj>(promisesMap: PromisesMap<T>): Promise<T> {
    const keys = Object.keys(promisesMap);

    const promises = keys.map(key => (promisesMap as any)[key]);

    const results = await Promise.all(promises);

    return results.reduce((resolved, result, index) => {
        resolved[keys[index]] = result;
        return resolved;
    }, {});
}

export function objectWithoutNulls<T extends PlainObj>(object: T): { [P in keyof T]-?: NonNullable<T[P]> } {
    return Object.fromEntries(Object.entries(object).filter(([_, v]) => v != null)) as { [P in keyof T]-?: NonNullable<T[P]> };
}
