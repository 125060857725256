/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { BaseGlobalPermissionAssignmentResponse } from './BaseGlobalPermissionAssignmentResponse';
    import {
    BaseGlobalPermissionAssignmentResponseFromJSON,
    BaseGlobalPermissionAssignmentResponseFromJSONTyped,
    BaseGlobalPermissionAssignmentResponseToJSON,
    BaseGlobalPermissionAssignmentResponseCreateEmpty,
    } from './BaseGlobalPermissionAssignmentResponse';
import {DateTime} from '@Utils/DateTime';

export interface BaseGlobalPermissionAssignmentsResponse {
  assignments: Array<BaseGlobalPermissionAssignmentResponse>;
}

export const BaseGlobalPermissionAssignmentsResponseSchema = {
  'assignments': {
    name: 'assignments',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfBaseGlobalPermissionAssignmentsResponse(value: object): value is BaseGlobalPermissionAssignmentsResponse {
let isInstance = true;
    isInstance = isInstance && "assignments" in value;

return isInstance;
}

export function BaseGlobalPermissionAssignmentsResponseFromJSON(json: any): BaseGlobalPermissionAssignmentsResponse {
return BaseGlobalPermissionAssignmentsResponseFromJSONTyped(json, false);
}

export function BaseGlobalPermissionAssignmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseGlobalPermissionAssignmentsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
            'assignments': ((json['assignments'] as Array<any>).map(BaseGlobalPermissionAssignmentResponseFromJSON)),
    };
  }

  export function BaseGlobalPermissionAssignmentsResponseToJSON(value?: BaseGlobalPermissionAssignmentsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
              'assignments': ((value.assignments as Array<any>).map(BaseGlobalPermissionAssignmentResponseToJSON)),
    };
  }

  export function BaseGlobalPermissionAssignmentsResponseCreateEmpty(): BaseGlobalPermissionAssignmentsResponse {
  return {
      'assignments': [],
  };
  }

