<template>
    <PegasusModal :dismiss="false" header="Felhasználó profil" width="900px">
        <div class="row">
            <div class="col-lg-6">
                <StaticField
                    :value="user.name"
                    inline
                    label="Név"
                ></StaticField>

                <StaticField :value="user.email" inline>
                    <template #label>
                        <b class="me-2">E-mail cím</b>
                        <PegasusHelp title="E-mail cím">Az e-mail cím a neptun <i class="fa fa-arrow-right"></i>
                            bme címtár <i class="fa fa-arrow-right"></i> Auth.sch útvonalon keresztül jut ide.
                            Ha nem megfelelő akkor a neptun felületén kell módosítanod, és a fentiek miatt
                            egy-két napos átfutási idővel fog itt látszódni!
                        </PegasusHelp>
                    </template>
                </StaticField>

                <hr>

                <StaticField
                    :value="user.pekSync?.datetime() ?? 'N/A'"
                    inline
                    label="Utolsó PéK szinkronizáció"
                ></StaticField>

                <PegasusButton v-if="user.pek" variant="success" @click="refresh">
                    <i class="fa fa-refresh"></i> Körtagságok frissítése
                </PegasusButton>

                <PegasusOverlay v-else header="Segítség">
                    <template #overlay>
                        Ideiglenesen nem lehetséges a jogosultságok frissítése.<br/>
                        Amennyiben jogosultság szükséges akkor írj a lenti Hibabejelentés gombon lévő e-mail címre
                    </template>

                    <PegasusButton click="refresh" disabled>
                        <i class="fa fa-refresh"></i> Körtagságok frissítése
                    </PegasusButton>
                </PegasusOverlay>

                <PegasusButton v-if="user.hasPermission(BaseGlobalPermission.ADMIN_ACL)" to="rvt.profile" variant="primary">
                    <i class="fa fa-external-link"></i> Teljes profil
                </PegasusButton>
            </div>

            <div class="col-lg-6">
                <PegasusForm ref="form">
                    <RoomSelectorField
                        v-model="room"
                        :allowed-types="[MapRoomType.LIVING]"
                        :schema="BaseUserUpdateSchema.room"
                        inline
                    ></RoomSelectorField>

                    <TextField
                        v-model="phone"
                        :schema="BaseUserUpdateSchema.phone"
                        inline
                        mask="99/999-99-99"
                        prefix="+36"
                    ></TextField>

                    <TextField
                        v-model="nick"
                        :schema="BaseUserUpdateSchema.nickName"
                        inline
                    ></TextField>

                    <PegasusButton class="float-end" variant="success" @click="save">
                        <i class="fa fa-save"></i> Mentés
                    </PegasusButton>
                </PegasusForm>
            </div>
        </div>
    </PegasusModal>
</template>

<script lang="ts" setup>
import PegasusModal from '@Components/base/PegasusModal.vue';
import {useAuthStore} from '@/stores/auth';
import TextField from '@Components/base/form/types/TextField.vue';
import {BaseGlobalPermission, BaseUserUpdateSchema, MapRoomType} from '@/api/models';
import RoomSelectorField from '@Components/base/form/types/custom/RoomSelectorField.vue';
import PegasusHelp from '@Components/base/PegasusHelp.vue';
import StaticField from '@Components/base/form/types/StaticField.vue';
import {ref} from 'vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import type {FormRef} from '@/vue';
import toast from '@Utils/toast';
import PegasusOverlay from '@Components/base/PegasusOverlay.vue';

const auth = useAuthStore();
const user = useAuthStore().requireUser();

const phone = ref(user.value.phoneShort ?? '');
const room = ref(user.value.room);
const nick = ref(user.value.nick);

const form = ref<FormRef>(null);

function save() {
    const request = user.value.update(form.value, room.value, phone.value, nick.value);

    toast.loading(request);
}

function refresh() {
    const request = auth.refresh();

    toast.loading(request, 'Frissítés');
}
</script>
