import {BaseEntity} from "@Models/BaseEntity";
import {User} from "@Models/base/User";
import {Tender} from "@Models/viko/Tender";
import {ResultCategory} from "@Models/viko/ResultCategory";
import {Report} from "@Models/viko/Report";
import {Review} from "@Models/viko/Review";
import {
    instanceOfVikoApplicationAdminResponse,
    instanceOfVikoApplicationWithReviewsResponse,
    VikoApi,
    type VikoApplicationResponse,
    type VikoApplicationUpdate,
    type VikoApplicationWithReviewsResponse,
    type VikoDone,
    type VikoReviewStore
} from "@/api/api";
import type {FormRef} from '@/vue';

export class Application extends BaseEntity {
    tender: Tender;

    courseCode: string | null;

    result: ResultCategory | null;

    user: User;

    reportCount: number;

    canEdit: boolean;

    done: boolean;

    groupDones: number[];

    ignoreFromCalculations: boolean | null;

    reviews: Record<string, Review>;

    static createNew(): Application {
        return new Application();
    }

    static getByTender(tenderId: number): Promise<Record<number, Application>> {
        return VikoApi.tendersApplications(tenderId).then(response => {
            return Application.newRecords(response.data.applications, Application.parseResponse);
        });
    }

    static getById(id: number): Promise<Application> {
        return VikoApi.applicationsShow(id).then(response => {
            return Application.newSingle(response.data, Application.parseResponse);
        });
    }

    static parseResponse(application: Application, data: VikoApplicationResponse | VikoApplicationWithReviewsResponse): Application {
        application.id = data.id;
        application.tender = Tender.newSingle(data.tender, Tender.parseResponse);
        application.user = User.newSingle(data.user, User.parseMinimalResponse);
        application.reportCount = data.reportCount;
        application.canEdit = data.canEdit;
        if (instanceOfVikoApplicationWithReviewsResponse(data)) {
            application.reviews = Review.newRecordsBy(data.reviews, Review.parseResponse, review => review.userId);
            application.done = data.done;
            application.groupDones = data.groupDones;
        }
        if (instanceOfVikoApplicationAdminResponse(data)) {
            application.ignoreFromCalculations = data.ignoreFromCalculations;
        }

        return application;
    }

    get resultText(): string {
        if (!this.result) {
            return "Nem kapott";
        }

        return this.result.name + " (" + this.result.money.huf() + ")";
    }

    public getReports(): Promise<Record<number, Report>> {
        return Report.getByApplication(this.id);
    }

    public review(point: number, comment: string | null, form: FormRef): Promise<Review> {
        const data: VikoReviewStore = {
            application: this.id,
            point: point,
            comment: comment ?? ""
        };

        return VikoApi.reviewsStore(data, {form}).then(response => {
            return Review.newSingle(response.data, Review.parseResponse);
        });
    }

    public setDone(groupId: number | null, done: boolean): Promise<Application> {
        if (groupId === null) {
            this.done = true;
        } else {
            this.groupDones.push(groupId);
        }

        const data: VikoDone = {
            done: done,
            reviewerGroup: groupId,
        };

        return VikoApi.summaryDone(this.id, data).then(response => {
            return Application.parseResponse(this, response.data);
        });
    }

    public setIgnore(ignore: boolean): Promise<Application> {
        return VikoApi.applicationsIgnore(this.id, {ignore}).then(response => {
            return Application.parseResponse(this, response.data);
        });
    }

    public store(): Promise<Application> {
        return VikoApi.applicationsStore().then(response => {
            return Application.parseResponse(this, response.data);
        });
    }

    public update(): Promise<void> {
        if (this.courseCode === null || this.user.nick === null) {
            throw new Error("CourseCode and nick must be set");
        }

        const data: VikoApplicationUpdate = {
            nickName: this.user.nick,
        };

        return VikoApi.applicationsUpdate(this.id, data).then(response => {
            Application.parseResponse(this, response.data);
        });
    }

    public destroy(): Promise<void> {
        return VikoApi.applicationsDestroy(this.id).then();
    }
}
