import {assign, find, first, forEach, isArray, map, parseInt, reduce, toArray} from "lodash-es";
import type {BaseEntity} from "@Models/BaseEntity";
import {NotFoundError} from "@Utils/errors/NotFoundError";
import type {FieldSchema} from '@Components/base/form/FieldProperties';
import type {Ref} from 'vue';
import type {DynamicDialogInstance} from 'primevue/dynamicdialogoptions';
import type {DialogProps} from 'primevue/dialog';
import type {RouteLocationNormalizedGeneric, RouteParamValueRaw} from 'vue-router';

export function sumObject<T>(collection: Record<number, T>, callback: (item: T) => number): number {
    return reduce(toArray(collection), (sum, value) => sum + callback(value), 0);
}

export function dictEnum<T extends string>(value: Record<T, string>): {
    value: T,
    label: string
}[] {
    return map(value, (value, key) => {
        return {value: key as T, label: value};
    });
}

export function dictNumberEnum<T>(value: Record<number, T>): {
    value: number,
    label: T
}[] {
    return map(value, (value, key) => {
        return {value: parseInt(key), label: value};
    });
}

export function dictObject<T>(
    value: Record<number | string, T>,
    labelMapper: (value: T) => string
): {
    value: T,
    label: string
}[] {
    return map(value, value => {
        return {value: value, label: labelMapper(value)};
    });
}

export function dictArray<T>(
    value: T[],
    labelMapper: (value: T) => string
): {
    value: T,
    label: string
}[] {
    return map(value, value => {
        return {value: value, label: labelMapper(value)};
    });
}

export function numberKeyedDict<T>(value: Record<number, T>): {
    value: number,
    label: T
}[] {
    return map(value, (value, key) => {
        return {
            value: parseInt(key),
            label: value,
        };
    });
}

export function groupBy<TKey extends string | number, TValue>(value: Record<string, TValue>, mapper: (value: TValue) => TKey): Record<TKey, TValue[]> {
    const result: Record<TKey, TValue[]> = {} as Record<TKey, TValue[]>;

    forEach(value, item => {
        const key = mapper(item);

        if (!(key in result)) {
            result[key] = [];
        }

        result[key].push(item);
    });

    return result;
}

export function getStringParamNullable(route: RouteLocationNormalizedGeneric, paramName: string): string | undefined {
    const value = route.params[paramName];

    return isArray(value)
        ? first(value)
        : value;
}

export function getStringParam(route: RouteLocationNormalizedGeneric, paramName: string): string {
    const string = getStringParamNullable(route, paramName);

    if (typeof string === 'undefined') {
        throw new Error('Required property does not exists: ' + paramName);
    }

    return string;
}

export function getNumberParam(route: RouteLocationNormalizedGeneric, paramName: string): number {
    return parseInt(getStringParam(route, paramName));
}

export function getNumberParamNullable(route: RouteLocationNormalizedGeneric, paramName: string): number | undefined {
    const string = getStringParamNullable(route, paramName);

    if (typeof string === 'undefined') {
        return undefined;
    }

    if (string === '') {
        return undefined;
    }

    if (isNaN(parseInt(string))) {
        throw new NotFoundError('Érvénytelen szám az URL-ben');
    }

    return parseInt(string);
}

export function getEnumParam<T extends Record<string, string>>(route: RouteLocationNormalizedGeneric, paramName: string, enumConstants: T): T[keyof T] {
    const value = getStringParam(route, paramName);

    if (!find(enumConstants, constant => constant === value)) {
        throw new Error('Invalid constants value: ' + value);
    }

    return value as T[keyof T];
}

export function routeParamsNormalise(params: Record<string, RouteParamValueRaw | Exclude<RouteParamValueRaw, null | undefined>[] | BaseEntity> | undefined): Record<string, string> {
    if (!params) {
        return {};
    }

    const result: Record<string, string> = {};

    for (const paramsKey in params) {
        let value = params[paramsKey];

        if (!value) {
            continue;
        }

        if (Array.isArray(value)) {
            value = value.toString();
        }

        switch (typeof value) {
            case "number":
                result[paramsKey] = value.toString();
                break;
            case 'string':
                result[paramsKey] = value;
                break;
            case "object":
                result[paramsKey] = value.id.toString();
                break;
        }
    }

    return result;
}

export function routeHashNormalise(params: Record<string, string | number | BaseEntity>): string {
    let result = '';

    for (const paramsKey in params) {
        const value = params[paramsKey];

        result += `&${paramsKey}=`;

        switch (typeof value) {
            case "number":
                result += value.toString();
                break;
            case 'string':
                result += value;
                break;
            case "object":
                result += value.id.toString();
                break;
        }
    }

    return '#' + result.substring(1);
}

export function simpleCheckboxSchema(label: string = '', name: string = 'unknown'): FieldSchema {
    return {
        name: name,
        label: label,
        required: false,
        type: 'boolean',
        nullable: false,
    };
}

const checkNumbers = [9, 7, 3, 1, 9, 7, 3, 1];

export function checkBankAccountNumber(number: string): boolean {
    const parts = [number.substring(0, 8), number.substring(8, 16), number.substring(16, 24)];

    if (parts.length !== 3) {
        return false;
    }

    return parts.every(part => {
        let sum = 0;

        checkNumbers.forEach((checkTerm: number, index: number) => {
            sum += parseInt(part.charAt(index)) * checkTerm;
        });

        return sum % 10 === 0;
    });
}

export function formatAccountNumber(number: string): string {
    const parts = [number.substring(0, 8), number.substring(8, 16), number.substring(16, 24)];

    return parts.join('-');
}

export function dialogOptions(ref: Ref<DynamicDialogInstance>, options: DialogProps): Record<string, any> {
    if (!ref.value.options.props) {
        ref.value.options.props = {};
    }

    assign(ref.value.options.props, options);

    return ref.value.data;
}
