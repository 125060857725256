import {BaseEntity} from '@Models/BaseEntity';
import {Owner} from '@Models/inventory/Owner';
import {ItemUpdate} from '@Models/inventory/ItemUpdate';
import {Group} from '@Models/base/Group';
import {Room} from '@Models/map/Room';
import {Document} from '@Models/inventory/Document';
import {Check} from '@Models/inventory/updates/Check';
import {Damage} from '@Models/inventory/updates/Damage';
import {Repair} from '@Models/inventory/updates/Repair';
import {Termination} from '@Models/inventory/updates/Termination';
import {Transfer} from '@Models/inventory/updates/Transfer';
import {Creation} from '@Models/inventory/updates/Creation';
import {DateTime} from '@Utils/DateTime';
import {DataChange} from '@Models/inventory/updates/DataChange';
import {filter, findLast, keyBy, map} from 'lodash-es';
import type {
    InventoryCreateExistingItem,
    InventoryCreationSource,
    InventoryItemResponse,
    InventoryItemsFilterRequest,
    InventoryMinimalItemResponse,
    InventoryUpdateResponse,
} from '@/api/api';
import {
    InventoryApi,
    type InventoryCreateItemAdministrator,
    InventoryDocumentType,
    type InventoryItemDataUpdate,
    type InventoryUpdateItemAdministrator,
} from '@/api/api';
import {InventoryGroupSummary} from '@Models/inventory/InventoryGroupSummary';
import type {FormRef} from '@/vue';
import {Tag} from '@Models/inventory/Tag';

export type ItemFilter = Omit<InventoryItemsFilterRequest, 'perPage' | 'page'>

export class Item extends BaseEntity {
    name: string | null;

    officialName: string;

    description: string | null;

    uniqueIdentifier: string | null;

    count: number;

    price: number | null;

    material: boolean = false;

    responsible: Group;

    owner: Owner | null;

    kprItemId: number | null;

    sgrId: string | null;

    location: Room | null;

    itemGroupId: number | null;

    documents: Record<number, Document>;

    tags: Tag[];

    updates: ItemUpdate[];

    createdAt: DateTime;

    get idAsText(): string {
        if (!this.id) {
            return '';
        }

        return 'SCH' + this.id.toString().padStart(8, '0');
    }

    static getByCode(code: number): Promise<{item: Item, summary: InventoryGroupSummary}> {
        return InventoryApi.itemsShow(code).then(response => {
            return {
                item: Item.newSingle(response.data.item, Item.parseItemResponse),
                summary: InventoryGroupSummary.newSingle(response.data.groupSummary, InventoryGroupSummary.parseResponse),
            };
        });
    }

    static filter(filter: InventoryItemsFilterRequest): Promise<{data: Record<number, Item>, total: number}> {
        return InventoryApi.itemsFilter(filter.page, filter.perPage, filter.text, filter.group, filter.itemGroup, filter.roomNumber, filter.tag, filter.owner).then(response => {
            return {
                data: Item.newRecords(response.data.data, Item.parseItemResponse),
                total: response.data.total,
            };
        });
    }

    static create(): Item {
        const item = new Item();

        item.name = '';
        item.officialName = '';
        item.description = null;
        item.uniqueIdentifier = null;
        item.count = 1;
        item.price = null;
        item.material = true;
        item.owner = Owner.getDefault();
        item.kprItemId = null;
        item.sgrId = null;
        item.location = null;
        item.itemGroupId = null;
        item.tags = [];
        item.documents = {};
        item.updates = [];

        return item;
    }

    static parseMinimalItemResponse(item: Item, response: InventoryMinimalItemResponse): Item {
        item.id = response.id;
        item.name = response.name;
        item.officialName = response.officialName;
        item.description = response.description;
        item.uniqueIdentifier = null;
        item.count = response.count;
        item.price = null;
        item.material = response.material;
        item.responsible = Group.getBySingleId(response.responsibleId);
        item.owner = response.ownerId ? Owner.getBySingleId(response.ownerId) : null;
        item.kprItemId = null;
        item.sgrId = null;
        item.location = Room.newSingleNullable(response.room, Room.parseResponse);
        item.itemGroupId = response.itemGroupId;
        item.documents = {};
        item.updates = [];
        item.tags = [];
        item.createdAt = response.createdAt;

        return item;
    }

    static parseItemResponse(item: Item, response: InventoryItemResponse): Item {
        item.id = response.id;
        item.name = response.name;
        item.officialName = response.officialName;
        item.description = response.description;
        item.uniqueIdentifier = response.uniqueIdentifier;
        item.count = response.count;
        item.price = response.price;
        item.material = response.material;
        item.responsible = Group.getBySingleId(response.responsibleId);
        item.owner = response.ownerId ? Owner.getBySingleId(response.ownerId) : null;
        item.kprItemId = response.kprItemId;
        item.sgrId = response.sgrId;
        item.location = Room.newSingleNullable(response.room, Room.parseResponse);
        item.itemGroupId = response.itemGroupId;
        item.documents = Document.newRecords(response.documents, Document.parseResponse);
        item.updates = Item.parseUpdates(response.updates);
        item.tags = Tag.getByMultipleId(response.tags);
        item.createdAt = response.createdAt;

        return item;
    }

    private static parseUpdates(updates: InventoryUpdateResponse[]) {
        return map(updates, data => {
            switch (data.data.type) {
                case 'creation':
                    return Creation.newSingle(data, Creation.parseResponse);
                case 'check':
                    return Check.newSingle(data, Check.parseResponse);
                case 'damage':
                    return Damage.newSingle(data, Damage.parseResponse);
                case 'repair':
                    return Repair.newSingle(data, Repair.parseResponse);
                case 'termination':
                    return Termination.newSingle(data, Termination.parseResponse);
                case 'transfer':
                    return Transfer.newSingle(data, Transfer.parseResponse);
                case 'dataChange':
                    return DataChange.newSingle(data, DataChange.parseResponse);
            }
        });
    }

    public storeByAdministrator(): Promise<Item> {
        const request: InventoryCreateItemAdministrator = {
            id: this.id,
            officialName: this.officialName,
            responsible: this.responsible.id,
            material: this.material,
        };

        return InventoryApi.administratorStore(request).then(response => {
            return Item.parseItemResponse(this, response.data);
        });
    }

    public storeByUser(creationSource: InventoryCreationSource, comment: string, form: FormRef): Promise<Item> {
        if (this.name === null) {
            throw new Error('Saving failed');
        }

        if (this.owner === null) {
            throw new Error('Missing owner');
        }

        const request: InventoryCreateExistingItem = {
            id: this.id,
            responsible: this.responsible.id,
            itemGroup: this.itemGroupId,
            owner: this.owner.id,
            kprItem: this.kprItemId,
            location: this.location?.id ?? null,
            name: this.name,
            officialName: this.officialName,
            description: this.description,
            uniqueIdentifier: this.uniqueIdentifier,
            count: this.count,
            price: this.price ?? 0,
            material: this.material,
            creationSource: creationSource,
            comment: comment,
        };

        return InventoryApi.itemCreationExisting(request, {form}).then(response => {
            return Item.parseItemResponse(this, response.data);
        });
    }

    public save(comment: string, form: FormRef): Promise<Item> {
        const request: InventoryItemDataUpdate = {
            itemGroup: this.itemGroupId,
            owner: this.owner?.id ?? null,
            kprItem: this.kprItemId,
            location: this.location?.id ?? null,
            name: this.name ?? '',
            officialName: this.officialName,
            description: this.description || null,
            uniqueIdentifier: this.uniqueIdentifier,
            price: this.price ?? 0,
            count: this.count,
            material: this.material,
            comment: comment,
        };

        return InventoryApi.itemsUpdate(this.id, request, {form}).then(response => {
            return Item.parseItemResponse(this, response.data);
        });
    }

    public updateByAdministrator(form: FormRef, officialName: string, responsible: Group, material: boolean): Promise<Item> {
        const request: InventoryUpdateItemAdministrator = {
            officialName: officialName,
            responsible: responsible.id,
            material: material,
        };

        return InventoryApi.administratorUpdate(this.id, request, {form}).then(response => {
            return Item.parseItemResponse(this, response.data);
        });
    }

    public destroy(): Promise<void> {
        return InventoryApi.itemsDestroy(this.id).then();
    }

    public getLatestImage(): Document | null {
        return findLast(this.documents, document => document.type === InventoryDocumentType.IMAGE) ?? null;
    }

    public getLatestCheck(): Check | Damage | Repair | null {
        return findLast(this.updates, update => update instanceof Check || update instanceof Damage || update instanceof Repair) ?? null;
    }

    public getPendingTermination(): Termination | undefined {
        return findLast(this.updates, update => update instanceof Termination && update.approval.decision === null) as Termination;
    }

    public getTermination(): Termination | undefined {
        return findLast(this.updates, update => update instanceof Termination && update.approval.decision !== null) as Termination;
    }

    public getPendingTransfer(): Transfer | undefined {
        return findLast(this.updates, update => update instanceof Transfer && update.approval.decision === null) as Transfer;
    }

    public getDocumentsByType(type: InventoryDocumentType): Record<number, Document> {
        return keyBy(
            filter(this.documents, document => document.type === type),
            document => document.id,
        );
    }
}
