import {Base} from '@Models/Base';
import {ItemGroup} from '@Models/inventory/ItemGroup';
import {Group} from '@Models/base/Group';
import {InventoryApi, type InventoryGroupStatisticsResponse} from '@/api/api';
import {every, filter, flatMapDeep, keyBy, map, toArray} from 'lodash-es';
import type {Tag} from '@Models/inventory/Tag';
import {useConstantsStore} from '@/stores/constants';
import type {Variants} from '@/vue';
import {Room} from '@Models/map/Room';
import type {Owner} from '@Models/inventory/Owner';

export type TagCount = {
    tag: Tag;

    itemCount: number;

    priceSum: number;
}

export type RoomCount = {
    room: Room;

    itemCount: number;

    priceSum: number;
}

export type OwnerCount = {
    owner: Owner;

    itemCount: number;

    priceSum: number;
}

export class InventoryGroupStatistics extends Base {
    group: Group;

    itemsWithoutGroup: number;

    tagCounts: Record<number, TagCount>;

    roomStatistics: Record<number, RoomCount>;

    ownerCounts: Record<number, OwnerCount>;

    rootGroup: ItemGroup;

    static parseResponse(summary: InventoryGroupStatistics, response: InventoryGroupStatisticsResponse): InventoryGroupStatistics {
        summary.group = Group.getBySingleId(response.groupId);

        const constants = useConstantsStore().constants;

        const tagCounts = map(response.tagCounts, tagCount => {
            return {
                tag: constants.inventory.tags[tagCount.countedById],
                itemCount: tagCount.itemCount,
                priceSum: tagCount.sumPrice,
            };
        });

        summary.tagCounts = keyBy(tagCounts, tagCount => tagCount.tag.id);

        summary.roomStatistics = map(response.roomStatistics, data => {
            return {
                room: Room.newSingle(data.room, Room.parseResponse),
                itemCount: data.itemCount,
                priceSum: data.sumPrice,
            };
        });

        const ownerCounts = map(response.ownerCounts, ownerCount => {
            return {
                owner: constants.inventory.owners[ownerCount.countedById],
                itemCount: ownerCount.itemCount,
                priceSum: ownerCount.sumPrice,
            };
        });

        summary.ownerCounts = keyBy(ownerCounts, ownerCount => ownerCount.owner.id);

        return summary;
    }

    static getByGroup(groupId: number): Promise<InventoryGroupStatistics> {
        return InventoryApi.groupsStatistics(groupId).then(response => {
            return InventoryGroupStatistics.newSingle(response.data, this.parseResponse);
        });
    }

    public allItemGroups(): Record<number, ItemGroup> {
        return keyBy(
            flatMapDeep(
                this.rootGroup.children,
                itemGroup => toArray(itemGroup.children).concat([itemGroup]),
            ),
            itemGroup => itemGroup.id,
        );
    }

    public filterTagCounts(severity: Variants): TagCount[] {
        return filter(this.tagCounts, tagCount => tagCount.tag.severity === severity);
    }

    get noWarnings(): boolean {
        return every(this.tagCounts, tagCount => tagCount.tag.severity !== 'danger' && tagCount.tag.severity !== 'warning');
    }
}
