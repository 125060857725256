/* tslint:disable */
/* eslint-disable */

export enum MoneySpendingEventType {
    CREATED = 'created',
    CASH_IN = 'cashIn',
    CASH_OUT = 'cashOut',
    INVOICE_UPLOADED = 'invoiceUploaded',
    TRANSFER_REQUESTED = 'transferRequested',
    TRANSFER_REGISTERED = 'transferRegistered',
    TRANSFER_SENT = 'transferSent',
    SPENDING_SETTLED = 'spendingSettled',
    OTHER_EVENT = 'otherEvent'
}

export const MoneySpendingEventTypeValues: Record<MoneySpendingEventType, string> = {
    'created': 'Altétel létrehozva',
    'cashIn': 'Készpénz leadás',
    'cashOut': 'Készpénz kiadás',
    'invoiceUploaded': 'Számla feltöltés',
    'transferRequested': 'Utalás kérvényezve',
    'transferRegistered': 'Utalás rögzítve',
    'transferSent': 'Utalás elküldve',
    'spendingSettled': 'Költés elszámolva',
    'otherEvent': 'Egyéb esemény'
}


export function MoneySpendingEventTypeFromJSON(json: any): MoneySpendingEventType {
    return MoneySpendingEventTypeFromJSONTyped(json, false);
}

export function MoneySpendingEventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneySpendingEventType {
    return json as MoneySpendingEventType;
}

export function MoneySpendingEventTypeToJSON(value?: MoneySpendingEventType | null): any {
    return value as any;
}

export function MoneySpendingEventTypeCreateEmpty(): MoneySpendingEventType {
    return MoneySpendingEventType.CREATED;
}

