<template>
    <PegasusModal header="Új altétel">
        <PegasusForm ref="form">
            <DataForm :spending-item="spendingItem"></DataForm>

            <div>Az altétel létrehozás után még nem költhető,
                ehhez a gazdasági láb felelősének jóváhagyása szükséges
            </div>

            <div>A tervezett költés előtt legalább 14 nappal szükséges létrehozni az altételt.
                Az ennél előbbi költés esetén külön egyeztetés szükséges a gazdasági láb felelősével.
            </div>

            <PegasusButton class="mt-15" variant="success" @click="save">
                <i class="fa fa-save"></i> Létrehozás
            </PegasusButton>
        </PegasusForm>
    </PegasusModal>
</template>

<script lang="ts" setup>
import DataForm from '@Components/money/spendingItem/DataForm.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import {Item} from '@Models/money/Item';
import {ref} from 'vue';
import {SpendingItem} from '@Models/money/SpendingItem';
import type {FormRef} from '@/vue';
import toast from '@Utils/toast';
import {redirect} from '@/router/index';
import PegasusModal from '@Components/base/PegasusModal.vue';
import {useReturn} from '@Utils/dialog';

const {item} = defineProps<{
    item: Item
}>();

const spendingItem = ref(new SpendingItem());

const form = ref<FormRef>(null);

const returnValue = useReturn();

async function save() {
    if (!await toast.confirm('Biztosan létre akarod hozni az altételt?')) {
        return;
    }

    const request = spendingItem.value.store(item.id, form.value).then(() => {
        redirect({
            name: 'money.spendingItem.show',
            params: {
                item: item.id,
                spendingItem: spendingItem.value.id,
            },
        });

        returnValue(true);
    });

    toast.loading(request);
}
</script>