<template>
    <div>
        <i :class="'text-white fas ' + icons[milestone.type]"></i>

        <div class="timeline-item">
            <span class="time">
                <template v-if="milestone.user">
                    <i class="fas fa-user"></i> {{ milestone.user.name }}
                </template>
                <template v-else>
                    <i class="fas fa-user-cog"></i> System
                </template>
                <i class="fas fa-clock ms-1"></i> {{ milestone.createdAt.datetime() }}
            </span>
            <h3 class="timeline-header">{{ EventsMilestoneTypeValues[milestone.type] }}</h3>

            <PegasusOverlay v-if="milestone.comment" :content="milestone.comment" header="Megjegyzés">
                <div
                    class="timeline-body"
                >
                    <i class="fas fa-comment"></i> {{ milestone.comment.limit(40) }}
                </div>
            </PegasusOverlay>

            <div
                v-if="milestone.modifyData"
                class="timeline-body"
            >
                <a class="p-link" @click="Dialog.open(AdminModificationDialog, {milestone})">
                    <i class="fas fa-user-edit"></i> Módosítások áttekintése
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {Milestone} from "@Models/events/Milestone";
import PegasusOverlay from "@Components/base/PegasusOverlay.vue";
import {EventsMilestoneTypeValues} from "@/api/models";
import {Dialog} from '@Utils/dialog';
import AdminModificationDialog from '@Components/events/dialogs/AdminModificationDialog.vue';

const {milestone} = defineProps<{
    milestone: Milestone
}>();

const icons = Milestone.getIcons();
</script>
