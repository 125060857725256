<template>
    <TextareaField
        v-model="data.reason"
        :schema="EventsKitchenReservationDataSchema.reason"
    ></TextareaField>

    <DatetimeField
        v-model="data.from"
        :disabled="!service.canEdit"
        :schema="EventsKitchenReservationDataSchema.from"
    ></DatetimeField>

    <DatetimeField
        v-model="data.to"
        :disabled="!service.canEdit"
        :schema="EventsKitchenReservationDataSchema.to"
    ></DatetimeField>

    <NumberField
        v-model="data.stoveCount"
        :schema="EventsKitchenReservationDataSchema.stoveCount"
    ></NumberField>

    <TextareaField
        v-model="data.appliances"
        :schema="EventsKitchenReservationDataSchema.appliances"
    ></TextareaField>
</template>

<script lang="ts" setup>
import type {Service} from '@Models/events/Service';
import {type EventsKitchenReservationData, EventsKitchenReservationDataSchema} from '@/api/models';
import DatetimeField from '@Components/base/form/types/DatetimeField.vue';
import NumberField from '@Components/base/form/types/NumberField.vue';
import TextareaField from '@Components/base/form/types/TextareaField.vue';

const data = defineModel<EventsKitchenReservationData>('data', {required: true});

const {service} = defineProps<{
    service: Service
}>();
</script>
