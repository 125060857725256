import {DateTime} from "@Utils/DateTime";
import {BaseEntity} from "@Models/BaseEntity";
import {ReviewerGroup} from "@Models/viko/ReviewerGroup";
import {User} from "@Models/base/User";
import {File} from "@Models/File";
import {
    VikoApi,
    type VikoExtendedTenderResponse,
    VikoReviewStage,
    type VikoTenderModerator,
    type VikoTenderResponse,
    VikoTenderStatus, VikoTenderStatusValues,
    type VikoTenderStore,
    type VikoTenderUpdate
} from "@/api/api";
import {find, map, min, some} from "lodash-es";
import {ResultCategory} from "@Models/viko/ResultCategory";
import type {FormRef} from '@/vue';

export class Tender extends BaseEntity {
    year: number;

    semester: number;

    begin: DateTime;

    end: DateTime;

    beginDrafting: DateTime;

    endDrafting: DateTime;

    endAccepting: DateTime;

    status: VikoTenderStatus;

    isPublic: boolean;

    budget: number | null;

    reviewerGroups: Record<number, ReviewerGroup> | null;

    moderators: Record<number, User> | null;

    resultCategories: Record<number, ResultCategory> = {};

    static createNew(): Tender {
        const tender = new Tender();

        tender.beginDrafting = DateTime.now();
        tender.endDrafting = DateTime.now().addDay(14).endOfDay();
        tender.endAccepting = DateTime.now().addDay(14).endOfDay();
        tender.year = DateTime.now().year;
        tender.semester = DateTime.now().month >= 9 ? 1 : 0;

        return tender;
    }

    static getAll(): Promise<Record<number, Tender>> {
        return VikoApi.tendersIndex().then(response => {
            return Tender.newRecords(response.data.tenders, Tender.parseResponse);
        });
    }

    static getById(id: number): Promise<Tender> {
        return VikoApi.tendersShow(id).then(response => {
            return Tender.newSingle(response.data, Tender.parseExtendedResponse);
        });
    }

    static parseResponse(tender: Tender, data: VikoTenderResponse): Tender {
        tender.id = data.id;
        tender.year = data.year;
        tender.semester = data.semester;
        tender.begin = data.begin;
        tender.end = data.end;
        tender.beginDrafting = data.beginDrafting;
        tender.endDrafting = data.endDrafting;
        tender.endAccepting = data.endAccepting;
        tender.status = data.status;
        tender.isPublic = data.isPublic;

        return tender;
    }

    static parseExtendedResponse(tender: Tender, data: VikoExtendedTenderResponse): Tender {
        Tender.parseResponse(tender, data);

        tender.budget = data.budget;
        tender.reviewerGroups = ReviewerGroup.newRecords(data.reviewerGroups, ReviewerGroup.parseResponse);
        tender.moderators = User.newRecords(data.moderators, User.parseMinimalResponse);
        tender.resultCategories = ResultCategory.newRecords(data.resultCategories, ResultCategory.parseResponse);

        return tender;
    }

    get computedReviewStage(): VikoReviewStage {
        return min(map(this.reviewerGroups, group => group.reviewStage)) ?? VikoReviewStage.STAGE_NONE;
    }

    public store(form: FormRef): Promise<Tender> {
        const data: VikoTenderStore = {
            year: this.year,
            semester: this.semester,
            begin: this.begin,
            end: this.end,
            beginDrafting: this.beginDrafting,
            endDrafting: this.endDrafting,
            endAccepting: this.endAccepting,
        };

        return VikoApi.tendersStore(data, {form}).then(response => {
            return Tender.newSingle(response.data, Tender.parseExtendedResponse);
        });
    }

    public update(): Promise<void> {
        const data: VikoTenderUpdate = {
            begin: this.begin,
            end: this.end,
            beginDrafting: this.beginDrafting,
            endDrafting: this.endDrafting,
            endAccepting: this.endAccepting,
            status: this.status,
            isPublic: this.isPublic,
        };

        return VikoApi.tendersUpdate(this.id, data).then(response => {
            Tender.parseExtendedResponse(this, response.data);
        });
    }

    public exportResults(): Promise<File> {
        return VikoApi.tendersExportResults(this.id).then(response => {
            return File.createFromResponse(response);
        });
    }

    public exportIgnoredResults(): Promise<File> {
        return VikoApi.tendersExportIgnoredResults(this.id).then(response => {
            return File.createFromResponse(response);
        });
    }

    public exportApplications(): Promise<File> {
        return VikoApi.tendersExportApplications(this.id).then(response => {
            return File.createFromResponse(response);
        });
    }

    public addModerator(userId: string): Promise<void> {
        const data: VikoTenderModerator = {
            user: userId,
        };

        return VikoApi.tendersAddModerator(this.id, data).then(response => {
            Tender.parseExtendedResponse(this, response.data);
        });
    }

    public removeModerator(userId: string): Promise<void> {
        const data: VikoTenderModerator = {
            user: userId,
        };

        return VikoApi.tendersRemoveModerator(this.id, data).then(response => {
            Tender.parseExtendedResponse(this, response.data);
        });
    }

    get active(): boolean {
        return this.status !== VikoTenderStatus.ARCHIVED;
    }

    get inDrafting(): boolean {
        return this.beginDrafting.isPast() && this.endDrafting.isFuture();
    }

    get displayName(): string {
        if (this.semester === 0) {
            return '' + (this.year - 1) + '/' + this.year + ' tavaszi félév';
        } else {
            return '' + this.year + '/' + (this.year + 1) + ' őszi félév';
        }
    }

    public findReviewerGroupForUser(user: User): ReviewerGroup | null {
        return find(
            this.reviewerGroups,
            (group: ReviewerGroup) => find(group.reviewers, reviewer => reviewer.id === user.id) !== undefined
        ) ?? null;
    }

    public isUserModerator(user: User): boolean {
        return some(this.moderators, moderator => moderator.id === user.id);
    }

    public displayStatus(): string {
        return VikoTenderStatusValues[this.status];
    }
}
