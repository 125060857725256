import {BaseEntity} from "@Models/BaseEntity";
import {Location} from "@Models/events/Location";
import {User} from "@Models/base/User";
import {Group} from "@Models/base/Group";
import {DateTime} from "@Utils/DateTime";
import type {
    EventsReservationReassign,
    EventsReservationResponse,
    EventsReservationStore,
    EventsReservationUpdate
} from "@/api/api";
import {EventsApi} from "@/api/api";
import {isEmpty, map, toArray} from "lodash-es";
import type {FormRef} from '@/vue';

export class Reservation extends BaseEntity {
    name: string;

    start: DateTime;

    finish: DateTime;

    locations: Location[];

    priority: boolean;

    group: Group;

    responsible: User;

    decision: boolean | null;

    static createNew(): Reservation {
        const reservation = new Reservation();

        reservation.priority = false;
        reservation.decision = null;
        reservation.start = DateTime.now().addMonth();
        reservation.finish = DateTime.now().addMonth().addDay(1);

        return reservation;
    }

    static getById(id: number): Promise<Reservation> {
        return EventsApi.reservationsShow(id).then(response => {
            return Reservation.newSingle(response.data, Reservation.parseResponse);
        });
    }

    static getByMonth(year: number, month: number): Promise<Record<number, Reservation>> {
        return EventsApi.reservationsIndex(year, month).then(response => {
            return Reservation.newRecords(response.data.reservations, Reservation.parseResponse);
        });
    }

    static getByUser(userId: string): Promise<Record<number, Reservation>> {
        return EventsApi.usersReservations(userId).then(response => {
            return Reservation.newRecords(response.data.reservations, Reservation.parseResponse);
        });
    }

    static getRelevantByGroup(groupId: number): Promise<Record<number, Event>> {
        return EventsApi.groupsReservations(groupId).then(response => {
            return Reservation.newRecords(response.data.reservations, Reservation.parseResponse);
        });
    }

    static parseResponse(event: Reservation, data: EventsReservationResponse): Reservation {
        event.id = data.id;
        event.name = data.name;
        event.start = data.start;
        event.finish = data.finish;
        event.locations = toArray(Location.getRecordsById(data.locations));
        event.priority = data.priority;
        event.group = Group.getBySingleId(data.groupId);
        event.responsible = User.newSingle(data.responsible, User.parseBaseResponse);

        return event;
    }

    public store(form: FormRef): Promise<Reservation> {
        const data: EventsReservationStore = {
            name: this.name,
            start: this.start,
            finish: this.finish,
            locations: Reservation.entitiesToArray(this.locations),
            group: this.group.id,
            priority: this.priority,
        };

        return EventsApi.reservationsStore(data, {form}).then(response => {
            return Reservation.parseResponse(this, response.data);
        });
    }

    public update(form: FormRef): Promise<void> {
        const data: EventsReservationUpdate = {
            name: this.name,
            start: this.start,
            finish: this.finish,
            locations: Reservation.entitiesToArray(this.locations),
            group: this.group.id,
            priority: this.priority,
        };

        return EventsApi.reservationsUpdate(this.id, data, {form}).then(response => {
            Reservation.parseResponse(this, response.data);
        });
    }

    public reassign(user: User, form: FormRef): Promise<void> {
        const data: EventsReservationReassign = {
            responsible: user.id
        };

        return EventsApi.reservationsReassign(this.id, data, {form}).then(response => {
            Reservation.parseResponse(this, response.data);
        });
    }

    public delete(): Promise<void> {
        return EventsApi.reservationsDelete(this.id).then();
    }

    get locationsText() {
        if (isEmpty(this.locations)) {
            return 'Külsős';
        }

        return map(this.locations, location => location.name).join(', ');
    }

    get dateText(): string {
        if (this.start.isSameDay(this.finish)) {
            return this.start.date();
        }

        if (this.finish.hour < 12 && this.start.until(this.finish).length('days') <= 1) {
            return this.start.date();
        }

        return this.start.date() + ' - ' + this.finish.date();
    }
}

