<template>
    <PegasusHeading>Leltár tétel kereső</PegasusHeading>

    <div class="row">
        <div class="col-lg-3">
            <GroupSelectorField
                v-model="group"
                :disabled="!user.hasPermission(BaseGlobalPermission.INVENTORY_READ)"
                :schema="{name: 'group', label: 'Felelős kör', required: false, nullable: true, type: 'number'}"
                clearable
            ></GroupSelectorField>
        </div>

        <div class="col-lg-3">
            <RoomSelectorField
                ref="roomSelector"
                :model-value="location ?? null"
                :schema="{name: 'location', label: 'Helyiség', required: false, nullable: true, type: 'number'}"
                clearable
                @update:model-value="value => location = value ?? undefined"
            ></RoomSelectorField>
        </div>

        <div class="col-lg-3">
            <SelectField
                v-model="owner"
                :options="dictObject(constants.owners, owner => owner.name)"
                :schema="{name: 'owner', label: 'Tulajdonos', required: false, nullable: true, type: 'number'}"
                clearable
            ></SelectField>
        </div>

        <div class="col-lg-3">
            <SelectField
                v-model="tag"
                :options="dictObject(constants.tags, tag => tag.name)"
                :schema="{name: 'tag', label: 'Cimke', required: false, nullable: true, type: 'number'}"
                clearable
            ></SelectField>
        </div>
    </div>

    <PegasusTable :data="data" :per-page="10" search>
        <template #header>
            <SelectField
                v-if="groupSummary"
                v-model="itemGroup"
                :options="dictObject(groupSummary.allItemGroups(), itemGroup => itemGroup.name)"
                :schema="InventoryItemDataUpdateSchema.itemGroup"
                inline
            ></SelectField>
        </template>
        <Column field="idAsText" header="Szám"></Column>

        <Column field="name" header="Név"></Column>

        <Column field="count" header="Darabszám"></Column>

        <Column header="Műveletek">
            <template #body="{data: row}">
                <PegasusButton :params="{code: row.idAsText}" to="inventory.items.default" variant="primary">
                    Megnyitás
                </PegasusButton>
            </template>
        </Column>
    </PegasusTable>
</template>

<title>Schönherz Leltár</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {useAuthStore} from '@/stores/auth';
import {onMounted, ref, useTemplateRef, watch} from 'vue';
import {Item} from '@Models/inventory/Item';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import Column from 'primevue/column';
import {first, isArray} from 'lodash-es';
import GroupSelectorField from '@Components/base/form/types/custom/GroupSelectorField.vue';
import {Group} from '@Models/base/Group';
import type {Room} from '@Models/map/Room';
import {useConstantsStore} from '@/stores/constants';
import RoomSelectorField from '@Components/base/form/types/custom/RoomSelectorField.vue';
import SelectField from '@Components/base/form/types/SelectField.vue';
import {dictObject} from '@Models/Utils';
import type {Owner} from '@Models/inventory/Owner';
import type {Tag} from '@Models/inventory/Tag';
import {BaseGlobalPermission, InventoryItemDataUpdateSchema} from '@/api/models/index';
import type {ItemGroup} from '@Models/inventory/ItemGroup';
import {InventoryGroupSummary} from '@Models/inventory/InventoryGroupSummary';
import {useUrlSearchParams, whenever} from '@vueuse/core';

type Parameters = {
    group?: string;
    itemGroup?: string;
    location?: string;
    owner?: string;
    tag?: string;
};

const user = useAuthStore().requireUser();

const roomSelector = useTemplateRef('roomSelector');

const params = useUrlSearchParams('hash-params');

const constants = useConstantsStore().constants.inventory;

const group = ref<Group | undefined>(undefined);
const groupSummary = ref<InventoryGroupSummary | undefined>(undefined);
watch(group, newValue => {
    groupSummary.value = undefined;

    if (newValue) {
        params.group = newValue.id.toString();
        InventoryGroupSummary.getByGroup(newValue.id).then(summary => {
            groupSummary.value = summary;
        });
    } else {
        delete params.group;
    }
});

const itemGroup = ref<ItemGroup | undefined>(undefined);
watch(itemGroup, newValue => {
    if (newValue) {
        params.itemGroup = newValue.id.toString();
    } else {
        delete params.itemGroup;
    }
});

const location = ref<Room | undefined>(undefined);
watch(location, newValue => {
    if (newValue) {
        params.location = newValue.displayNumber;
    } else {
        delete params.location;
    }
});

const owner = ref<Owner | undefined>(undefined);
watch(owner, newValue => {
    if (newValue) {
        params.owner = newValue.id.toString();
    } else {
        delete params.owner;
    }
});

const tag = ref<Tag | undefined>(undefined);
watch(tag, newValue => {
    if (newValue) {
        params.tag = newValue.id.toString();
    } else {
        delete params.tag;
    }
});

watch(params, newParams => {
    data.value = getFilterFunction(newParams);
});

if ('group' in params) {
    const groupId = number(params.group);
    if (groupId) {
        group.value = Group.getBySingleId(groupId);
    }

    if ('itemGroup' in params) {
        const itemGroupId = number(params.itemGroup);
        if (itemGroupId) {
            whenever(() => !!groupSummary.value, () => {
                if (!groupSummary.value || !itemGroupId) {
                    throw new Error('Cannot happen');
                }

                itemGroup.value = groupSummary.value.allItemGroups()[itemGroupId];
            });
        }
    }
}

if ('tag' in params) {
    const tagId = number(params.tag);
    if (tagId) {
        tag.value = constants.tags[tagId];
    }
}

if ('owner' in params) {
    const ownerId = number(params.owner);
    if (ownerId) {
        owner.value = constants.owners[ownerId];
    }
}

onMounted(() => {
    if ('location' in params) {
        if (params.location.length > 1) {
            roomSelector.value.setByRoomNumber(params.location);
        }
    }
});

const data = ref(getFilterFunction(params));

function getFilterFunction(parameters: Parameters) {
    return async function data(page: number, perPage: number, filter: string | undefined) {
        return await Item.filter({
            page,
            perPage,
            text: string(filter),
            group: number(parameters.group),
            itemGroup: number(parameters.itemGroup),
            roomNumber: parameters.location,
            tag: number(parameters.tag),
            owner: number(parameters.owner),
        });
    };
}

function string(param: string | string[] | undefined) {
    if (param === undefined) {
        return undefined;
    }

    const string = isArray(param) ? first(param) : param;

    if (!string || string.length === 0) {
        return undefined;
    }

    return string;
}

function number(param: string | string[] | undefined) {
    const parsed = string(param);

    return parsed
        ? parseInt(parsed)
        : undefined;
}
</script>
