import {SpendingItemEvent} from '@Models/money/SpendingItemEvent';
import {
    type MoneySpendingEventOtherEvent,
    MoneySpendingEventOtherEventSchema,
    MoneySpendingEventType,
    type MoneySpendingItemEvent,
    type MoneySpendingItemEventResponse,
} from '@/api/models';
import type {FormRef} from '@/vue';
import {MoneyApi} from '@/api/apis';

export class OtherEvent extends SpendingItemEvent {
    event: string;

    data: string;

    static parseResponse(otherEvent: OtherEvent, data: MoneySpendingItemEventResponse): OtherEvent {
        SpendingItemEvent.parseResponse(otherEvent, data);

        const specific = data.data as MoneySpendingEventOtherEvent;
        otherEvent.event = specific.event;
        otherEvent.data = specific.data;

        return otherEvent;
    }

    public store(itemId: number, form: FormRef): Promise<OtherEvent> {
        const data: MoneySpendingItemEvent = {
            type: MoneySpendingEventType.OTHER_EVENT,
            data: {
                type: MoneySpendingEventType.OTHER_EVENT,
                event: this.event,
                data: this.data,
            },
            comment: this.comment,
        };

        return MoneyApi.spendingEventsOtherEvent(itemId, data, {form}).then(response => {
            return OtherEvent.parseResponse(this, response.data);
        });
    }

    get text(): {key: string, value: string}[] {
        return [
            {
                key: MoneySpendingEventOtherEventSchema.event.label,
                value: this.event,
            },
            {
                key: MoneySpendingEventOtherEventSchema.data.label,
                value: this.data,
            },
        ];
    }
}
