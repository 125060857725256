/* tslint:disable */
/* eslint-disable */

export enum MoneyBudgetLocation {
    SVIE = 'SVIE',
    KB = 'KB',
    SKA = 'SKA',
    SCHIS = 'SCHIS',
    ESZI = 'ESZI',
    MUHAL = 'MUHAL',
    MUHASZ = 'MUHASZ',
    Internal = 'Internal',
    Other = 'Other'
}

export const MoneyBudgetLocationValues: Record<MoneyBudgetLocation, string> = {
    'SVIE': 'SVIE',
    'KB': 'KB',
    'SKA': 'SKA',
    'SCHIS': 'SCHIS',
    'ESZI': 'ÉSZI',
    'MUHAL': 'MŰHAL',
    'MUHASZ': 'MŰHASZ',
    'Internal': 'Belső',
    'Other': 'Egyéb'
}


export function MoneyBudgetLocationFromJSON(json: any): MoneyBudgetLocation {
    return MoneyBudgetLocationFromJSONTyped(json, false);
}

export function MoneyBudgetLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoneyBudgetLocation {
    return json as MoneyBudgetLocation;
}

export function MoneyBudgetLocationToJSON(value?: MoneyBudgetLocation | null): any {
    return value as any;
}

export function MoneyBudgetLocationCreateEmpty(): MoneyBudgetLocation {
    return MoneyBudgetLocation.SVIE;
}

