<template>
    <PegasusHeading>Házszintű tétel</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusPanel header="Adatok">
                <PegasusForm ref="form">
                    <TextField
                        v-model="item.name"
                        :disabled="!canEdit"
                        :schema="MoneyBudgetItemUpdateSchema.name"
                    ></TextField>

                    <TextareaField
                        v-model="item.description"
                        :disabled="!canEdit"
                        :schema="MoneyBudgetItemUpdateSchema.description"
                    ></TextareaField>

                    <NumberField
                        v-model="item.number"
                        :disabled="!canEdit"
                        :schema="MoneyBudgetItemUpdateSchema.number"
                    ></NumberField>

                    <GroupSelectorField
                        v-model="item.group"
                        :disabled="!canEdit"
                        :schema="MoneyBudgetItemUpdateSchema.group"
                    ></GroupSelectorField>

                    <dt>Lábakra osztás</dt>
                    <dd>
                        <div v-for="value in item.values" :key="value.id">
                            {{ MoneyBudgetLocationValues[value.location] }}: {{ value.value.huf() }}
                        </div>
                    </dd>

                    <PegasusButton v-if="canEdit" variant="success" @click="save">
                        <i class="fa fa-save"></i> Mentés
                    </PegasusButton>

                    <PegasusButton v-if="canEdit" variant="danger" @click="remove">
                        <i class="fa fa-trash"></i> Sor törlése
                    </PegasusButton>
                </PegasusForm>
            </PegasusPanel>

            <PegasusPanel header="Nyílt pályázatok" class="mt-2">
                <PegasusTable :data="openTenders">
                    <Column field="name" header="Név"></Column>

                    <Column header="Műveletek">
                        <template #body="{data}">
                            <PegasusButton :params="{tender: data}" to="money.openTenders.show">
                                <i class="fa fa-external-link"></i> Megnyitás
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>

                <PegasusButton variant="success" @click="Dialog.open(OpenTenderCreateDialog, {item})">
                    <i class="fa fa-plus"></i> Új nyílt pályázat
                </PegasusButton>
            </PegasusPanel>
        </div>

        <div class="col-lg-6">
            <PegasusPanel header="Költségvetések">
                <PegasusTable :data="programs">
                    <Column field="name" header="Név"></Column>

                    <Column field="statusText" header="Állapot"></Column>

                    <Column header="Műveletek">
                        <template #body="{data}">
                            <PegasusButton :params="{program: data}" to="money.budget.program.show">
                                <i class="fa fa-external-link"></i> Megnyitás
                            </PegasusButton>
                        </template>
                    </Column>
                </PegasusTable>

                <PegasusButton variant="success" @click="Dialog.open(BudgetProgramStoreDialog, {item})">
                    <i class="fa fa-plus"></i> Új költségvetés
                </PegasusButton>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Házszintű tétel</title>

<script lang="ts" setup>
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {ref} from 'vue';
import {BudgetItem} from '@Models/money/BudgetItem';
import PegasusTable from '@Components/base/table/PegasusTable.vue';
import {promiseAll} from '@Utils/misc';
import {BudgetProgram} from '@Models/money/BudgetProgram';
import Column from 'primevue/column';
import PegasusButton from '@Components/base/PegasusButton.vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import {MoneyBudgetItemUpdateSchema, MoneyBudgetLocationValues} from '@/api/models';
import TextareaField from '@Components/base/form/types/TextareaField.vue';
import TextField from '@Components/base/form/types/TextField.vue';
import PegasusForm from '@Components/base/form/PegasusForm.vue';
import GroupSelectorField from '@Components/base/form/types/custom/GroupSelectorField.vue';
import NumberField from '@Components/base/form/types/NumberField.vue';
import toast from '@Utils/toast';
import type {FormRef} from '@/vue';
import {redirect} from '@/router';
import {Dialog} from '@Utils/dialog';
import BudgetProgramStoreDialog from '@Components/money/budget/BudgetProgramStoreDialog.vue';
import OpenTenderCreateDialog from '@Components/money/openTenders/OpenTenderCreateDialog.vue';
import {OpenTender} from '@Models/money/OpenTender';

const {itemId} = defineProps<{
    itemId: number
}>();

const fetched = await promiseAll({
    item: BudgetItem.getById(itemId),
    programs: BudgetProgram.getAllByItem(itemId),
    openTenders: OpenTender.getAllByItem(itemId)
});

const item = ref(fetched.item);

const programs = ref(fetched.programs);

const openTenders = ref(fetched.openTenders);

const form = ref<FormRef>(null);

const canEdit = ref(true);

function save() {
    const request = item.value.update(form.value);

    toast.loading(request);
}

async function remove() {
    if (!await toast.confirm('Biztos vagy benne?', 'Visszavonhatatlan művelet!')) {
        return;
    }

    const request = item.value.delete().then(() => {
        redirect({
            name: 'money.index'
        });
    });

    toast.loading(request, 'Törlés');
}
</script>
