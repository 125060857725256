/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
    import type { InventoryGenericStatisticsResponse } from './InventoryGenericStatisticsResponse';
    import {
    InventoryGenericStatisticsResponseFromJSON,
    InventoryGenericStatisticsResponseFromJSONTyped,
    InventoryGenericStatisticsResponseToJSON,
    InventoryGenericStatisticsResponseCreateEmpty,
    } from './InventoryGenericStatisticsResponse';
    import type { InventoryRoomStatisticsResponse } from './InventoryRoomStatisticsResponse';
    import {
    InventoryRoomStatisticsResponseFromJSON,
    InventoryRoomStatisticsResponseFromJSONTyped,
    InventoryRoomStatisticsResponseToJSON,
    InventoryRoomStatisticsResponseCreateEmpty,
    } from './InventoryRoomStatisticsResponse';
import {DateTime} from '@Utils/DateTime';

export interface InventoryGroupStatisticsResponse {
  groupId: number;
  itemsWithoutGroup: number;
  tagCounts: Array<InventoryGenericStatisticsResponse>;
  roomStatistics: Array<InventoryRoomStatisticsResponse>;
  ownerCounts: Array<InventoryGenericStatisticsResponse>;
}

export const InventoryGroupStatisticsResponseSchema = {
  'groupId': {
    name: 'groupId',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 1,
  },
  'itemsWithoutGroup': {
    name: 'itemsWithoutGroup',
    required: true,
    nullable: false,
    label: '',
    type: 'number',
      minimum: 0,
  },
  'tagCounts': {
    name: 'tagCounts',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'roomStatistics': {
    name: 'roomStatistics',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
  'ownerCounts': {
    name: 'ownerCounts',
    required: true,
    nullable: false,
    label: '',
      type: 'array',
  },
}
export function instanceOfInventoryGroupStatisticsResponse(value: object): value is InventoryGroupStatisticsResponse {
let isInstance = true;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "itemsWithoutGroup" in value;
    isInstance = isInstance && "tagCounts" in value;
    isInstance = isInstance && "roomStatistics" in value;
    isInstance = isInstance && "ownerCounts" in value;

return isInstance;
}

export function InventoryGroupStatisticsResponseFromJSON(json: any): InventoryGroupStatisticsResponse {
return InventoryGroupStatisticsResponseFromJSONTyped(json, false);
}

export function InventoryGroupStatisticsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryGroupStatisticsResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'groupId': json['groupId'],
          'itemsWithoutGroup': json['itemsWithoutGroup'],
            'tagCounts': ((json['tagCounts'] as Array<any>).map(InventoryGenericStatisticsResponseFromJSON)),
            'roomStatistics': ((json['roomStatistics'] as Array<any>).map(InventoryRoomStatisticsResponseFromJSON)),
            'ownerCounts': ((json['ownerCounts'] as Array<any>).map(InventoryGenericStatisticsResponseFromJSON)),
    };
  }

  export function InventoryGroupStatisticsResponseToJSON(value?: InventoryGroupStatisticsResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'groupId': value.groupId,
                'itemsWithoutGroup': value.itemsWithoutGroup,
              'tagCounts': ((value.tagCounts as Array<any>).map(InventoryGenericStatisticsResponseToJSON)),
              'roomStatistics': ((value.roomStatistics as Array<any>).map(InventoryRoomStatisticsResponseToJSON)),
              'ownerCounts': ((value.ownerCounts as Array<any>).map(InventoryGenericStatisticsResponseToJSON)),
    };
  }

  export function InventoryGroupStatisticsResponseCreateEmpty(): InventoryGroupStatisticsResponse {
  return {
      'groupId': 0,
      'itemsWithoutGroup': 0,
      'tagCounts': [],
      'roomStatistics': [],
      'ownerCounts': [],
  };
  }

