import {User} from "../base/User";
import {DateTime} from "@Utils/DateTime";
import {BaseEntity} from "@Models/BaseEntity";
import {type EventsMilestoneResponse, EventsMilestoneType} from "@/api/api";

export class Milestone extends BaseEntity {
    type: EventsMilestoneType;

    user: User | null;

    comment: string | null;

    modifyData: Record<string, string> | null;

    createdAt: DateTime;

    static parseResponse(milestone: Milestone, data: EventsMilestoneResponse): Milestone {
        milestone.id = data.id;
        milestone.type = data.type;
        milestone.user = User.newSingleNullable(data.user, User.parseMinimalResponse);
        milestone.modifyData = data.modifyData as Record<string, string> | null;
        milestone.createdAt = data.createdAt;
        milestone.comment = data.comment;

        return milestone;
    }

    static getIcons(): Record<EventsMilestoneType, string> {
        return {
            [EventsMilestoneType.CREATE]: 'fa-plus-circle bg-primary',
            [EventsMilestoneType.SUBMIT]: 'fa-paper-plane bg-primary',
            [EventsMilestoneType.APPROVE]: 'fa-gavel bg-success',
            [EventsMilestoneType.REJECT]: 'fa-gavel bg-danger',
            [EventsMilestoneType.ADMIN_MOD]: 'fa-user-cog bg-danger',
            [EventsMilestoneType.REPORT]: 'fa-vote-yea bg-warning',
            [EventsMilestoneType.REPORT_ACK]: 'fa-vote-yea bg-success',
            [EventsMilestoneType.INTERNAL]: 'fa-comment bg-primary',
            [EventsMilestoneType.CANCEL]: 'fa-times bg-danger',
        };
    }
}
