import {BaseEntity} from "@Models/BaseEntity";
import type {InventoryTagResponse} from "@/api/api";
import {useConstantsStore} from '@/stores/constants';
import type {Variants} from '@/vue';
import {find} from 'lodash-es';
import {NotFoundError} from '@Utils/errors/NotFoundError';

export class Tag extends BaseEntity {
    identifier: string;

    name: string;

    description: string;

    severity: Variants;

    system: boolean;

    static getBySingleId(id: number): Tag {
        return useConstantsStore().constants.inventory.tags[id];
    }

    static getBySingleIdentifier(identifier: string): Tag {
        const tag = find(useConstantsStore().constants.inventory.tags, tag => tag.identifier === identifier);

        if (tag === undefined) {
            throw new NotFoundError();
        }

        return tag;
    }

    static getByMultipleId(ids: number[]): Tag[] {
        const tags: Tag[] = [];

        for (const id in ids) {
            tags.push(useConstantsStore().constants.inventory.tags[ids[id]]);
        }

        return tags;
    }

    static parseResponse(tag: Tag, data: InventoryTagResponse): Tag {
        tag.id = data.id;
        tag.identifier = data.identifier;
        tag.name = data.name;
        tag.description = data.description;
        tag.severity = data.severity;
        tag.system = data.system;

        return tag;
    }

    get primeSeverity(): Variants {
        switch (this.severity) {
            case 'primary':
            case 'secondary':
            case 'info':
                return 'info';
            case 'success':
                return 'success';
            case 'warning':
                return 'warning';
            case 'danger':
                return 'danger';
            case 'light':
            case 'dark':
                return 'info';
            default:
                throw new Error('Invalid severity: ' + this.severity);
        }
    }

    get icon(): string {
        switch (this.severity) {
            case "primary":
                return 'fa fa-tag';
            case "secondary":
                return 'fa fa-';
            case "success":
                return 'fa fa-check';
            case "info":
                return 'fa fa-circle-info';
            case "warning":
                return 'fa fa-triangle-exclamation';
            case "danger":
                return 'fa fa-triangle-exclamation';
            case "light":
                return 'fa';
            case "dark":
                return 'fa';
        }
    }
}
