import {Base} from "@Models/Base";
import {map} from "lodash-es";

export abstract class BaseEntityString extends Base {
    id: string;

    static entitiesToArray(array: Record<string, BaseEntityString>): string[] {
        return map(array, entity => entity.id);
    }
}
