<template>
    <PegasusHeading>Hetifőnök adminisztráció</PegasusHeading>

    <div class="row">
        <div class="col-lg-6">
            <PegasusTable :data="managers">
                <Column field="user.name" header="Név"></Column>
                <Column field="user.phone" header="Telefonszám"></Column>
                <Column field="roomNumber" header="Szobaszám"></Column>

                <Column header="Műveletek">
                    <template #body="{data}: {data: Manager}">
                        <PegasusButton size="sm" variant="primary" @click="edit(data)">
                            <i class="fas fa-edit"></i> Szerkesztés
                        </PegasusButton>
                    </template>
                </Column>
            </PegasusTable>

            <PegasusButton>
                <i class="fas fa-edit fa-fw"></i> Új hetifőnök hozzáadása
            </PegasusButton>
        </div>

        <div class="col-lg-6">
            <PegasusPanel header="Beosztás">
                <div v-for="week in weeks" :key="week.start.toISO()">
                    <SelectField
                        v-model="assignments[week.start.weekOfYear]"
                        :label="week.start.date() + ' - ' + week.end.date()"
                        :options="dictArray(managerIds, id => managers[id].user.name)"
                        :schema="selectSchema"
                        inline
                        @update:model-value="updateAssignment(week, $event)"
                    ></SelectField>
                </div>
            </PegasusPanel>
        </div>
    </div>
</template>

<title>Hetifőnök adminisztráció</title>

<script lang="ts" setup>
import {computed, ref} from "vue";
import PegasusButton from "@Components/base/PegasusButton.vue";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import Column from "primevue/column";
import {Manager} from "@Models/events/Manager";
import {useAuthStore} from "@/stores/auth";
import PegasusHeading from '@Components/base/PegasusHeading.vue';
import {Dialog} from '@Utils/dialog';
import ManagerAdminDialog from '@Components/events/managers/ManagerAdminDialog.vue';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import {DateTime} from '@Utils/DateTime';
import SelectField from '@Components/base/form/types/SelectField.vue';
import type {FieldSchema} from '@Components/base/form/FieldProperties';
import {dictArray} from '@Models/Utils';
import {promiseAll} from '@Utils/misc';
import {EventsApi} from '@/api/apis';
import {map} from 'lodash-es';
import toast from '@Utils/toast';

useAuthStore().requireUser();

const fetched = await promiseAll({
    managers: Manager.getAll(),
    assignments: EventsApi.managersGetAssignments()
});
const managers = ref(fetched.managers);
const assignments = ref(fetched.assignments.data.assignments);

async function edit(manager: Manager) {
    Dialog.open(ManagerAdminDialog, {manager});
}

const selectSchema: FieldSchema = {
    name: 'manager',
    type: 'number',
    nullable: true,
    required: true,
};

type Week = {start: DateTime, end: DateTime};

// eslint-disable-next-line vue/no-ref-object-reactivity-loss
const managerIds = map(managers.value, manager => manager.id);

const weeks = computed(() => {
    const weeks: Week[] = [];

    let current = DateTime.now().addDay(-14).startOfWeek();

    for (let week = 0; week < 26; week++) {
        current = current.addDay(7);
        weeks.push({
            start: current,
            end: current.addDay(6)
        });
    }

    return weeks;
});

function updateAssignment(week: Week, manager: number) {
    const request = EventsApi.managersUpdateAssignment({
        from: week.start,
        to: week.end,
        manager
    });

    toast.loading(request);
}
</script>
