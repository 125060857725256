/* tslint:disable */
/* eslint-disable */

import { exists, mapValues } from '../runtime';
import {DateTime} from '@Utils/DateTime';

export interface BaseMinimalUserResponse {
  id: string;
  name: string;
  nick: string | null;
  email: string;
}

export const BaseMinimalUserResponseSchema = {
  'id': {
    name: 'id',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'name': {
    name: 'name',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
  'nick': {
    name: 'nick',
    required: true,
    nullable: true,
    label: '',
    type: 'string',
  },
  'email': {
    name: 'email',
    required: true,
    nullable: false,
    label: '',
    type: 'string',
  },
}
export function instanceOfBaseMinimalUserResponse(value: object): value is BaseMinimalUserResponse {
let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "nick" in value;
    isInstance = isInstance && "email" in value;

return isInstance;
}

export function BaseMinimalUserResponseFromJSON(json: any): BaseMinimalUserResponse {
return BaseMinimalUserResponseFromJSONTyped(json, false);
}

export function BaseMinimalUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseMinimalUserResponse {
  if ((json === undefined) || (json === null)) {
  return json;
  }
  return {
  
          'id': json['id'],
          'name': json['name'],
          'nick': json['nick'],
          'email': json['email'],
    };
  }

  export function BaseMinimalUserResponseToJSON(value?: BaseMinimalUserResponse | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {
  
                'id': value.id,
                'name': value.name,
                'nick': value.nick,
                'email': value.email,
    };
  }

  export function BaseMinimalUserResponseCreateEmpty(): BaseMinimalUserResponse {
  return {
      'id': '',
      'name': '',
      'nick': '',
      'email': '',
  };
  }

