<template>
    <PegasusHeading>Mentor beosztás - {{ fetched.assignmentMonth.date.monthLong }}</PegasusHeading>

    <PegasusTable :data="mentors" :per-page="20" :row-variant="mentorBodyClass" show-gridlines>
        <Column body-class="py-2 text-nowrap" field="name" header="Mentor">
            <template #footer>Összesítés</template>
        </Column>

        <Column
            :field="(item: Mentor) => availabilitiesByMentor[item.id]?.days.length.toString() ?? '0'"
            body-class="py-2"
            header="Ráér"
        />

        <Column
            :field="(item: Mentor) => assignmentsByMentor[item.id]?.length.toString() ?? '0'"
            body-class="py-2"
            header="Kapott"
        />

        <Column v-for="day in assignmentMonth.getDaysArray()" :key="day" body-class="p-0 text-center" footer-class="text-center p-0" header-class="p-1 text-center">
            <template #header>
                <div class="w-100">{{ day }}</div>
            </template>

            <template #body="{data}: {data: Mentor}">
                <span v-if="available(data, day)">
                    <RadioButton
                        v-model="assignments[day].mentor"
                        :value="data"
                    ></RadioButton>
                </span>

                <span v-else>-</span>
            </template>

            <template #footer>
                {{ availabilitiesByDay[day] }}
            </template>
        </Column>
    </PegasusTable>

    <PegasusPanel class="my-2" header="Műveletek">
        <PegasusButton variant="success" @click="save">
            <i class="fa fa-save"></i> Mentés
        </PegasusButton>
    </PegasusPanel>

    <PegasusPanel header="Statisztikák">
        <dl>
            <dt>Mentorok beosztás nélkül</dt>
            <dd>{{ size(mentors) - size(availabilitiesByMentor) }}</dd>
        </dl>
    </PegasusPanel>
</template>

<title>Mentor beosztás</title>

<script lang="ts" setup>
import {AssignmentMonth} from "@Models/mentors/AssignmentMonth";
import Column from "primevue/column";
import PegasusTable from "@Components/base/table/PegasusTable.vue";
import {useAuthStore} from "@/stores/auth";
import {promiseAll} from "@Utils/misc";
import {Mentor} from "@Models/mentors/Mentor";
import {computed, ref} from "vue";
import RadioButton from "primevue/radiobutton";
import {Assignment} from '@Models/mentors/Assignment';
import PegasusPanel from '@Components/base/PegasusPanel.vue';
import PegasusButton from '@Components/base/PegasusButton.vue';
import toast from '@Utils/toast';
import {each, groupBy, keyBy, orderBy, size} from "lodash-es";
import PegasusHeading from '@Components/base/PegasusHeading.vue';

const {year, month} = defineProps<{
    year: number,
    month: number,
}>();

useAuthStore().requireUser();

const fetched = await promiseAll({
    assignmentMonth: AssignmentMonth.get(year, month),
    assignments: Assignment.getMonth(year, month),
    mentors: Mentor.getAll(),
});

const assignmentMonth = ref(fetched.assignmentMonth);
const assignments = ref(keyBy(fetched.assignments, assignment => assignment.date.day));

const availabilitiesByMentor = computed(() => keyBy(assignmentMonth.value.availabilities, availability => availability.mentorId));
const assignmentsByMentor = computed(() => groupBy(assignments.value, assignment => assignment.mentor?.id));

const availabilitiesByDay = computed(() => {
    const days: number[] = [];
    each(assignmentMonth.value.availabilities, availability => {
        each(availability.days, day => {
            if (!days[day]) {
                days[day] = 0;
            }

            days[day] += 1;
        });
    });
    return days;
});

const mentors = orderBy(fetched.mentors, mentor => parseInt(mentor.room));

function mentorBodyClass(mentor: Mentor) {
    return mentor.id in availabilitiesByMentor.value
        ? null
        : 'warning';
}

function available(mentor: Mentor, day: number) {
    const availabilities = availabilitiesByMentor.value[mentor.id];
    if (availabilities === undefined) {
        return true;
    }

    return availabilities.days.includes(day);
}

async function save() {
    if (!await toast.confirm('Biztosan elmented a változtatásokat?')) {
        return;
    }

    const request = assignmentMonth.value.updateAssignments(assignments.value).then(response => {
        assignments.value = keyBy(response, assignment => assignment.date.day);
    });

    toast.loading(request);
}
</script>
